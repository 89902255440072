<template>
   <div>
    <loading :active="isLoading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :color="`var(--color-beta)`"
                 :z-index="10000"
                ></loading>
        <Header />
         <!-- Resources -->
        <section class="home resources">
            <!-- Container -->
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-12 mb-3">
                        <div class="d-flex align-items-start align-items-md-center border-bottom">
                            <div class="projectInnerTopBar">
                                <div class="projectLogo text-white">
                                    <img src="@/assets/image/project-icon.png">
                                </div>
                            </div>
                        
                            <div class="projectInnerTopBar h-auto overflow-x-auto overflow-y-hidden">
                                <div class="projectStructure overflow-x-auto">
                                    <div class="d-flex align-items-center">
                                        <h1 class="project-title fw-bold">რესურსები</h1>
                                        <router-link v-if="permission === 'Administrator' || permission === 'Super Administrator'" to="/AddResource" class="btn-members ms-2 px-2 py-1 position-relative text-decoration-none d-block d-md-none">
                                            <i class="tio-add"></i>
                                        </router-link>
                                    </div>
                                    <ul class="project-navigation overflow-x-auto">
                                        <li class="active"><router-link class="text-nowrap" to="/Resources">რესურსების სია</router-link></li>
                                        <li v-if="permission === 'Administrator' || permission === 'Super Administrator'"><router-link class="text-nowrap" to="/AddResource">დაამატე რესურსი</router-link></li>
                                    </ul>
                                </div>
                            </div>
                            <router-link v-if="permission === 'Administrator' || permission === 'Super Administrator'" to="/AddResource" class="btn-members ms-auto position-relative text-decoration-none d-none d-md-block">
                                <i class="tio-add"></i>
                                რესურსი
                            </router-link>
                        </div>
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-md-3 mb-3" v-for="(item, index) in resources" :key="index">
                        <div class="card">
                            <img :src="item.Thumb" class="card-img-top bg-warning" alt="programming language" style="height: 300px;object-fit: cover;">
                            <div class="card-body border-top">
                                <span class="fs-6 text-muted">ვიდეო: {{ item.count_material }}</span>
                                <h4 class="card-title mt-2">{{ item.name }}</h4>
                                <p class="card-text fs-6" v-html="item.description"></p>
                                <div class="d-flex align-items-center">
                                    <router-link :to="'/Resources/'+item.id" class="btn-members text-decoration-none d-inline-block">დაწყება <i class="tio-launch-vs-outlined"></i></router-link>
                                    <router-link v-if="permission === 'Administrator' || permission === 'Super Administrator'" :to="'/EditResource/'+item.id" class="btn btn-warning text-decoration-none d-inline-block ms-auto"><i class="tio-edit"></i></router-link>
                                    <button v-if="permission === 'Administrator' || permission === 'Super Administrator'" class="btn btn-danger text-decoration-none d-inline-block ms-1" data-bs-toggle="modal" :data-bs-target="'#resourceDelete_'+item.id"><i class="tio-clear"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Col -->

                </div>
            </div>
             <!-- End Container -->
        </section>
        <!-- End Resources -->
        


        <!-- Resource Delete -->
        <div v-for="item in resources" class="modal fade" :id="'resourceDelete_'+item.id" tabindex="-1" aria-labelledby="resourceDeleteLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="resourceDeleteLabel">რესურსის წაშლა</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    ნამდვილად გსურს რესურსის წაშლა?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" @click="detele_resource(item.id)">წაშლა</button>
                </div>
                </div>
            </div>
        </div>
        <!-- End Resource Delete -->

   </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import axios from 'axios';
import { mapGetters } from 'vuex'

export default {
    name: 'Index',
    data() {
        return {
            // loader
            isLoading: true,
            // Custom DropDown
            filterMenu: false,
            filterAssigne: false,
            // data of resources
            resources: []
        }
    },
    components: {
        Header,
        Loading
    },
    methods: {
        onCancel() {},
        filter() {
            this.filterMenu = true;
            this.filterAssigne = false;
        },
        getList(){
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/resource/GetList',
              {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              })
              .then(response => {
                // handle success
                this.resources = response.data;
              })
              .catch(error => {
                // handle error
                console.log(error);
              });
        },
        detele_resource(id) {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/resource/delete/'+id,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    // handle success
                    this.getList()
                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
        }
    },
    mounted() {
        window.scroll(0, 0);

        this.getList();
        setTimeout(() => {
            this.isLoading = false
        }, 1000)
    },
    computed: {
        ...mapGetters({ permission: "getCurrentPermission" })
    }
}
</script>

<style scroped>
.btn {
    padding: 0.5rem 0.75rem!important;
}
</style>