<template>
    <div>
        <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :color="`var(--color-beta)`"
            :z-index="10000">
        </loading>

        <Header />
        <!-- Resources -->
        <section class="home resources">
            <!-- Container -->
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-12 mb-3">
                        <div class="d-flex align-items-start align-items-md-center border-bottom">
                            <div class="projectInnerTopBar">
                                <div class="projectLogo text-white">
                                    <img src="@/assets/image/project-icon.png">
                                </div>
                            </div>

                            <div class="projectInnerTopBar h-auto overflow-x-auto overflow-y-hidden">
                                <div class="projectStructure overflow-x-auto">
                                    <div class="d-flex align-items-center">
                                        <h1 class="project-title fw-bold">{{ lecture.name }}</h1>
                                        <a href="/Add_lecture"
                                            class="btn-members ms-2 px-2 py-1 position-relative text-decoration-none d-block d-md-none">
                                            <i class="tio-add"></i>
                                        </a>
                                    </div>
                                    <ul class="project-navigation overflow-x-auto">
                                        <li><router-link class="text-nowrap" to="/Overviews">რესურსების
                                                სია</router-link></li>
                                        <li><router-link class="text-nowrap"
                                                :to="'/Resources/' + lecture.resources_id">ლექციები</router-link></li>
                                        <li
                                            v-if="permission === 'Administrator' || permission === 'Super Administrator'">
                                            <router-link class="text-nowrap"
                                                :to="'/Add_lecture/' + lecture.resources_id">დაამატე
                                                ლექცია</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <router-link v-if="permission === 'Administrator' || permission === 'Super Administrator'"
                                to="/Add_lecture"
                                class="btn-members ms-auto position-relative text-decoration-none d-none d-md-block">
                                <i class="tio-add"></i>
                                რესურსი
                            </router-link>
                        </div>
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12">
                        <!-- Card -->
                        <div class="card shadow-sm mb-3">
                            <!-- Card Header -->
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <img class="me-2 bg-warning rounded" style="width: 50px;" :src="lecture.Thumb"
                                        alt="">

                                    <div
                                        class="d-flex align-items-center justify-content-between w-100 flex-wrap row-gap-2">
                                        <h4 class="card-title mb-0 me-auto">{{ lecture.name }}</h4>
                                        <div class="d-flex">
                                            <button @click="goBack()" class="btn btn-outline-dark me-1"><i
                                                    class="tio-back-ui"></i></button>
                                            <router-link to="/Mytask" v-if="permission === 'User'"
                                                class="btn btn-members me-1">მზად ვარ დავალების შესასრულებლად <i
                                                    class="tio-launch-outlined"></i></router-link>
                                            <router-link :to="'/EditMaterial/' + lecture.id"
                                                v-if="permission === 'Administrator' || permission === 'Super Administrator'"
                                                class="btn btn-outline-primary me-1">რედაქტირება</router-link>
                                            <button
                                                v-if="permission === 'Administrator' || permission === 'Super Administrator'"
                                                class="btn btn-outline-danger" data-bs-toggle="modal"
                                                :data-bs-target="'#deleteResource_' + lecture.id">წაშლა</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Card Header -->

                            <!-- Card Body -->
                            <div v-if="lecture?.video" class="card-body d-flex justify-content-center">
                                <video v-if="!isLoading && lecture.video_path" class="w-100 bg-dark" height="534"
                                    preload="auto" controls>
                                    <source :src="lecture.video_path" type="video/mp4">
                                    <source :src="lecture.video_path" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <!-- End Card Body -->

                            <!-- Card Body -->
                            <div class="card-body border-top">
                                <h5>აღწერა</h5>
                                <p v-html="lecture.description"></p>
                            </div>
                            <!-- End Card Body -->

                            <!-- Card Body -->
                            <div v-if="permission === 'Administrator' || permission === 'Super Administrator'"
                                class="card-body border-top">
                                <!-- Row -->
                                <div class="row">
                                    <!-- Col -->
                                    <div v-if="permission === 'Administrator' || permission === 'Super Administrator'"
                                        class="col-12 col-md-6">
                                        <!-- Row -->
                                        <div class="row g-3">
                                            <!-- Head Title Col -->
                                            <div class="col-12">
                                                <h4>წევრის დამატება</h4>
                                            </div>
                                            <!-- End Head Title Col -->

                                            <!-- Member Select Col List -->
                                            <div class="col-12">
                                                <div class="d-flex h-100 align-items-center column-gap-1">
                                                    <v-select class="assigne w-100 h-100 border rounded" label="name"
                                                        :reduce="item => item.id" v-model="member"
                                                        placeholder="აირჩიე წევრი" :options="members"></v-select>
                                                    <button class="btn btn-dark" type="button" @click="add_member()"> <i
                                                            class="tio-user-add"></i> </button>
                                                </div>
                                            </div>
                                            <!-- Member End Select Col List -->

                                            <!-- Member Col List -->
                                            <div class="col-12 mb-3">
                                                <small class="mb-1 d-block">რაოდენობა: {{ member_list.length }}</small>
                                                <div class="border-top pt-3 "></div>
                                                <!-- Members -->
                                                <div class="members">
                                                    <div class="accordion" id="accordionExample">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header">
                                                                <button class="accordion-button collapsed" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseTwo" aria-expanded="false"
                                                                    aria-controls="collapseTwo">
                                                                    წევრები
                                                                </button>
                                                            </h2>
                                                            <div id="collapseTwo" class="accordion-collapse collapse"
                                                                data-bs-parent="#accordionExample">
                                                                <div class="accordion-body members">
                                                                    <!-- Member -->
                                                                    <div v-for="(item, index) in member_list"
                                                                        :key="index" class="member">
                                                                        <div class="d-flex flex-column">
                                                                            <h5 class="m-0">{{ item.user_info.name }}
                                                                            </h5>
                                                                        </div>
                                                                        <button class="btn btn-danger" type="button"
                                                                            data-bs-toggle="modal"
                                                                            :data-bs-target="'#DeleteMember_' + item.id"><i
                                                                                class="tio-clear"></i></button>
                                                                    </div>
                                                                    <!-- End Member -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <!-- End Members -->
                                            </div>
                                            <!-- End Member Col List -->
                                        </div>
                                        <!-- End Row -->

                                        <!-- Row -->
                                        <div class="row g-3">
                                            <!-- Head Title Col -->
                                            <div class="col-12">
                                                <h4>ჩაფთერის დამატება</h4>
                                            </div>
                                            <!-- End Head Title Col -->

                                            <!-- Member Select Col List -->
                                            <div class="col-12">
                                                <div class="d-flex h-100 align-items-center column-gap-1">
                                                    <v-select class="assigne w-100 h-100 border rounded" label="name"
                                                        :reduce="item => item.id" v-model="chapter"
                                                        placeholder="აირჩიე ჩაფთერი" :options="chapters"></v-select>
                                                    <button class="btn btn-dark" type="button" @click="add_chapter()">
                                                        <i class="tio-add"></i> </button>
                                                </div>
                                            </div>
                                            <!-- Member End Select Col List -->

                                            <!-- Member Col List -->
                                            <div class="col-12">
                                                <small class="mb-1 d-block">რაოდენობა: {{ chapter_list.length }}</small>
                                                <div class="border-top pt-3 "></div>
                                                <!-- Members -->
                                                <div class="members">
                                                    <!-- Member -->
                                                    <div v-for="(item, index) in chapter_list" :key="index"
                                                        class="member">
                                                        <div class="d-flex flex-column">
                                                            <h5 class="m-0">{{ item.user_info.name }}</h5>
                                                        </div>
                                                        <button class="btn btn-danger" type="button"
                                                            data-bs-toggle="modal"
                                                            :data-bs-target="'#DeleteMember_' + item.id"><i
                                                                class="tio-clear"></i></button>
                                                    </div>
                                                    <!-- End Member -->
                                                </div>
                                                <!-- End Members -->
                                            </div>
                                            <!-- End Member Col List -->
                                        </div>
                                        <!-- End Row -->
                                    </div>
                                    <!-- End Col -->

                                    <!-- Col -->
                                    <div v-if="permission === 'Administrator' || permission === 'Super Administrator'"
                                        class="col-12 col-md-6 mt-5 mt-md-0">
                                        <!-- Row -->
                                        <div class="row g-3">
                                            <!-- Head Title Col -->
                                            <div class="col-12">
                                                <h4>დავალებები</h4>
                                            </div>
                                            <!-- End Head Title Col -->

                                            <!-- Member Select Col List -->
                                            <div class="col-12"
                                                v-if="permission === 'Administrator' || permission === 'Super Administrator'">
                                                <div
                                                    class="d-flex h-100 align-items-center justify-content-start justify-content-md-end column-gap-1">
                                                    <router-link :to="'/Add_task/' + this.$route.params.id"
                                                        class="btn btn-primary"><i class="tio-add"></i> დავალების
                                                        დამატება</router-link>
                                                </div>
                                            </div>
                                            <!-- Member End Select Col List -->

                                            <!-- Task Col List -->
                                            <div class="col-12">
                                                <small class="mb-1 d-block">რაოდენობა: {{ tasks.length }}</small>
                                                <div class="border-top pt-3 "></div>
                                                <!-- Tasks -->
                                                <div class="tasks">
                                                    <!-- Task -->
                                                    <div v-for="(item, index) in tasks" :key="index" class="task">
                                                        <div class="d-flex flex-column">
                                                            <h5 class="m-0">{{ item.name }}</h5>
                                                        </div>
                                                        <router-link :to="'/Edit_task/' + item.id"
                                                            class="btn btn-dark ms-auto" type="button"><i
                                                                class="tio-edit"></i></router-link>
                                                        <button @click="remove_task(item.id)" class="btn btn-danger"
                                                            type="button"><i class="tio-clear"></i></button>
                                                        <button @click="linkToCopy(item.url, $event, 'primary')"
                                                            class="btn btn-primary position-relative" type="button"><i
                                                                class="tio-link"></i>
                                                            <div class="position-absolute w-100 h-100 top-0"
                                                                style="left: 0"></div>
                                                        </button>
                                                    </div>
                                                    <!-- End Task -->
                                                </div>
                                                <!-- End Tasks -->
                                            </div>
                                            <!-- End Task Col List -->
                                        </div>
                                        <!-- End Row -->
                                    </div>
                                    <!-- End Col -->
                                </div>
                                <!-- End Row -->
                            </div>
                            <!-- End Card Body -->
                        </div>
                        <!-- End Card -->
                    </div>
                    <!-- End Col -->
                </div>
            </div>
            <!-- End Container -->
        </section>
        <!-- End Resources -->

        <!-- Resource Delete -->
        <div class="modal fade" :id="'deleteResource_' + lecture.id" data-bs-backdrop="static" data-bs-keyboard="false"
            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">ლექციის წაშლა</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        ნამდვილად გსურს წაშლა?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"
                            @click="delete_lecture(lecture.id)">წაშლა</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Resource Delete -->

        <!-- Resource Delete -->
        <div class="modal fade" v-for="item in member_list" :id="'DeleteMember_' + item.id" data-bs-backdrop="static"
            data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">იუზერის წაშლა</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        ნამდვილად გსურს წაშლა?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"
                            @click="remove_member(item.id)">წაშლა</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Resource Delete -->

    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import { mapGetters } from 'vuex'
import axios from 'axios';

export default {
    name: 'Inner',
    data() {
        return {
            // loader
            isLoading: true,

            // For Members 
            member: '',
            members: [],
            member_list: [],

            // For Members 
            chapter: '',
            chapters: [],
            chapter_list: [],

            // Fors Tasks
            tasks: [],


            lecture: []
        }
    },
    components: {
        Header,
        Loading
    },
    methods: {
        getLecture() {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/material/getById/' + this.$route.params.id,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    // handle success
                    this.lecture = response.data;
                    this.isLoading = false;
                    //  setTimeout(() => {
                    //      this.isLoading = false;
                    //  }, 1500)
                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
        },
        delete_lecture(id) {
            this.isLoading = true;
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/material/delete/' + id,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    // handle success
                    this.$router.push({
                        path: '/Resources/' + this.lecture.resources_id
                    })
                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
        },


        // For Members
        get_member_list() {

            // setTimeout(() => {
            //     var permissions = this.permission;


            //     if (permissions === 'Administrator' || permissions === 'Super Administrator') {


            this.isLoading = true;
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/material/get_member_list/' + this.$route.params.id,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    this.member_list = response.data;
                    this.isLoading = false;

                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
            //  }
            // }, 1000);
        },

        member_full_list() {
            // const permissions = this.permission;

            //  if (permissions === 'Administrator' || this.permission === 'Super Administrator') {
            this.isLoading = true;
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/material/get_user_list/',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    this.members = response.data;

                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
            //  }
        },
        add_member() {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            // Make POST request with Axios
            axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/material/add_member', {
                material_id: this.$route.params.id,
                user_id: this.member // Assuming 'member' is passed as an argument
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    this.get_member_list();
                    this.member = '';
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                });
        },
        remove_member(id) {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            // Make POST request with Axios
            axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/material/remove_member/' + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    this.get_member_list();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                });
        },


        // For Chapters
        get_chapter_list() {

            // setTimeout(() => {
            //     var permissions = this.permission;


            //     if (permissions === 'Administrator' || permissions === 'Super Administrator') {


            this.isLoading = true;
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/material/get_chapter_list',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    this.chapters = response.data;
                    this.isLoading = false;

                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
            //  }
            // }, 1000);
        },
        add_chapter() {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            // Make POST request with Axios
            axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/material/add_chapter_member', {
                material_id: this.$route.params.id,
                chapter_id: this.chapter // Assuming 'member' is passed as an argument
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    this.get_chapter_list();
                    this.chapter = '';
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                });
        },

        linkToCopy(textToCopy, event, color) {
            const input = document.createElement('input');
            input.setAttribute('type', 'text');
            input.value = textToCopy;

            // Append the input element to the DOM
            document.body.appendChild(input);

            // Select the text inside the input field
            input.select();

            try {
                document.execCommand('copy'); // Try to copy the selected text to the clipboard
                event.target.parentElement.classList.remove(color == 'primary' ? 'btn-primary' : '' || color == 'dark' ? 'btn-dark' : '');
                event.target.parentElement.classList.add('btn-success');
                setTimeout(() => {
                    event.target.parentElement.classList.remove('btn-success');
                    event.target.parentElement.classList.add(color == 'primary' ? 'btn-primary' : '' || color == 'dark' ? 'btn-dark' : '');
                }, 1000);
            } catch (err) {
                alert('ვერ დაკოპირდა');
            }

            // Clean up by removing the input element
            document.body.removeChild(input);
        },


        // For Tasks
        getTasks_by_material_id() {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/material/get_task_list/' + this.$route.params.id,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    // handle success
                    this.tasks = response.data;
                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
        },
        remove_task(id) {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/material/remove_task/' + id,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    // handle success
                    this.getTasks_by_material_id();
                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
        },



        onCancel() { },
        goBack() {
            this.$router.back()
        }
    },
    mounted() {
        window.scroll(0, 0);

        this.getLecture();
        this.get_member_list();
        this.member_full_list();
        this.getTasks_by_material_id();


        // For Chapters
        this.get_chapter_list();
    },
    computed: {
        ...mapGetters({ permission: "getCurrentPermission" }),
    }
}
</script>

<style scroped>
.members,
.tasks {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.member,
.task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
}
</style>