<template>
  <div>
    <loading :active="isLoading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :color="`var(--color-beta)`"
                 :z-index="10000"
                ></loading>
                <Header />
                <section class="home">
            <!-- Main Content -->
            <div class="container">
                <div v-if="permission=='Super Administrator' || permission=='Administrator'" class="row" >
                    <!-- Col-->
                    <div class="col-12 mb-3">
                        <div class="d-flex align-items-center border-bottom">
                            <div class="projectInnerTopBar">
                            <div class="projectLogo">
                                <img src="@/assets/image/project-icon.png">
                            </div>
                            <div class="projectStructure">
                                <h1 class="project-title">{{projectData.project.name}}</h1>
                                <ul class="project-navigation">
                                    <li class="active" v-if="permission!='User'"><a :href="'/Project_Dashboard/'+projectData.project.id">დეშბორდი</a></li>
                                    <li><a :href="'/Project/'+projectData.project.id">მიმდინარე დავალებები</a></li>
                                    <li v-if="permission!='User'"><a href="#">დასრულებული დავალებები</a></li>
                                </ul>
                            </div>
                        </div>
                        
                        <button v-if="permission=='Administrator' || permission=='Super Administrator'" class="btn-members ms-auto position-relative" data-bs-toggle="modal" data-bs-target="#members">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill"
                                viewBox="0 0 16 16">
                                <path
                                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z">
                                </path>
                            </svg>
                            წევრები
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {{projectData.members_cnt}}
                            </span>
                        </button>
                        
                        </div>
                    </div>
                    <!-- End Col-->

                    <!-- Col-->
                    <div class="col-12 mb-3">
                       <div class="filter border-bottom pb-3 d-flex align-content-center  gap-3">
                        <div class="btn-group">
                            <button type="button" @click="filter()" class="btn btn-ligth border dropdown-toggle">
                                <i class="tio-filter-list"></i> ფილტრი
                            </button>
                            <ul class="dropdown-menu filterMenu shadow" :class="filterMenu == true ? 'd-block' : ''">
                            
                                <li @click="filterAssigne = true, filterMenu = false"><a class="dropdown-item" href="#">იუზერით ფილტრი</a></li>
                            </ul>
                            <ul class="dropdown-menu filterMenu shadow" :class="filterAssigne == true ? 'd-block' : ''">
                                <li class="p-3">
                                    <label class="mb-1 fw-bold">აირჩიე თარიღი</label>
                                    <div class="dropdown-custom-item d-flex align-items-center gap-2" style="min-width: 500px">
                                        <div class="row w-100">
                                            <div class="col-6">
                                                <input class="form-control date" type="date" placeholder="Start">
                                            </div>
                                            <div class="col-6">
                                                <input class="form-control date" type="date" placeholder="End">
                                            </div>
                                        </div>
                                    <button class="btn-members" type="button">ძებნა</button>
                                    </div>
                                    
                                </li>
                            </ul>
                        </div>

                       </div>
                    </div>
                    <!-- End Col-->

                    <!-- Col-->
                    <div class="col-12 col-md-4 position-relative">
                        <!-- Total Assignment-->
                        <div class="card shadow-sm">
                            <div class="card-body">
                                <!-- Chart with Info -->
                                <div class="media align-items-center py-2">
                                    <div class="media-body">
                                        <div class="d-flex justify-content-between align-items-start mb-2">
                                            <h5 class="h5 mb-0"> სულ დავალებები</h5>
                                            <span class="h2 ti-layout-cta-btn-right text-primary mb-0"></span>
                                        </div>
                                        <span class="h2 font-weight-normal mb-0">100</span>
                                    </div>
                                </div>
                                <!-- End Chart with Info -->
                            </div>
                            <div class="progress rounded-0 rounded-bottom bg-transparent" style="height: 5px;"></div>
                        </div>
                        <!-- End Total Assignment-->
                    </div>
                    <!-- End Col-->

                    <!-- Col-->
                    <div class="col-12 col-md-4 position-relative">
                        <!-- Total Assignment-->
                        <div class="card shadow-sm">
                            <div class="card-body">
                                <!-- Chart with Info -->
                                <div class="media align-items-center py-2">
                                    <div class="media-body">
                                        <div class="d-flex justify-content-between align-items-start mb-2">
                                            <h5 class="h5 mb-0"> ვადაგადაცილებული</h5>
                                            <span class="h2 ti-flag-alt text-danger mb-0"></span>
                                        </div>
                                        <span class="h2 font-weight-normal mb-0">30</span>
                                    </div>
                                </div>
                                <!-- End Chart with Info -->
                            </div>
                            <div class="progress rounded-0 rounded-bottom" role="progressbar" aria-label="Success example" aria-valuenow="30" aria-valuemin="0"
                                aria-valuemax="100" style="height: 5px;">
                                <div class="progress-bar bg-danger" style="width: 30%"></div>
                            </div>
                        </div>
                        <!-- End Total Assignment-->
                    </div>
                    <!-- End Col-->

                    <!-- Col-->
                    <div class="col-12 col-md-4 position-relative">
                        <!-- Total Assignment-->
                        <div class="card shadow-sm">
                            <div class="card-body">
                                <!-- Chart with Info -->
                                <div class="media align-items-center py-2">
                                    <div class="media-body">
                                        <div class="d-flex justify-content-between align-items-start mb-2">
                                            <h5 class="h5 mb-0"> დასრულებულები</h5>
                                            <span class="h2 ti-check-box text-success mb-0"></span>
                                        </div>
                                        <span class="h2 font-weight-normal mb-0">50</span>
                                    </div>
                                </div>
                                <!-- End Chart with Info -->
                                
                            </div>
                            <div class="progress rounded-0 rounded-bottom" role="progressbar" aria-label="Success example" aria-valuenow="50" aria-valuemin="0"
                                aria-valuemax="100" style="height: 5px;">
                                <div class="progress-bar bg-success" style="width: 50%"></div>
                            </div>
                        </div>
                        <!-- End Total Assignment-->
                    </div>
                    <!-- End Col-->

                </div>
                <div v-else class="row">
                  <div class="col-12">
                    ეს გვერდი არ არსებობს
                  </div>
                </div>
            </div>
        </section>

        <!-- Members -->
        <div v-if="permission=='Super Administrator' || permission=='Administrator'" class="modal fade" id="members" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#member" type="button"
                                role="tab" aria-controls="home-tab-pane" aria-selected="true">წევრები</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#inviteMember" type="button"
                                role="tab" aria-controls="profile-tab-pane" aria-selected="false">მოიწვიე</button>
                        </li>
                    </ul>
                        <!-- <h1 class="modal-title fs-5 fw-bold" id="staticBackdropLabel">Members</h1> -->
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body py-3 px-0">
                        <div class="row">
                            <div class="col-12">
                        
                                <div class="tab-content" id="myTabContent">
                                    <!-- Member Tab Content-->
                                    <div class="tab-pane fade show active" id="member" role="tabpanel" aria-labelledby="home-tab"
                                        tabindex="0">
                                        <div class="row">
                                            <div class="col-12 border-bottom pb-3">
                                                <label class="mb-2 px-3 fw-bold" for="addMember">წევრის დამატება</label>
                                                <div class="d-flex align-items-center gap-2 px-3">
                                                    <v-select v-model="user.id" id="addMember" class="form-control" placeholder="წევრები" label="text" :options="membersList"></v-select>
                                                    <button @click="addMember()" class="btn-members ms-auto">
                                                        <i class="tio-user-add"></i>
                                                        დამატება
                                                    </button>
                                                </div>
                                                <!-- Alert -->
                                                <div v-if="memberError" class="alert bg-danger text-white fade show py-2 mt-2 mx-3"
                                                    role="alert">
                                                    <strong>Oops</strong> ,something went wrong.
                                                </div>
                                                <!-- End Alert -->
                                            </div>
                                            <div class="col-12 mt-3">
                                                <label class="fw-bold mb-2 px-3" for="membersCount">წევრები
                                                    ({{projectData.members_cnt}})</label>
                                                <ul class="memberList">
                                                    <li v-for="member in projectData.members" :key="member.id">
                                                        <span><i class="tio-user"></i> {{ member.name }}</span>
                                                        <select v-model="member_status" :key="member.id"
                                                            v-on:change="member_status_change(member.id)">
                                                            <option selected disabled value="">სტატუსი</option>
                                                            <option value="Super Administrator">სრული კონტროლი</option>
                                                            <option value="Administrator">შეუძლია რედაქტირება</option>
                                                            <option value="User">მხოლოდ ნახვა</option>
                                                            <option value="remove">წაშლა</option>
                                                        </select>
                        
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Member Tab Content-->

                                    <!-- Invite Member Tab Content-->
                                    <div class="tab-pane fade" id="inviteMember" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                                        <div class="row">
                                            <div class="col-12 border-bottom pb-3">
                                                <label class="mb-2 px-3 fw-bold" for="addMember">მოიწვიე მეილით</label>
                                                <div class="d-flex align-items-center gap-2 px-3">
                                                    <input id="addMember" class="form-control" placeholder="დაამატე წევრი მეილით">
                                                    <button class="btn-members ms-auto">
                                                        <i class="tio-send"></i>
                                                        მოწვევა
                                                    </button>
                                                </div>
                                                <!-- Alert -->
                                                <div v-if="memberError" class="alert bg-danger text-white fade show py-2 mt-2 mx-3"
                                                    role="alert">
                                                    <strong>Oops</strong> ,something went wrong.
                                                </div>
                                                <!-- End Alert -->
                                            </div>
                                            <div class="col-12 mt-3">
                                                <label class="fw-bold mb-2 px-3" for="membersCount">წევრები
                                                    ({{projectData.members_cnt}})</label>
                                                <ul class="memberList">
                                                    <li v-for="member in projectData.members" :key="member.id">
                                                        <span><i class="tio-user"></i> {{ member.name }}</span>
                                                        <select v-model="member_status" :key="member.id"
                                                            v-on:change="member_status_change(member.id)">
                                                            <option selected disabled value="">სტატუსი</option>
                                                            <option value="Super Administrator">სრული კონტროლი</option>
                                                            <option value="Administrator">შეუძლია რედაქტირება</option>
                                                            <option value="User">მხოლოდ ნახვა</option>
                                                            <option value="remove">წაშლა</option>
                                                        </select>
                        
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Invite Member Tab Content-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Custom DropDown -->
        <div @click="filterMenu = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent" :class="filterMenu == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
        <div @click="filterAssigne = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent" :class="filterAssigne == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import Header from '@/components/Header.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Dashboard",
    data() {
        return {
            filterMenu: false,
            filterAssigne: false,
            member: '',
            projectData: 
                { 
                    project: [
                        {
                            id: '',
                            name: ''
                        }
                    ]
                }
            ,
            membersList: [],
            user: {
                id: ''
            },
            userName: '',
            memberError: false,
            member_status:'',
            isLoading: true
        }
    },
    components: {
        Header,
        'v-select': vSelect,
        Loading
    },
    methods: {
        onCancel(){

        },
       
        filter() {
            this.filterMenu = true;
            this.filterAssigne = false;
        },
        member_list() {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
                axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/MembersList', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => {
                        this.membersList = response.data;
                    });
        },
        addMember() {
            if (!this.user.id == '') {
                const token = JSON.parse(localStorage.getItem('user')).access_token;
                axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/add_member_to_project', { 'project_id': this.$route.params.id, 'user_id': this.user.id.id }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => {
                        this.getProjectData();
                    })
                    .catch((error) => {
                        this.memberError = true;
                    });
            }
        },
        getProjectData() {
    
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/get_by_id/'+ this.$route.params.id , {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    this.projectData = response.data;
                });
        },
        member_status_change(user_id) {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/member_status_change', {
                'project_id': this.$route.params.id,
                'user_id': user_id,
                "status": this.member_status
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    location.reload();
                })
                .catch((error) => {
                    this.memberError = true;
                });
        }
    },
    mounted() {

        this.member_list();
        
        this.getProjectData();
        window.scroll(0, 0);
        const user_info = JSON.parse(localStorage.getItem('user'));
        this.userName = user_info.user.name;
        let username =  this.userName.split(" ");
        let firstName = username[0];
        let lastName = username[1];
        this.userName = firstName[0] + lastName[0]
        
        setTimeout(() => {
            this.isLoading = false
        }, 1000)
    },
    computed: {
        ...mapGetters({ permission: "getCurrentPermission" })
    }
}
</script>

<style>

input.date {
    position: relative;
}
input.date[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
</style>