<template>

  <div>
    <loading :active="isLoading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :color="`var(--color-beta)`"
                 :z-index="10000"
                ></loading>
    <section id="login_wrapper">
      <div class="login_position">
        <div class="login_box card border-0">
          <div class="card-body border-0">

            <ValidationObserver v-slot="{ handleSubmit }">
            <!-- Form -->
            <form v-on:submit.prevent="handleSubmit(submitForm)" @keydown="clearError" class="row">
              <!-- Col -->
              <div class="col-12">
                <h1>რეგისტრაცია</h1>
              </div>
              <!-- End Col -->

              <!-- Col -->
              <div class="col-12 custom-form-group">
                <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                  <label class="custom-input-label" for="name">სახელი და გვარი</label>
                  <input id="name" type="text" name="name" v-model="form.name" class="lss-form-control" placeholder="სახელი და გვარი">
                  <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <!-- End Col -->
              
              <!-- Col -->
              <div class="col-12 custom-form-group">
                <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                <label class="custom-input-label" for="email">ელ.ფოსტა</label>
                <input id="email" type="email" name="email" v-model="form.email" class="lss-form-control"
                  placeholder="example@example.com">
                  <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <!-- End Col -->
            
              <!-- Col -->
              <div class="col-12 custom-form-group">
                <ValidationProvider name="mobile" :rules="{required, regex: /^[0-9]+$/ }" v-slot="{ errors }">
                  <label class="custom-input-label" for="mobile">მობილური</label>
                  <input id="mobile" type="number" name="mobile" v-model="form.mobile" class="lss-form-control"
                    placeholder="--- -- -- --">
                  <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <!-- End Col -->

              <!-- Col -->
              <div class="col-12 custom-form-group">
                <ValidationProvider name="password" rules="required|password:@confirm" v-slot="{ errors }">
                  <label class="custom-input-label" for="password">პაროლი</label>
                  <div class="custom-input-group input-group-merge">
                    <input id="password" :type="InputType" name="password" v-model="form.password" class="lss-form-control"
                      placeholder="********">
                    <!-- <div id="changePasswordView" class="input-group-append">
                                    <div class="input-group-text" >
                                      <div :class="isActive ? 'd-block' : 'd-none'">
                                        <i class="fas fa-eye-slash"></i>
                                      </div>
                                      <div :class="!isActive ? 'd-block' : 'd-none'">
                                        <i class="fas fa-eye"></i>
                                      </div>
                            
                                    </div>
                                  </div> -->
                
                  </div>
                  <span class="text-danger" v-if="errors[0] && form.repassword.length == 0">{{ errors[0] }}</span>
                  <span class="text-danger" v-if="form.repassword.length >= 1">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <!-- End Col -->

              <!-- Col -->
              <div class="col-12 custom-form-group">
                <ValidationProvider name="confirm" rules="required" v-slot="{ errors }">
                  <label class="custom-input-label" for="re-password">გაიმეორე პაროლი</label>
                  <div class="custom-input-group input-group-merge">
                    <input id="re-password" :type="InputType" name="repassword" v-model="form.repassword" class="lss-form-control"
                      placeholder="********">
                    <!-- <div class="input-group-append">
                                    <div class="input-group-text" v-on:click="show_password">
                                      <div :class="isActive ? 'd-block' : 'd-none'">
                                        <i class="fas fa-eye-slash"></i>
                                      </div>
                                      <div :class="!isActive ? 'd-block' : 'd-none'">
                                        <i class="fas fa-eye"></i>
                                      </div>
                                    </div>
                                  </div> -->
                  </div>
                  <span class="text-danger" v-if="errors[0] && form.repassword.length == 0">{{ errors[0] }}</span>
                  <span class="text-danger" v-if="form.repassword.length >= 1">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <!-- End Col -->

              <!-- Col -->
              <div class="col-12 d-block d-sm-flex justify-content-between align-items-center mt-40">
                <button class="btn-sent-yellow-lg d-block d-sm-none">რეგისტრაცია</button>
                <div class="d-block d-md-flex text-center mt-4 mt-sm-0">
                  <div class="mr-0 mr-sm-3">გაქვს ექაუნთი?</div>
                  <a href="/" class="ms-1">ავტორიზაცია</a>
                </div>
                <button class="btn-sent-yellow-lg d-none d-sm-block">რეგისტრაცია</button>
              </div>
              <!-- End Col -->

            </form>
            <!-- End Form -->
            </ValidationObserver>

          </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import axios from 'axios';

export default {
  name: "Signup",
  data() {
    return {
      isLoading: true,
      fullPage: true,
      isActive: true,
      StatusPassword:'fas fa-eye-slash',
      InputType:'password',
      error: false,
      errors: {},
      required: '',
      form: {
        name: '',
        email: '',
        mobile: '',
        password: '',
        repassword: '',
      },
      checkRules:''
    }
  },
  components: {
   Loading
  },
  methods: {
    onCancel() {},
    submitForm(){
      axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/signup', this.form)
          .then((res) => {
            localStorage.setItem('user',JSON.stringify(res.data))
            window.location ='/';
          })
          .catch((error) => {
            var error_response=error.response.data;
            if(error.response.status==422){
              this.setErrors(error.response.data.errors);
            }else{
              this.setErrors(error.response.data.message);
            }
          });
    },
    onFailure(message){
      this.error=true;
    },
    setErrors(errors){
      this.errors=errors;
    },
    hasError(fieldName){

      return (fieldName in this.errors);
    },
    clearError(event){

      delete this.errors[event.target.name]
    }
  },
  mounted() {
    setTimeout(() => {
            this.isLoading = false
        }, 1000)
  },
}
</script>

<style scoped>

</style>