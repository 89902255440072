<template>
  <div v-if="permission">
    <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :color="`var(--color-beta)`" :z-index="10000">
    </loading>
    <Header />
    <section class="home mytask">
      <!-- Main Content -->
      <div class="container">
        <div class="row">

          <!-- Col -->
          <div class="col-12 mb-3">
            <div class="d-flex align-items-center border-bottom">
              <div class="projectInnerTopBar">
                <div class="projectLogo text-white">
                  <img src="@/assets/image/project-icon.png">
                </div>
              </div>

              <div class="projectInnerTopBar h-auto overflow-x-auto">
                <div class="projectStructure overflow-x-auto">
                  <h1 class="project-title fw-bold">ჩემი მიმდინარე დავალებები</h1>
                  <ul class="project-navigation overflow-x-auto">
                    <li class="d-none" v-if="permission != 'User'"><a class="text-nowrap"
                        :href="'/Project_Dashboard/' + projectData.project.id">დეშბორდი</a></li>
                    <li class="active"><router-link class="text-nowrap" to="/MyTask">მიმდინარე დავალებები</router-link>
                    </li>
                    <li><router-link class="text-nowrap" to="/MyCompletedTask">დასრულებული დავალებები</router-link></li>
                    <li class="d-none" v-if="permission != 'User'"><a class="text-nowrap" href="#">Overview</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->


          <div class="col-12 mb-3">
            <div class="filter border-bottom pb-3 d-flex align-content-center  gap-3">
              <button type="button" class="btn btn-ligth border" v-if="permission != 'User'" @click="addTaskPlus()"><i
                  class="tio-add"></i> დაამატე დავალება</button>
              <div class="btn-group">
                <button v-if="permission != 'User'" type="button" @click="filter()"
                  class="btn btn-ligth border dropdown-toggle">
                  <i class="tio-filter-list"></i> ფილტრი
                </button>
                <ul class="dropdown-menu filterMenu shadow" :class="filterMenu == true ? 'd-block' : ''">
                  <li @click="filterAssigne = true, filterMenu = false"><a class="dropdown-item" href="#">იუზერით
                      ფილტრი</a></li>
                </ul>
                <ul class="dropdown-menu filterMenu shadow" :class="filterAssigne == true ? 'd-block' : ''">
                  <li class="p-3">
                    <label class="mb-1 fw-bold">შემსრულებელი</label>
                    <div class="dropdown-custom-item d-flex align-items-center gap-2" style="min-width: 500px">
                      <v-select multiple class="assigne w-100 border" label="name" :options="[
    { name: 'რატი წიკლაური', id: '1' },
    { name: 'გიორგი ქაცარავა', id: '2' },
    { name: 'დავით ჭეჭელაშვილი', id: '3' }
  ]"></v-select>
                      <button class="btn-members" type="button">ძებნა</button>
                    </div>

                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div class="col-12 position-relative">
            <!-- Padding Task-->
            <h1 class="title">
              <div class="section-arrow">
                <svg class="Icon RightTriangleIcon" viewBox="0 0 32 32" aria-hidden="true" focusable="false">
                  <path
                    d="M13.707,6.707L23,16l-9.293,9.293C13.077,25.923,12,25.477,12,24.586V7.414C12,6.523,13.077,6.077,13.707,6.707z">
                  </path>
                </svg>
              </div>
              მიმდინარე დავალებები
              <div class="add-task-plus" @click="addTaskPlus()" v-if="permission != 'User'"><i class="tio-add"></i>
              </div>
            </h1>
            <div class="table-responsive">
              <table class="padding-table table table-bordered">
                <thead>
                  <tr>
                    <th class="task-name">დავალების დასახელება</th>
                    <th>შემსრულებელი</th>
                    <th class="text-center">დას. თარიღი</th>
                    <th>პრიორიტეტი</th>
                    <th>სტატუსი</th>
                  </tr>
                </thead>
                <tbody>

                  <!-- Tasks Data -->
                  <tr v-for="(item, index) in getDataTasks" :key="index"
                    :class="item.status.text === 'Done' ? 'bg-success-light' : ''">
                    <td class="task-name">
                      <div class="d-flex justify-content-between">
                        <span>
                          <i v-if="permission != 'User'" @click="markTask(item.id, index, $event, '4')" data_id="1"
                            class="tio-checkmark-circle-outlined task-mark"></i>
                          <input style="width:350px;" placeholder="დაწერე დავალების სახელი" @input="inputGrow()"
                            @keyup="updateTasks(item.id, index)" :disabled="permission == 'User'" v-model="item.name"
                            type="text" class="text">
                          <span class="text-inputValue"></span>
                        </span>
                        <span class="badge-details" @click="showDetail(item.id)">
                          დეტალები
                          <i class="tio-arrow-forward"></i>
                        </span>
                      </div>
                    </td>
                    <td class="assigne">
                      <v-select :disabled="permission == 'User'" label="text" v-model="item.assigne" placeholder="User"
                        v-on:input="updateTasks(item.id, index)" :options="membersList"></v-select>
                    </td>
                    <td class="date"><input :class="item.date < moment ? 'text-danger' : 'text-success'" type="date"
                        v-model="item.date" :disabled="permission == 'User'" placeholder="Date"
                        @change="updateTasks(item.id, index)"></td>
                    <td class="priority">
                      <v-select label="text" v-model="item.priority" :disabled="permission == 'User'"
                        v-on:input="updateTasks(item.id, index)" placeholder="პრიორიტეტი"
                        :options="PriorityData"></v-select>
                    </td>
                    <td class="status">
                      <v-select label="text" v-model="item.status" v-on:input="updateTasks(item.id, index)"
                        placeholder="სტატუსი" :options="StatusData"></v-select>
                    </td>
                  </tr>
                  <!-- End Tasks Data -->




                </tbody>
              </table>
            </div>
            <!-- End Padding Task -->
          </div>

        </div>
      </div>
    </section>

    <!-- OffCanvas ------------------------>



    <!-- details canvas -->
    <div v-if="1 != 1" class="details offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel">
      <div v-if="permission != 'User'" class="offcanvas-header border-bottom">
        <h5 class="mb-0 position-relative" id="offcanvasRightLabel">
          <div data_id="1" class="position-absolute top-0 w-100 h-100" @click="DetailsMarkTask($event)" style="left:0;">
          </div>
          <i :class="detailsTask == true ? 'tio-checkmark-circle-outlined' : 'tio-checkmark-circle'"></i>
          <span v-if="detailsTask == true"> Mark Complited</span>
          <span v-else> Completed</span>
        </h5>

        <button type="button" class="btn-close text-reset" @click="showDescription = false" data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
      </div>
      <div v-else class="offcanvas-header">
        <h5 class="mb-0 position-relative" id="offcanvasRightLabel">
          <div data_id="1" class="position-absolute top-0 w-100 h-100" @click="markDetailTask(taskDetail.id, 3, $event)"
            style="left:0;"></div>
          <i
            :class="detailsTask == true || taskDetail.status_id != 3 ? 'tio-checkmark-circle-outlined' : 'tio-checkmark-circle'"></i>
          <span v-if="detailsTask != true || taskDetail.status_id != 3"> Mark Done</span>
          <span v-else> Done</span>
        </h5>

        <button type="button" class="btn-close text-reset" @click="showDescription = false" data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
      </div>
      <div id="comments" class="offcanvas-body  position-relative px-0" @click="textareaDetails = false"
        :class="textareaDetails ? 'active' : ''">

        <div class="container px-0 mb-4">
          <div class="row align-items-center mx-1">
            <!-- Details Col -->

            <div class="col-9">
              <h5 class="mb-0">{{ taskDetail.name }}</h5>
              <small class="text-muted">{{ taskDetail.project.name }}</small>
            </div>
            <div class="col-3  mt-0 text-end">
              <button v-if="permission != 'User'" type="button" @click="deleteTask(taskDetail.id);"
                class="btn btn-sm btn-outline-danger">Delete Task</button>
            </div>


            <div class="col-6 mt-3">
              <span>Assigner</span>
              <p class="mb-0">
                <small class="text-muted">{{ taskDetail.assigner.name }}</small>
              </p>
            </div>

            <div class="col-6  mt-3">
              <span>Assigne</span>
              <p class="mb-0">
                <small class="text-muted">{{ taskDetail.assigned.name }}</small>
              </p>
            </div>

            <div class="col-6  mt-1">
              <span>Created at</span>
              <p class="mb-0">
                <small class="text-muted">{{ taskDetail.created_at }}</small>
              </p>
            </div>


            <div class="col-6  mt-1">
              <span>Due at</span>
              <p class="mb-0">
                <small class="text-muted">{{ taskDetail.due_at }}</small>
              </p>
            </div>

            <div class="col-12  mt-1">
              <span>Description</span>
              <div v-if="permission != 'User'" class="detailsDescription" :class="showDescription ? 'active' : ''">
                <p @click="showDescription = true" class="mb-0">
                  <small v-html="description" class="text-muted"></small>
                </p>
                <ckeditor class="detailEditor" v-model="description" @input="updateDescription(taskDetail.id)"
                  :config="editorConfig_Description"></ckeditor>

                <i @click="showDescription = false" class="tio-clear-circle-outlined offDescription"></i>
              </div>
              <div v-else class="detailsDescription">
                <p v-html="taskDetail.description"></p>
              </div>
            </div>


          </div>
        </div>

        <!-- get Comment List -->
        <div class="getCommentList mt-auto" v-if="taskDetail.comment.length > 0">
          <h5 class="px-3">Comments</h5>
          <ul class="px-3 py-3 list-unstyled">
            <!-- comments -->
            <li :class="comments.s_color + ' pb-3 d-flex mb-2'" v-for="(comments, index) in taskDetail.comment"
              :key="index">
              <span
                :class="comments.color + ' ' + comments.f_color + ' rounded-circle  text-dark p-3 d-flex align-items-center justify-content-center me-2'"
                style="font-size: 12px;height: 32px;line-height: 32px;min-width: 32px;width: 32px;">
                {{ comments.short_name }}
              </span>
              <div>
                <div class="d-flex align-items-center gap-2">
                  <span>
                    <strong>
                      {{ comments.name }}</strong>
                  </span>
                  <small class="text-muted">
                    {{ comments.created_at }}
                  </small>
                </div>
                <p class="w-100 mb-0">
                  <span :class="'badge rounded-pill' + ' text-' + comments.badge"> {{ comments.role }}</span>
                </p>
                <p class="mb-0 mt-2 comments" v-html="comments.comment"></p>
              </div>
            </li>



          </ul>
        </div>
        <!-- End get Comment List -->

      </div>
      <div class="offcanvas-footer border-top">
        <!-- Comment -->
        <div class="addComment" :class="textareaDetails ? 'active' : ''">
          <div class="comment d-flex align-items-start">
            <div v-if="permission == 'User'"
              class="rounded-circle bg-danger text-primary text-white p-3 d-flex align-items-center justify-content-center me-2"
              style="font-size: 12px;height: 32px;line-height: 32px;min-width: 32px;width: 32px;">{{ userName }}</div>
            <div v-if="permission == 'Super Administrator'"
              class="rounded-circle bg-danger text-white p-3 d-flex align-items-center justify-content-center me-2"
              style="font-size: 12px;height: 32px;line-height: 32px;min-width: 32px;width: 32px;">{{ userName }}</div>
            <div v-if="permission == 'Administrator'"
              class="rounded-circle bg-warning text-dark p-3 d-flex align-items-center justify-content-center me-2"
              style="font-size: 12px;height: 32px;line-height: 32px;min-width: 32px;width: 32px;">{{ userName }}</div>

            <div class="add-comment position-relative">
              <ckeditor class="detailEditor w-100" v-model="comment" @focus="textareaDetails = true"
                @input="textareaDetails = true" :config="editorConfig"></ckeditor>

              <!-- <vue-editor class="detailEditor" :editorToolbar="customToolbar" @click="textareaDetails = true" @input="textareaDetails = true"  v-model="comment"></vue-editor> -->
              <!-- <textarea v-model="comment" class="textarea" @click="textareaDetails = true" @input="textareaDetails = true" contenteditable="true"></textarea> -->
              <button class="btn-comment" @click="addComment(taskDetail.id)"
                :class="textareaDetails ? 'active' : ''">Comment</button>
            </div>
          </div>
        </div>
        <!-- End Comment -->
      </div>
    </div>


    <!-- End OffCanvas -------------------------->



    <!-- Modal ------------------------>


    <!-- End Modal ------------------------>

    <!-- Custom DropDown -->
    <div @click="filterMenu = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent"
      :class="filterMenu == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
    <div @click="filterAssigne = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent"
      :class="filterAssigne == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import Header from '@/components/Header.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from "vuex";


export default {
  name: 'Index',
  data() {
    return {
      moment: '',
      // Detail Description
      description: "add more description about task...",
      showDescription: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        ['link'],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"]
      ],
      editorConfig: {
        toolbar: [{ name: 'basicstyles', items: ['Bold', 'Italic', 'Link', 'Underline', 'Undo', 'Redo', 'NumberedList', 'BulletedList', 'Format'] }]
      },
      editorConfig_Description: {
        toolbar: [{ name: 'basicstyles', items: ['Bold', 'Italic', 'Link', 'Underline', 'Undo', 'Redo', 'NumberedList', 'BulletedList', 'Format'] }]
      },
      getDataTasks: [
      ],
      completedTasks: [
      ],
      detailsTask: true,
      filterMenu: false,
      filterAssigne: false,
      member: '',
      projectData:
      {
        members: [],
        project: [
          {
            id: '',
            name: ''
          }
        ]
      }
      ,
      membersList: [],
      user: {
        id: ''
      },
      userName: '',
      memberError: false,
      memberSuccess: false,
      memberRemovedSuccess: false,
      member_status: '',
      PriorityData: [],
      StatusData: [],
      textareaDetails: false,
      comment: '',
      isLoading: true,
      taskDetail: {
        assigned: {},
        assigner: {},
        project: {},
        comment: {}

      }
    }
  },
  components: {
    Header,
    'v-select': vSelect,
    Loading
  },
  methods: {
    onCancel() {

    },
    markDetailTask(id, status, event) {
      if (event.target.getAttribute('data_id') == '1' && status == 3) {
        event.target.parentElement.classList.add('active');

        this.detailsTask = false;
        event.target.setAttribute("data_id", "2");
        status = 1
      } else {
        event.target.parentElement.classList.remove('active');



        this.detailsTask = true;

        event.target.setAttribute("data_id", "1");
        status = 3
      }
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/setStatus', {
        'project_id': this.$route.params.id,
        'id': id,
        'status_id': status
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {

        })
        .catch((error) => {
          this.memberError = true;
        });
    },
    markTask(id, index, event, status) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/setStatus', {
        'project_id': this.$route.params.id,
        'id': id,
        'status_id': status
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.setMark(event);
        })
        .catch((error) => {
          this.memberError = true;
        });
    },
    setMark(event) {
      if (event.target.getAttribute('data_id') == '1') {
        event.target.classList.remove('tio-checkmark-circle-outlined');
        event.target.classList.add('tio-checkmark-circle');
        event.target.classList.remove('task-mark');
        event.target.classList.add('task-marked');
        event.target.setAttribute("data_id", "2");
      } else {
        event.target.classList.remove('tio-checkmark-circle');
        event.target.classList.add('tio-checkmark-circle-outlined');
        event.target.classList.remove('task-marked');
        event.target.classList.add('task-mark');
        event.target.setAttribute("data_id", "1");
      }
      console.log(event.target);
    },
    DetailsMarkTask(event) {
      if (event.target.getAttribute('data_id') == '1') {
        event.target.parentElement.classList.add('active');


        this.detailsTask = false;

        event.target.setAttribute("data_id", "2");
      } else {
        event.target.parentElement.classList.remove('active');



        this.detailsTask = true;

        event.target.setAttribute("data_id", "1");
      }
      console.log(event.target);
    },
    accordion() {


    },
    getTaskList() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/GetMyTaskPendding', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.getDataTasks = response.data;
          this.isLoading = false;
        });
    },
    addTaskPlus() {


      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/Add', {
        'project_id': this.$route.params.id
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.addNewTask(response.data.id);
        })
        .catch((error) => {
          this.memberError = true;
        });

    },
    addNewTask(id) {
      // Open Padding-tabble
      const table = document.querySelector(".padding-table");
      const iconUpDown = document.querySelector(".section-arrow");
      iconUpDown.parentElement.classList.remove("active");
      table.classList.remove('collapsed');


      // Add New Task
      this.getDataTasks.push(
        {
          id: id,
          name: '',
          assigne: [],
          date: "",
          priority: [],
          status: []
        }
      )

    },
    updateTasks(id, index) {


      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/update', {
        'id': id,
        'task_data': this.getDataTasks[index]
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {

        })
        .catch((error) => {
          this.memberError = true;
        });
    },
    showDetail(itemId) {
      this.$router.push({ path: '/Details/' + itemId });
      // const token = JSON.parse(localStorage.getItem('user')).access_token;
      // axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/GetById/'+itemId, {
      //   headers: {
      //     'Authorization': `Bearer ${token}`
      //   }
      // })
      //     .then(response => {
      //       this.taskDetail=response.data;
      //       if(response.data.description!=null){
      //         this.description=response.data.description;
      //       }



      //     });

    },
    inputGrow() {
      const inputText = document.querySelectorAll('.text');
      const spanInputText = document.querySelector('.text-inputValue');

      inputText.forEach(el => {
        spanInputText.innerHTML = el.value.replace(/\s/g, '&nbsp;&nbsp;&nbsp;');
        el.style.width = spanInputText.offsetWidth + 'px';
      })

    },
    detailAddComment() {
      const comment = document.querySelector(".comment textarea");
      comment.parentElement.classList.toggle("active");
    },
    filter() {
      this.filterMenu = true;
      this.filterAssigne = false;
    },
    member_list() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/MembersList', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.membersList = response.data;
        });
    },
    addMember() {
      if (!this.user.id == '') {
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/add_member_to_project', { 'project_id': this.$route.params.id, 'user_id': this.user.id.id }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
          .then(response => {
            this.memberSuccess = true;
            setTimeout(() => {
              this.memberSuccess = false;
            }, 3000)
            this.getProjectData();
          })
          .catch((error) => {
            this.memberError = true;
            setTimeout(() => {
              this.memberError = false;
            }, 3000)
          });
      }
    },
    getProjectData() {

      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/get_by_id/' + this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.projectData = response.data;
        });
    },
    member_status_change(user_id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/member_status_change', {
        'project_id': this.$route.params.id,
        'user_id': user_id,
        "status": this.member_status
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.memberRemovedSuccess = true;
          setTimeout(() => {
            this.memberRemovedSuccess = false;
          }, 3000)
          // location.reload();
          this.getProjectData();
        })
        .catch((error) => {
          this.memberError = true;
        });
    },
    getPriority() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Priority/GetList', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.PriorityData = response.data;
        });
    },
    getStatus() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Status/GetList', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.StatusData = response.data;
        });
    },
    getComplitedTasks() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/GetMyTaskComplited', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.completedTasks = response.data;
        });
    },
    addComment(id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/add-task-comment', {
        task_id: id,
        comment: this.comment
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.showDetail(id);



          setTimeout(function () {
            var div = document.querySelector("#comments");
            div.scrollTop = div.scrollHeight - div.clientHeight;

          }, 500);





        })
        .catch(error => {

        })

      this.comment = '';
      this.textareaDetails = false;
    },
    deleteTask(id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/' + id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          // Handle successful response
          location.reload();
        })
        .catch(error => {
          // Handle error
          console.log(error.response.data.error);
        });
    },
    updateDescription(id) {

      if (id) {
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/UpdateDescription/' + id, {
          'id': id,
          'description': this.description
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
          .then(response => {

          })
          .catch((error) => {
            this.memberError = true;
          });
      }
    },
    getDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const dateTime = `${year}-${month}-${day}`;
      this.moment = dateTime;
      return dateTime;
    }

  },
  mounted() {
    this.getDateTime();

    this.getComplitedTasks();
    this.getStatus();
    this.getPriority();
    this.getTaskList();
    //this.member_list();

    //this.getProjectData();
    window.scroll(0, 0);
    const user_info = JSON.parse(localStorage.getItem('user'));
    this.userName = user_info.user.name;
    let username = this.userName.split(" ");
    let firstName = username[0];
    let lastName = username[1];
    this.userName = firstName[0] + lastName[0]

  },
  updated() {
    const iconUpDown = document.querySelectorAll(".section-arrow");

    iconUpDown.forEach(icon => {

      icon.addEventListener("click", e => {
        icon.parentElement.classList.toggle("active")
        icon.parentElement.nextElementSibling.classList.toggle("collapsed")
      })
    })
    // const comment = document.querySelector('.add-comment .ql-editor');
    //     comment.addEventListener('click', () => {
    //       this.textareaDetails = true;
    //     })
  },
  computed: {
    ...mapGetters({ permission: "getCurrentPermission" })
  }
}
</script>

<style scroped>
.error {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.5em red;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}
</style>