<template>
  <div class="position-relative overflow-x-hidden">
    <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :color="`var(--color-beta)`" :z-index="10000">
    </loading>
    <Header />

    <section class="settings">
      <!-- Container -->
      <div class="container py-5">
        <!-- Row -->
        <div class="row">
          <!-- Col -->
          <div class="col">
            <nav class="card mb-4">
              <div class="card-body bg-light">
                <h4 class="mb-0">პარამეტრები</h4>
              </div>
            </nav>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->

        <!-- Row -->
        <div class="row">
          <!-- Col -->
          <div class="col-lg-4">
            <!-- Card -->
            <div class="card mb-4">
              <div class="card-body text-center">
                <!-- <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp" alt="avatar"
                  class="rounded-circle img-fluid" style="width: 150px;"> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="#ccced2"
                  class="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                  <path fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                </svg>
                <h5 class="my-3">{{ user.name }}</h5>
                <p class="text-muted mb-1">{{ user.email }}</p>
                <p class="text-muted mb-4">{{ user.mobile }}</p>
                <div class="d-flex justify-content-center mb-2">
                  <button @click="openPasswordReset()" type="button" class="btn btn-primary"><i class="tio-refresh"></i>
                    პაროლის შეცვლა</button>
                </div>
              </div>
            </div>
            <!-- End Card -->
          </div>
          <!-- End Col -->

          <!-- Col -->
          <ValidationObserver class="col-lg-8" v-slot="{ handleSubmit }">
            <form v-on:submit.prevent="handleSubmit(submitForm)" @keydown="clearError">
              <!-- Card -->
              <div class="card mb-4">
                <!-- Card Body -->
                <div class="card-body">
                  <!-- Row -->
                  <div class="row align-items-center">
                    <div class="col-sm-3">
                      <p class="mb-0">სრული სახელი</p>
                    </div>
                    <div class="col-sm-9">
                      <input type="text" disabled :value="user.name" class="form-control" placeholder="name">
                    </div>
                  </div>
                  <!-- End Row -->
                  <hr>
                  <!-- Row -->
                  <div class="row align-items-center">
                    <div class="col-sm-3">
                      <p class="mb-0">ელ.ფოსტა</p>
                    </div>
                    <div class="col-sm-9">
                      <input type="email" disabled :value="user.email" class="form-control"
                        placeholder="example@reachstar.io">
                    </div>
                  </div>
                  <!-- End Row -->
                  <hr>
                  <!-- Row -->
                  <div class="row align-items-center">
                    <div class="col-sm-3">
                      <p class="mb-0">მობილური ნომერი</p>
                    </div>
                    <div class="col-sm-9">
                      <input type="number" disabled :value="user.mobile" class="form-control" placeholder="mobile">
                    </div>
                  </div>
                  <!-- End Row -->
                  <div v-if="openResetPasswords">
                    <hr>
                    <!-- Row -->
                    <div class="row align-items-center">
                      <div class="col-sm-3">
                        <p class="mb-0">ძველი პაროლი</p>
                      </div>
                      <div class="col-sm-9">
                        <ValidationProvider name="oldPassword" rules="required" v-slot="{ errors }">
                          <input type="password" v-model="form.old_password" class="form-control"
                            placeholder="ძველი პაროლი">
                          <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <!-- End Row -->
                    <hr>
                    <!-- Row -->
                    <div class="row align-items-center">
                      <div class="col-sm-3">
                        <p class="mb-0">ახალი პაროლი</p>
                      </div>
                      <div class="col-sm-9">
                        <ValidationProvider name="newPassword" rules="required|password:@confirm" v-slot="{ errors }">
                          <input type="password" v-model="form.password" class="form-control"
                            placeholder="ახალი პაროლი">
                          <span class="text-danger" v-if="errors[0] && form.password.length == 0">{{ errors[0] }}</span>
                          <span class="text-danger" v-if="form.password.length >= 1">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <!-- End Row -->
                    <hr>
                    <!-- Row -->
                    <div class="row align-items-center">
                      <div class="col-sm-3">
                        <p class="mb-0">გაიმეორე პაროლი</p>
                      </div>
                      <div class="col-sm-9">
                        <ValidationProvider name="confirm" rules="required" v-slot="{ errors }">
                          <input type="password" v-model="form.re_password" class="form-control"
                            placeholder="გაიმეორე პაროლი">
                          <span class="text-danger" v-if="errors[0] && form.re_password.length == 0">{{ errors[0]
                            }}</span>
                          <span class="text-danger" v-if="form.re_password.length >= 1">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <!-- End Row -->
                  </div>
                </div>
                <!-- End Card Body -->

                <!-- Card Footer -->
                <div v-if="openResetPasswords" class="card-footer bg-light py-3 text-end">
                  <button class="btn btn-primary">შენახვა</button>
                </div>
                <!-- End Card Footer -->
              </div>
              <!-- End Card -->
            </form>
          </ValidationObserver>
          <!-- End Col -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Container -->
    </section>


    <!-- old - password does't match - Alert -->
    <div :class="errorAlert == true ? 'active' : ''" class="alert alert-danger" role="alert">
      ძველი პაროლი არ ემთხვევა
    </div>
    <!-- End - old password does't match - Alert -->

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from "@/components/Header";
import axios from "axios";

export default {
  name: "PasswordChange",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      error: false,
      errors: {},
      user: {
        name: '',
        email: '',
        mobile: ''
      },
      form: {
        old_password: '',
        password: '',
        re_password: '',
      },
      openResetPasswords: false,
      errorAlert: false,
      isLoading: true


    }
  },
  components: {
    Header,
    Loading
  },
  methods: {
    onCancel() {

    },
    submitForm() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/change_password', this.form, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          this.openResetPasswords = false;
          this.isLoading = false;
          this.$router.push('/');
        })
        .catch((error) => {
          this.errorAlert = true;
          setTimeout(() => {
            this.errorAlert = false;
          }, 3000)

        });

    },
    onFailure(message) {
      this.error = true;
    },
    setErrors(errors) {
      this.errors = errors;
    },
    hasError(fieldName) {
      return (fieldName in this.errors);
    },
    clearError(event) {
      delete this.errors[event.target.name]
    },
    openPasswordReset() {
      this.openResetPasswords = true;
    }

  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 700)

    //** User Info */
    const user_info = JSON.parse(localStorage.getItem('user'));
    this.user.name = user_info.user.name;
    this.user.email = user_info.user.email;
    this.user.mobile = user_info.user.mobile;
  }
}
</script>

<style scoped></style>