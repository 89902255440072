<template>
  <div>
    <loading :active="isLoading" :can-cancel="true" :color="`var(--color-beta)`" :z-index="10000"></loading>
    <Header />
    <section v-if="permission == 'Super Administrator' || permission == 'Administrator'" class="home">

      <div class="container">
        <div class="row g-3">
          <div class="col-12">
            <div class="row w-100 gy-3">
              <div class="col-xl-4">
                <label class="mb-1 fw-bold">სახელი</label>
                <input v-model="name" type="text" class="form-control py-2" placeholder="სახელი">
              </div>
              <div class="col-xl-4">
                <label class="mb-1 fw-bold">ელ.ფოსტა</label>
                <input v-model="email" type="email" class="form-control py-2" placeholder="ელ.ფოსტა">
              </div>
              <div class="col-xl-3">
                <label class="mb-1 fw-bold">სტატუსი</label>
                <v-select :options="[
      { title: 'აქტიური', id: 'active' },
      { title: 'შეზღუდული', id: 'disabled' }
    ]" v-model="status" label="title" placeholder="აირჩიე სტატუსი" class="border form-control"></v-select>
              </div>
              <div class="col-xl-1">
                <label class="mb-4 pb-2 fw-bold d-none d-xl-block"></label>
                <button @click="filter" class="btn-members w-100 justify-content-center" type="button">ძებნა</button>
              </div>
            </div>

          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped table-bordered members">
                <thead>
                  <tr>
                    <th class="p-3 text-center" width="5%">Id</th>
                    <th class="p-3" width="30%">Name</th>
                    <th class="p-3" width="25%">Email</th>
                    <th class="p-3" width="30">Projects</th>
                    <th class="p-3" width="30">Status</th>
                    <th class="p-3 text-center" width="10%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in members" :key="index">
                    <td class="p-3 text-center">
                      {{ item.id }}
                    </td>
                    <td class="p-3">
                      <p class="mb-0">
                        {{ item.name }}
                      </p>
                      <p class="mb-0">
                        <b>{{ item.mobile }}</b>
                      </p>

                      <p v-if="item.permission == 'User'" class="badge bg-warning text-dark mb-0">{{ item.permission }}
                      </p>
                      <p v-else class="badge bg-danger text-white mb-0">{{ item.permission }}</p>

                    </td>
                    <td class="p-3">
                      {{ item.email }}
                    </td>
                    <td class="p-3">
                      <span class="badge bg-primary me-2" v-for="project in item.projects" :key="project.id">{{
      project.name }}</span>
                    </td>
                    <td class="p-3">
                      <div class="d-flex align-items-center gap-2">
                        <span v-if="item.status === 'active'" class="badge bg-success">{{ item.status }}</span>
                        <span v-if="item.status === 'disabled'" class="badge bg-danger">{{ item.status }}</span>


                      </div>

                    </td>
                    <td class="p-3 text-center">
                      <button class="btn btn-warning" @click="EditMember(item.id)">Edit</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>

    </section>
    <div @click="filterMenu = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent"
      :class="filterMenu == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
    <div @click="filterAssigne = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent"
      :class="filterAssigne == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import Header from '@/components/Header.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Dashboard",
  data() {
    return {
      isLoading: true,
      members: [],
      filterMenu: false,
      filterAssigne: false,

      // filter 
      name: '',
      email: '',
      status: { title: 'აქტიური', id: 'active' },
    }
  },
  components: {
    Header,
    'v-select': vSelect,
    Loading
  },
  methods: {
    filter() {
      this.getList();
    },
    getList() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/users/getList', {
        params: {
          name: this.name,
          email: this.email,
          status: this.status
        },
        headers: {
          'Authorization': `Bearer ${token}`
        },



      })
        .then(response => {
          this.isLoading = false;
          this.members = response.data;
        });
    },
    EditMember(id) {
      this.$router.push({ name: 'EditMember', params: { id: id } })
    }


  },
  mounted() {
    this.getList();

    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      if (user.permission == 'Super Administrator' || user.permission == 'Administrator') {
        console.log('');
      } else {
        this.$router.push('/');
      }
    }


  },
  computed: {
    ...mapGetters({ permission: "getCurrentPermission" })
  }
}
</script>

<style>
input.date {
  position: relative;
}

input.date[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}


input:checked {
  width: 50px;
}
</style>