<template>
  <div v-if="permission === 'Administrator' || permission === 'Super Administrator'">
    <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :color="`var(--color-beta)`" :z-index="10000">
    </loading>
    <Header />
    <!-- Resources -->
    <section class="home resources">
      <!-- Container -->
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-12 mb-3">
            <div class="d-flex align-items-start align-items-md-center border-bottom">
              <div class="projectInnerTopBar">
                <div class="projectLogo text-white">
                  <img src="@/assets/image/project-icon.png">
                </div>
              </div>

              <div class="projectInnerTopBar h-auto overflow-x-auto overflow-y-hidden">
                <div class="projectStructure overflow-x-auto">
                  <h1 class="project-title fw-bold">ლექციის დამატება</h1>
                  <ul class="project-navigation overflow-x-auto">
                    <li><router-link class="text-nowrap" to="/Overviews">რესურსების სია</router-link></li>
                    <li><router-link class="text-nowrap cursor-pointer"
                        :to="'/InnerResource/' + form.tasks.material_id">ლექცია</router-link></li>
                    <li v-if="permission === 'Administrator' || permission === 'Super Administrator'"><router-link
                        class="text-nowrap " :to="'/Add_task/' + form.tasks.material_id">დავალების
                        დამატება</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->


          <!-- Col -->
          <div class="col-12">
            <!-- Card -->
            <div class="card shadow-sm mb-3">
              <div class="card-body">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(submitForm)" class="row g-4">
                    <div class="col-12">
                      <label class="form-label">დავალების დამატება</label>
                      <div class="row g-3">
                        <div class="col-md-6">
                          <input type="text" name="task_name" v-model="form.tasks.name" class="form-control"
                            placeholder="დავალების დასახელება">
                        </div>
                        <div class="col-md-6">
                          <input type="text" v-model="form.tasks.url" class="form-control"
                            placeholder="დავალების ლინკი">
                        </div>
                        <div class="col-md-4">
                          <label class="form-label">დღეების რაოდენობა</label>
                          <input type="text" v-model="form.tasks.days" class="form-control"
                            placeholder="დავალების ლინკი">
                        </div>
                        <!-- <div class="col-md-2">
                                        <button class="btn btn-dark w-100" type="button" @click="addTask()"><i class="tio-file-add-outlined"></i></button>
                                      </div> -->

                      </div>
                      <div v-if="1 != 1" class="table-responsive mt-2 border-bottom">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>დავალებების სია:</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="form.tasks.length == 0" class="placeholder-glow">
                              <td>
                                <h4 class="placeholder rounded m-0 col-4"></h4>
                              </td>
                              <td class="text-end">
                                <button type="button" class="btn btn-outline-primary me-1 placeholder col-2"></button>
                                <button type="button" class="btn btn-outline-danger placeholder col-1"></button>
                              </td>
                            </tr>
                            <tr v-for="(item, index) in form.tasks" :key="index">
                              <td>
                                <h4>{{ item.name }}</h4>
                              </td>
                              <td class="text-end">
                                <a class="btn btn-outline-primary me-1" :href="item.url" target="_blank"><i
                                    class="tio-link"></i> ლინკი</a>
                                <button class="btn btn-outline-danger" type="button" @click="remove_tasks(index)"><i
                                    class="tio-clear"></i></button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-12">
                      <button type="submit" class="btn-members"><i class="tio-edit"></i> რედაქტირება</button>
                    </div>
                  </form>
                </ValidationObserver>

              </div>
            </div>
            <!-- End Card -->
          </div>
          <!-- End Col -->
        </div>
      </div>
      <!-- End Container -->
    </section>
    <!-- End Resources -->

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import axios from 'axios';
import { mapGetters } from 'vuex'

export default {
  name: 'Add_task',
  data() {
    return {
      form: {
        tasks: {
          id: '',
          name: '',
          material_id: '',
          url: '',
          days: 4
        }
      },
      // For Task Upload
      task_name: '',
      task_url: '',
      // loader
      isLoading: true
    }
  },
  components: {
    Header,
    Loading
  },
  methods: {
    onCancel() { },
    submitForm() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      // make POST request with Axios
      axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/material/update_task/' + this.$route.params.id, this.form, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          // handle success
          this.$router.push({
            path: '/InnerResource/' + this.form.tasks.material_id
          })

        })
        .catch(error => {
          // handle error
          console.log(error);
        });
    },
    get_task_edit_data() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      // make POST request with Axios
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/material/get_task_list_by_id/' + this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.form.tasks = response.data;

        })
        .catch(error => {
          // handle error
          console.log(error);
        });
    },
    addTask() {
      if (this.task_name === '' || this.task_url === '') {
        alert('გთხოვთ შეავსოთ ორივე ველი (დასახელება და ლინკი)')
        return
      }
      const task = {
        name: this.task_name,
        url: this.task_url,
        material_id: this.$route.params.id
      }
      this.form.tasks.push(task);

      this.task_name = '';
      this.task_url = '';
    },
    remove_tasks(index) {
      this.form.tasks.splice(index, 1);
    },

    goBack() {
      this.$router.back()
    },

    onFailure(message) {
      this.error = true;
    },
    setErrors(errors) {
      this.errors = errors;
    },
    hasError(fieldName) {
      return (fieldName in this.errors);
    },
    // clearError(event){
    //   delete this.errors[event.target.name]
    // },
    openPasswordReset() {
      this.openResetPasswords = true;
    },
  },
  mounted() {
    window.scroll(0, 0);

    setTimeout(() => {
      this.isLoading = false
    }, 1000)

    this.get_task_edit_data();

    // const permission = JSON.parse(localStorage.getItem('user')).permission
    // if (permission !== 'Administrator') {
    //   this.$router.push({
    //     path: '/Overviews',
    //   })
    // }
  },
  computed: {
    ...mapGetters({ permission: "getCurrentPermission" })
  }
}
</script>

<style scroped>
label {
  font-family: var(--FiraGoBold);
}

button:disabled {
  background-color: #a5a6e3 !important;
}
</style>