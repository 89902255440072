<template>
  <div>
    <loading :active="isLoading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :color="`var(--color-beta)`"
                 :z-index="10000"
                ></loading>
    <section id="login_wrapper">
      <div class="login_position">
        <div class="login_box card border-0">
          <div class="card-body border-0">
            <ValidationObserver v-slot="{ handleSubmit }">
              <!-- Form -->
              <form v-on:submit.prevent="handleSubmit(submitForm)" @keydown="clearError" class="rowpt-70">
                <div class="col-12">
                  <h1>ავტორიზაცია</h1>
                </div>
                <!-- Col -->
                <div class="col-12 custom-form-group">
                  <div class="alert alert-danger w-100 m-0 mb-3" v-if="failAlert">
                    მითითებული ელ.ფოსტა ან პაროლი არ არის სწორი.
                  </div>
                  <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                    <label class="custom-input-label" for="login-email">ელ.ფოსტა</label>
                    <input id="login-email" type="email" name="email" v-model="form.email" class="lss-form-control"
                      placeholder="example@example.com" />
                    <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12 custom-form-group">
                  <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                  <label class="custom-input-label" for="password">პაროლი</label>
                  <div class="custom-input-group input-group-merge">
                    <input id="password" type="password" name="password" v-model="form.password"
                      class="lss-form-control" :class="errorPassword ? 'error' : ''" placeholder="********" />
                  </div>
                
                  <!-- <div class="input-group-append">
                    <div class="input-group-text" v-on:click="show_password">
                      <div :class="isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye-slash"></i>
                      </div>
                      <div :class="!isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye"></i>
                      </div>
                    </div>
                  </div> -->
                  <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12 mt-20">
                  <div>
                    <a href="/Recover-password">
                      <p>დაგავიწყდა პაროლი?</p>
                    </a>
                  </div>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12 d-block d-sm-flex justify-content-between align-items-center mt-20">
                  <button class="btn-sent-yellow-lg d-block d-sm-none">ავტორიზაცია</button>
                  <div class="d-block d-md-flex text-center mt-4 mt-sm-0">
                    <div class="mr-0 mr-sm-3">არ გაქვს ექაუნთი? </div>
                    <a href="/Signup" class="ms-1">რეგისტრაცია</a>
                  </div>
                  <button class="btn-sent-yellow-lg d-none d-sm-block">ავტორიზაცია</button>
                </div>
                <!-- End Col -->
              </form>
              <!-- End Form -->
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import axios from 'axios';

export default {
  name: "Signin",
  data() {
    return {
      errorPassword:'',
      isLoading: true,
      fullPage: true,
      isActive: true,
      StatusPassword:'fas fa-eye-slash',
      InputType:'password',
      error:false,
      errors:{},
      form: {
        email: '',
        password: '',
      },
      failAlert: false
    }
  },
  components: {
   Loading
  },
  methods: {
    onCancel() {

    },
    submitForm(){
      this.isLoading = true;
      axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/login', this.form)
          .then((res) => {
            this.$store.commit("setCurrentPermission", res.data.permission);
            this.isLoading = false
            localStorage.setItem('user',JSON.stringify(res.data));
            /*this.$store.state.user_role=res.role;*/

            if(localStorage.getItem('user')) {
              this.$router.push({ path: '/' })
            }


          })
          .catch((error) => {
            this.isLoading = false;
            this.failAlert = true;
            var error_response=error.response.data;
            if(error.response.status==422){
              this.setErrors(error.response.data.errors);
            }else{
              this.setErrors(error.response.data.message);
            }
          });
    },
    onFailure(message){
      this.error=true;
    },
    setErrors(errors){
      this.errors=errors;
    },
    hasError(fieldName){
      return (fieldName in this.errors);
    },
    clearError(event){
      delete this.errors[event.target.name]
    },
    openPasswordReset() {
      this.openResetPasswords = true;
    }
  },
  mounted() {
    setTimeout(() => {
            this.isLoading = false
        }, 1000)
  },
}
</script>

<style scoped>
</style>