<template>
    <div>
        <loading :active="isLoading" :can-cancel="true" :color="`var(--color-beta)`" :z-index="10000"></loading>
        <Header />
        <!-- Resources -->
        <section v-if="!isLoading" class="home resources details-page ">
            <!-- Container -->
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-12 mb-3">
                        <div class="d-flex flex-wrap align-items-center border-bottom">
                            <div class="projectInnerTopBar h-auto">
                                <div v-if="permission == 'User'" class="projectLogo text-white">

                                    <img src="@/assets/image/project-icon.png">
                                </div>
                                <div v-if="permission != 'User'">
                                    <div v-if="task_status == 4" data_id="1" @click="markDetailTask(taskDetail.id, 1)"
                                        class="projectLogo text-white bg-success text-success cursor-pointer">

                                        <i class="tio-checkmark-circle fs-2"></i>
                                    </div>
                                    <div v-else data_id="1" @click="markDetailTask(taskDetail.id, 4)"
                                        class="projectLogo bg-transparent text-success border cursor-pointer">

                                        <i class="tio-checkmark-circle-outlined fs-2"></i>
                                    </div>
                                </div>

                                <div class="projectStructure">
                                    <h1 class="project-title fw-bold">{{ taskDetail.name }}</h1>
                                    <ul class="project-navigation">
                                        <li v-if="permission == 'User'" class=""><span>{{ taskDetail.project.name
                                                }}</span></li>
                                        <li v-else class=""><router-link :to="'/Project/' + taskDetail.project.id">{{
            taskDetail.project.name }}</router-link></li>
                                    </ul>
                                </div>
                            </div>


                            <button v-if="permission == 'User'" @click="doneTask(taskDetail.id, 3)"
                                :class="task_status == 3 || status_done ? 'btn-success' : 'btn-outline-success'"
                                class="btn position-relative text-decoration-none d-inline-block me-1 ms-auto">
                                შესრულება
                            </button>
                            <router-link to="/Mytask" v-if="permission == 'User'"
                                class="btn btn-primary position-relative text-decoration-none d-inline-block me-1">
                                ჩემი დავალება
                            </router-link>
                            <div class="d-none d-md-flex column-gap-1 ms-0 ms-md-auto mt-3 mt-md-0"
                                v-if="permission != 'User'">

                                <button v-if="task_status != 1" data_id="1"
                                    class="btn btn-warning position-relative text-decoration-none"
                                    @click="markDetailTask(taskDetail.id, 1)">
                                    <i class="tio-settings-back"></i> პროცესში დაბრუნება
                                </button>


                                <!-- <button v-if="task_status == 4" data_id="1" class="btn btn-warning position-relative text-decoration-none" @click="markDetailTask(taskDetail.id, 1)">
                                    <i class="tio-settings-back"></i> დაბრუნება
                                </button>
                                <button v-else data_id="1" class="btn btn-outline-success position-relative text-decoration-none" @click="markDetailTask(taskDetail.id, 4)">
                                    <i class="tio-checkmark-circle-outlined"></i> მონიშნე დასრულებულად
                                </button> -->

                                <button class="btn btn-outline-danger position-relative text-decoration-none"
                                    data-bs-toggle="modal" data-bs-target="#deleteTask">
                                    <i class="tio-remove-circle-outlined"></i> წაშალე დავალება
                                </button>
                            </div>



                        </div>
                    </div>
                    <!-- End Col -->


                    <!-- Col -->
                    <div class="col-md-7">
                        <!-- Card -->
                        <div class="card shadow-sm mb-3 position-sticky top-0">
                            <div class="card-header">
                                <div class="d-flex flex-wrap justify-content-between align-items-center">
                                    <h5 class="card-title mb-0">დეტალები</h5>
                                    <div class="d-flex d-md-none column-gap-1 ms-0 ms-md-auto mt-3 mt-md-0"
                                        v-if="permission != 'User'">

                                        <!-- <button v-if="task_status != 1" class="btn btn-outline-secondary position-relative text-decoration-none">
                                             In progress
                                        </button> -->


                                        <!-- <button v-if="task_status == 4" data_id="1" class="btn btn-success position-relative text-decoration-none" @click="markDetailTask(taskDetail.id, 1)">
                                            <i class="tio-checkmark-circle-outlined"></i> დასრულებული
                                        </button>
                                        <button v-else data_id="1" class="btn btn-outline-success position-relative text-decoration-none" @click="markDetailTask(taskDetail.id, 4)">
                                            <i class="tio-checkmark-circle-outlined"></i> მონიშნე დასრულებულად
                                        </button> -->

                                        <button v-if="task_status != 1" data_id="1"
                                            class="btn btn-warning position-relative text-decoration-none"
                                            @click="markDetailTask(taskDetail.id, 1)">
                                            <i class="tio-settings-back"></i> პროცესში დაბრუნება
                                        </button>

                                        <button class="btn btn-outline-danger position-relative text-decoration-none"
                                            data-bs-toggle="modal" data-bs-target="#deleteTask">
                                            <i class="tio-remove-circle-outlined"></i> წაშალე დავალება
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row g-3">
                                    <!-- Col -->
                                    <div class="col-6 mt-3">
                                        <span>დავალების გამცემი</span>
                                        <p class="mb-0">
                                            <small class="text-muted">{{ taskDetail.assigner.name }}</small>
                                        </p>
                                    </div>
                                    <!-- End Col -->

                                    <!-- Col -->
                                    <div class="col-6">
                                        <span>შემსრულებელი</span>
                                        <p class="mb-0">
                                            <small class="text-muted">{{ taskDetail.assigned.name }}</small>
                                        </p>
                                    </div>
                                    <!-- End Col -->

                                    <!-- Col -->
                                    <div class="col-6">
                                        <span>გაცემის თარიღი</span>
                                        <p class="mb-0">
                                            <small class="text-muted">{{ taskDetail.created_at }}</small>
                                        </p>
                                    </div>
                                    <!-- End Col -->

                                    <!-- Col -->
                                    <div class="col-6">
                                        <span>დასრულების თარიღი</span>
                                        <p class="mb-0">
                                            <small class="me-1"
                                                :class="taskDetail.due_at < moment ? 'text-danger' : 'text-success'">{{
            taskDetail.due_at }}</small>
                                            <span
                                                :class="taskDetail.due_at < moment ? 'text-bg-danger' : 'text-bg-success'"
                                                class="badge rounded-pill">{{ taskDetail.due_at < moment
            ? 'ვადაგადაცილება' : 'დრო გაქვს' }}</span>
                                        </p>
                                    </div>
                                    <!-- End Col -->

                                    <!-- Col -->
                                    <div class="col-12">
                                        <div class="addComment" :class="textareaDetails ? 'active' : ''">
                                            <div class="comment d-flex align-items-start">
                                                <div v-if="permission == 'User'"
                                                    class="rounded-circle bg-primary text-primary text-white p-3 d-flex align-items-center justify-content-center me-2"
                                                    style="font-size: 12px;height: 32px;line-height: 32px;min-width: 32px;width: 32px; text-transform: uppercase;">
                                                    {{ userName }}</div>
                                                <div v-if="permission == 'Super Administrator'"
                                                    class="rounded-circle bg-danger text-white p-3 d-flex align-items-center justify-content-center me-2"
                                                    style="font-size: 12px;height: 32px;line-height: 32px;min-width: 32px;width: 32px;text-transform: uppercase;">
                                                    {{ userName }}</div>
                                                <div v-if="permission == 'Administrator'"
                                                    class="rounded-circle bg-warning text-dark p-3 d-flex align-items-center justify-content-center me-2"
                                                    style="font-size: 12px;height: 32px;line-height: 32px;min-width: 32px;width: 32px;text-transform: uppercase;">
                                                    {{ userName }}</div>

                                                <div class="add-comment w-100 position-relative">
                                                    <!-- <ckeditor class="detailEditor w-100" v-model="comment"
                                                        @focus="textareaDetails = true" @input="textareaDetails = true"
                                                        :config="editorConfig"></ckeditor> -->

                                                    <vue-editor class="detailEditor w-100" :editorToolbar="customToolbar" @click="textareaDetails = true" @input="textareaDetails = true"  v-model="comment"></vue-editor>
                                                    <!-- <textarea v-model="comment" class="textarea" @click="textareaDetails = true" @input="textareaDetails = true" contenteditable="true"></textarea> -->
                                                    <button class="btn-comment" @click="addComment(taskDetail.id)"
                                                        :class="textareaDetails ? 'active' : ''">დამატება</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Col -->

                                </div>
                            </div>
                        </div>

                        <!-- End Card -->
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-md-5">
                        <div v-if="permission == 'Super Administrator'" class="card mb-3">
                            <div class="card-header">
                                <h5 class="card-title mb-0">რესურსის მართვა</h5>
                            </div>
                            <div class="card-body">
                                <div class="row g-3">
                                    <div class="col-12">
                                        <label>დავალება</label>
                                        <v-select v-model="update_resource" class="border rounded py-2 cursor-pointer"
                                            label="title" placeholder="აირჩიე დავალება" @input="updateResource"
                                            :options="allTasks"></v-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="taskDetail.taskUrl" class="card mb-3">
                            <div class="card-header">
                                <h5 class="card-title mb-0">დავალების ინფორმაცია</h5>
                            </div>
                            <div class="card-body">
                                <div class="row g-3">
                                    <div class="col-12">
                                        <label class="d-block mb-1">ვიდეო რესურსი:</label>
                                        <a class="btn btn-primary" :href="taskDetail.ResourceUrl" target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-file-earmark-play me-2"
                                                viewBox="0 0 16 16">
                                                <path
                                                    d="M6 6.883v4.234a.5.5 0 0 0 .757.429l3.528-2.117a.5.5 0 0 0 0-.858L6.757 6.454a.5.5 0 0 0-.757.43z" />
                                                <path
                                                    d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                            </svg>
                                            რესურსის ლინკი
                                        </a>
                                    </div>
                                    <div class="col-12">
                                        <label class="d-block mb-1">დავალება:</label>
                                        <a class="btn btn-primary" :href="taskDetail.taskUrl" target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-card-text me-2" viewBox="0 0 16 16">
                                                <path
                                                    d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                                                <path
                                                    d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8m0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5" />
                                            </svg>
                                            დავალების ლინკი
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title mb-0">კომენტარები: {{ taskDetail.comment.length }}</h5>
                            </div>
                            <div class="card-body">
                                <ul class="list-unstyled">
                                    <!-- Comments -->
                                    <li :class="comments.s_color + ' pb-3 d-flex mb-2 w-100'"
                                        v-for="(comments, index) in taskDetail.comment" :key="index">
                                        <span
                                            :class="comments.color + ' ' + comments.f_color + ' rounded-circle  text-dark p-3 d-flex align-items-center justify-content-center me-2'"
                                            style="font-size: 12px;height: 32px;line-height: 32px;min-width: 32px;width: 32px;">
                                            {{ comments.short_name }}
                                        </span>
                                        <div class="w-100 break-word">
                                            <div class="d-flex align-items-center gap-2">
                                                <span class="w-100">
                                                    <strong>
                                                        {{ comments.name }}</strong>
                                                </span>
                                                <span v-if="permission != 'User'" class="float-end">
                                                    <button type="button"
                                                        @click="deleteComment(comments.id, taskDetail.id);"
                                                        class="btn-close"></button>
                                                </span>


                                            </div>
                                            <p class="w-100 mb-0">
                                                <small class="text-muted">
                                                    {{ comments.created_at }}
                                                </small>
                                            </p>
                                            <p class="w-100 mb-0">
                                                <span :class="'badge rounded-pill' + ' text-' + comments.badge"> {{
                                                    comments.role}}</span>
                                            </p>
                                            <p class="mb-0 mt-2 comments" v-html="comments.comment"></p>
                                        </div>
                                    </li>
                                </ul>
                                <!-- End Comments -->
                            </div>
                        </div>
                    </div>
                    <!-- End Col -->
                </div>
            </div>
            <!-- End Container -->
        </section>
        <!-- End Resources -->



        <!-- Modal -->
        <div class="modal fade" id="deleteTask" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Delete Task</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        are you sure?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="deleteTask(taskDetail.id)">Delete</button>
                    </div>
                </div>
            </div>
        </div>




    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import axios from 'axios';
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'Details',
    data() {
        return {
            // loader
            isLoading: false,
            taskDetail: {
                name: '',
                assigned: {},
                assigner: {},
                project: {},
                comment: {}
            },
            detailsTask: true,
            textareaDetails: false,
            comment: '',
            customToolbar: [
                ["bold", "italic", "underline"],
                ['link'],
                [{ list: "ordered" }, { list: "bullet" }],
                ["image", "code-block"]
            ],
            editorConfig: {
                toolbar: [{ name: 'basicstyles', items: ['Bold', 'Italic', 'Link', 'Underline', 'Undo', 'Redo', 'NumberedList', 'BulletedList', 'Format', 'Source'] }]
            },
            editorConfig_Description: {
                toolbar: [{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Undo', 'Redo', 'NumberedList', 'BulletedList', 'Format'] }]
            },
            itemId: this.$route.params.id,
            task_status: '',
            status_done: false,
            userName: '',
            moment: '',
            allTasks: [],
            update_resource: ''
        }
    },
    components: {
        Header,
        Loading
    },
    methods: {
        showDetail() {
            this.isLoading = true;

            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/GetById/' + this.itemId, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    this.taskDetail = response.data;

                    if (response.data.description != null) {
                        this.description = response.data.description;
                    }
                    this.isLoading = false;

                    this.task_status = this.taskDetail.status_id;




                });

        },

        doneTask(id, status) {

            this.status_done = true;
            this.task_status = status;


            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/setStatus', {
                'project_id': this.taskDetail.project.id,
                'id': id,
                'status_id': status
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {

                })
                .catch((error) => {
                    this.memberError = true;
                });
        },

        markDetailTask(id, status) {

            console.log(status)

            // for completed tasks
            if (status === 4) {
                this.task_status = 4;
            } else if (status === 1) {
                this.task_status = 1;
            }


            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/setStatus', {
                'project_id': this.taskDetail.project.id,
                'id': id,
                'status_id': status
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {

                })
                .catch((error) => {
                    this.memberError = true;
                });
        },

        addComment(id) {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/add-task-comment', {
                task_id: id,
                comment: this.comment
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    this.showDetail(id);



                    setTimeout(function () {
                        var div = document.querySelector("#comments");
                        div.scrollTop = div.scrollHeight - div.clientHeight;

                    }, 500);





                })
                .catch(error => {

                })

            this.comment = '';
            this.textareaDetails = false;
        },

        deleteComment(id, detail_id) {

            if (id) {
                const token = JSON.parse(localStorage.getItem('user')).access_token;
                axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/DeleteComment/' + id + '/' + detail_id, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => {
                        this.showDetail(detail_id);
                    })
                    .catch((error) => {
                        this.memberError = true;
                    });
            }
        },

        deleteTask(id) {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/' + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    // Handle successful response

                    window.location.href = '/Project/' + this.taskDetail.project.id

                    // this.$router.push({ path: `/Project/`+this.taskDetail.project.id })
                })
                .catch(error => {
                    // Handle error
                    console.log(error.response.data.error);
                });
        },
        getDateTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            const day = now.getDate().toString().padStart(2, '0');
            const dateTime = `${year}-${month}-${day}`;
            this.moment = dateTime;
            return dateTime;
        },

        getAllTasks() {

            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/resource/getTasks/')
                .then(response => {

                    this.allTasks = response.data
                        .filter(element => element.TaskScope && element.TaskScope.task_id)
                        .map(element => ({
                            id: element.TaskScope.task_id,
                            title: element.TaskScope.title
                        }));

                });
        },

        updateResource() {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/UpdateTask/' + this.$route.params.id,
                {
                    resource_task_id: this.update_resource
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    this.showDetail();
                })
                .catch(error => {
                    // Handle error
                    console.log(error.response.data.error);
                });
        }
    },
    mounted() {

        window.scroll(0, 0);
        this.showDetail();
        this.getDateTime();
        this.getAllTasks();

        const user_info = JSON.parse(localStorage.getItem('user'));
        this.userName = user_info.user.name;
        let username = this.userName.split(" ");
        let firstName = username[0];
        let lastName = username[1];
        this.userName = firstName[0] + lastName[0]

    },
    computed: {
        ...mapGetters({ permission: "getCurrentPermission" })
    }
}
</script>

<style scroped>
.break-word {
    word-break: break-word;
}

.details-page #offcanvasRightLabel {
    cursor: pointer;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
    padding: 0 8px;
    border: 1px solid #000000;
    border-radius: 6px;

    transition: all 0.3s;
    user-select: none;
}

.details-page #offcanvasRightLabel:hover {
    color: var(--green);
    border-color: var(--green);
    background-color: #157f0712;
}

.details-page #offcanvasRightLabel.active {
    color: var(--green);
    border-color: var(--green);
    transition: all 0.3s;
}

.details-page #offcanvasRightLabel.active:hover {
    color: var(--greenH);
    border-color: var(--greenH);
}

.details-page .addComment {
    /* position: absolute; */
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 20px;

    background: #f0f0f0;
    transition: height 0.3s;
}

.details-page .addComment .comment {
    position: relative;
    transition: height 0.3s;
}

.details-page .addComment .comment .add-comment {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.details-page .addComment .comment .add-comment textarea {
    width: 100%;
}

.details-page .addComment .comment .add-comment .btn-comment {
    position: absolute;
    bottom: -100%;
    padding: 0 10px;
    height: 36px;
    border-radius: 5px;
    background: var(--color-beta);
    color: #fff;
    outline: none;
    border: 1px solid transparent;
    margin-top: 10px;
    visibility: hidden;
    opacity: 0;

    transition: all 0.1s;
}

.details-page .addComment .comment .add-comment button.active {
    position: relative;
    visibility: visible;
    opacity: 1;
    margin-top: 10px;
    bottom: 0;
}
</style>