<template>
  <router-view class="pt-100"/>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default({
  methods:{
    ...mapActions({ addPermission: "setCurrentPermission" }),
  },
  mounted(){
    this.addPermission();
  }
})
</script>

<style lang="scss">
.pt-100 {
  padding-top: 100px;
}
</style>
