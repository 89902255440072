<template>
   <div v-if="permission === 'Administrator' || permission === 'Super Administrator'">
    <loading :active="isLoading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :color="`var(--color-beta)`"
                 :z-index="10000"
                ></loading>
        <Header />
         <!-- Resources -->
        <section class="home resources">
            <!-- Container -->
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-12 mb-3">
                        <div class="d-flex align-items-start align-items-md-center border-bottom">
                          <div class="projectInnerTopBar">
                            <div class="projectLogo text-white">
                                <img src="@/assets/image/project-icon.png">
                            </div>
                          </div>
                        
                          <div class="projectInnerTopBar h-auto overflow-x-auto overflow-y-hidden">
                            <div class="projectStructure overflow-x-auto">
                                <h1 class="project-title fw-bold">რესურსის დამატება</h1>
                                <ul class="project-navigation">
                                    <li><router-link class="text-nowrap" to="/Overviews">რესურსების სია</router-link></li>
                                    <li class="active"><router-link class="text-nowrap" to="/AddResource">დაამატე რესურსი</router-link></li>
                                </ul>
                            </div>
                          </div>
                        </div>
                    </div>
                    <!-- End Col -->


                    <!-- Col -->
                    <div class="col-12">
                        <!-- Card -->
                        <div class="card shadow-sm mb-3">
                            <div class="card-body">
                               
                                <ValidationObserver v-slot="{ handleSubmit }">
                                    <form @submit.prevent="handleSubmit(submitForm)" class="row g-4">
                                        <div class="col-md-6">
                                            <label for="name" class="form-label">რესურსის დასახელება</label>
                                            <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                                <input type="text" class="form-control"  v-model="form.name" id="name">
                                                <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-12">
                                            <label class="form-label">აღწერა</label>
                                            <!-- <vue-editor class="detailEditor" :editorToolbar="customToolbar" v-model="form.description"></vue-editor> -->
                                            <ValidationProvider name="description" rules="required" v-slot="{ errors }">
                                                <ckeditor class="detailEditor"  v-model="form.description"></ckeditor>
                                                <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                            </ValidationProvider>
    
                                        </div>
                                        <div class="col-12">
                                            <label class="form-label d-block">ლოგოს ატვირთვა</label>
                                            <!-- File Input -->
                                            <!-- <input id="uploadlogo" type="file" @change="handleFileChange" multiple /> -->
                                            <vue-dropzone ref="myVueDropzone" id="dropzone" :headers="dropzoneOptions.headers" :options="dropzoneOptions" v-on:vdropzone-success="logoRes" v-bind:useCustomSlot="true">
                                                <div class="dropzone-custom-content">
                                                    <h3 class="dropzone-custom-title"><i class="tio-add-photo"></i></h3>
                                                    <p>ატვირთე ლოგო</p>
                                                </div>
                                            </vue-dropzone>
    
                                        </div>

                                        <div class="col-md-4">
                                            <label class="form-label">სტატუსი</label>
        
                                            <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                                                <v-select id="status"  class="form-control" label="name" v-model="form.status" placeholder="აირჩიე სტატუსი" :options="[
                                                    { name: 'საჯარო', id: 'public' },
                                                    { name: 'დაფარული', id: 'private' }
                                                ]"></v-select>
                                                <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                            </ValidationProvider>
        
                                        </div>
                                        <div class="col-12">
                                            <button type="submit" :disabled="!form.image"  class="btn-members"><i class="tio-add"></i> დამატება</button>
                                        </div>
                                    </form>
                                </ValidationObserver>

                            </div>
                        </div>
                        <!-- End Card -->
                    </div>
                    <!-- End Col -->
                </div>
            </div>
             <!-- End Container -->
        </section>
        <!-- End Resources -->
   
       </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import axios from 'axios';
import { mapGetters } from 'vuex'

export default {
    name: 'Add-Category',
    data() {
        return {
            form: {
                name: '',
                description: '',
                image: '',
                status: ''
            },
            // loader
            isLoading: true,
            // Vue Editor
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["image", "code-block"]
            ],

            selectedFiles: [],
            // for uploading
            dropzoneOptions: {
                url: process.env.VUE_APP_BACKEND_URL + '/auth/resource/uploadImage',
                thumbnailWidth: 150,
                maxFiles: 1,
                headers: {
                'Cache-Control': null,
                'X-Requested-With': null,
                'authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
                },
            }
        }
    },
    components: {
        Header,
        Loading
    },
    methods: {
        onCancel() { },
        submitForm() {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            // make POST request with Axios
            axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/resource/add', this.form, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    // handle success
                    this.$router.push('/Overviews');

                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
        },
        logoRes(file, response) {
            // console.log(file);
            // console.log(response);
            this.form.image = response.success
        }
    },
    mounted() {
        window.scroll(0, 0);

        setTimeout(() => {
            this.isLoading = false
        }, 1000)

        // const permission = JSON.parse(localStorage.getItem('user')).permission
        // if(permission !== 'Administrator') {
        //     this.$router.push({
        //         path: '/Overviews',
        //     })
        // }

    },
    computed: {
        ...mapGetters({ permission: "getCurrentPermission" })
    }
}
</script>

<style scroped>
 label {
  font-family: var(--FiraGoBold)!important;
 }
</style>