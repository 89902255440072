<template>
    <div v-if="permission === 'Administrator' || permission === 'Super Administrator'">
     <loading :active="isLoading"
                  :can-cancel="true"
                  :on-cancel="onCancel"
                  :color="`var(--color-beta)`"
                  :z-index="10000"
                 ></loading>
         <Header />
          <!-- Resources -->
         <section class="home resources">
             <!-- Container -->
             <div class="container">
                 <div class="row">
                    <!-- Col -->
                    <div class="col-12 mb-3">
                        <div class="d-flex align-items-start align-items-md-center border-bottom">
                          <div class="projectInnerTopBar">
                            <div class="projectLogo text-white">
                                <img src="@/assets/image/project-icon.png">
                            </div>
                          </div>
                        
                          <div class="projectInnerTopBar h-auto overflow-x-auto overflow-y-hidden">
                            <div class="projectStructure overflow-x-auto">
                                <h1 class="project-title fw-bold">დავალების დამატება</h1>
                                <ul class="project-navigation overflow-x-auto">
                                  <li><router-link class="text-nowrap" to="/Challenges">კატეგორიების სია</router-link></li>
                                  <li><router-link class="text-nowrap" :to="'/Challenge_list/'+this.$route.params.id">დავალებები</router-link></li>
                                  <li class="active" v-if="permission === 'Administrator' || permission === 'Super Administrator'"><router-link class="text-nowrap" :to="'/Add_challenge/'+this.$route.params.id">დაამატე დავალება</router-link></li>
                                </ul>
                            </div>
                          </div>
                        </div>
                    </div>
                    <!-- End Col -->
 
 
                     <!-- Col -->
                     <div class="col-12">
                         <!-- Card -->
                         <div class="card shadow-sm mb-3">
                             <div class="card-body">
                              <ValidationObserver v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(submitForm)" class="row g-4">
                                  <div class="col-md-6">
                                    <label for="name" class="form-label">აირჩიე კატეგორია</label>
                                    <ValidationProvider name="resource" rules="required" v-slot="{ errors }">
                                      <v-select class="form-control" label="name" v-model="form.challenge_id" :reduce="item => item.id" placeholder="აირჩიე რესრუსი" :options="resources"></v-select>
                                      <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                  <div class="col-md-6">
                                    <label for="name" class="form-label">დავალების დასახელება</label>
                                    <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                      <input type="text" class="form-control"  v-model="form.name" id="name" placeholder="დავალების დასახელება">
                                      <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                  <div class="col-md-6">
                                    <label for="url" class="form-label">დავალების ლინკი</label>
                                    <ValidationProvider name="url" rules="required" v-slot="{ errors }">
                                      <input class="form-control" type="text" placeholder="ლინკი" v-model="form.link">
                                      <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                  <div class="col-md-6">
                                    <label for="level" class="form-label">აირჩიე ლეველი</label>
                                    <ValidationProvider name="level" rules="required" v-slot="{ errors }">
                                      <v-select class="form-control" label="level" v-model="form.level" :reduce="item => item.id" placeholder="აირჩიე ლეველი" :options="levels"></v-select>
                                      <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                  <div class="col-12">
                                      <button type="submit"  class="btn-members"><i class="tio-add"></i> დამატება</button>
                                  </div>
                                </form>
                              </ValidationObserver>
 
                             </div>
                         </div>
                         <!-- End Card -->
                     </div>
                     <!-- End Col -->
                 </div>
             </div>
              <!-- End Container -->
         </section>
         <!-- End Resources -->
    
        </div>
 </template>
 
<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import axios from 'axios';
import { mapGetters } from 'vuex'

export default {
  name: 'Add_challenge',
  data() {
    return {
      form: {
        challenge_id: '',
        name: '',
        link: '',
        level: ''
      },
      // loader
      isLoading: true,
      resources: [],
      levels: [
        {id: 'high', level: 'რთული'},
        {id: 'middle', level: 'საშუალო'},
        {id: 'low', level: 'ადვილი'}
      ]
    }
  },
  components: {
    Header,
    Loading
  },
  methods: {
    onCancel() { },
    submitForm() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      // make POST request with Axios
      axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/ChallengeMaterial/add', this.form, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          // handle success
          this.$router.push({
            path: '/Challenge_list/'+this.$route.params.id
          })

        })
        .catch(error => {
          // handle error
          console.log(error);
        });
    },
    remove_tasks(index) {
      this.form.tasks.splice(index, 1);
    },

    goBack() {
      this.$router.back()
    },

    onFailure(message) {
      this.error = true;
    },
    setErrors(errors) {
      this.errors=errors;
    },
    hasError(fieldName){
      return (fieldName in this.errors);
    },
    // clearError(event){
    //   delete this.errors[event.target.name]
    // },
    openPasswordReset() {
      this.openResetPasswords = true;
    },


    getResources() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/ChallengeMaterial/GetChallengeList',
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          // handle success
          this.resources = response.data;

        })
        .catch(error => {
          // handle error
          console.log(error);
        });
    },
  },
  mounted() {
    window.scroll(0, 0);

    setTimeout(() => {
      this.isLoading = false
    }, 1000)

    // const permission = JSON.parse(localStorage.getItem('user')).permission
    // if (permission !== 'Administrator' || permission !== 'Super Administrator') {
    //   this.$router.push({
    //     path: '/Overviews',
    //   })
    // }

    this.getResources()
  },
  computed: {
    ...mapGetters({ permission: "getCurrentPermission" })
  }
}
</script>
 
<style scroped>
label {
  font-family: var(--FiraGoBold);
}

button:disabled {
  background-color: #a5a6e3!important;
}
</style>