import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

//* authorization
import Signin from '../views/auth/Signin.vue'
import Recovery from '../views/auth/RecoverPassword.vue'
import Signup from '../views/auth/Signup.vue'
import Settings from '../views/auth/profile/Change_password.vue'
import PasswordReset from '../views/auth/PasswordReset.vue'

//* main content
import Dashboard from '../views/Dashboard.vue'
import Project from '../views/auth/Projects/index.vue'
import Completed_Task from '../views/auth/Projects/Completed_Task.vue'
import Project_Dashboard from '../views/auth/Projects/Dashboard.vue'
import Mytask from '../views/Mytask.vue'
import MyCompletedTask from '../views/My-Completed-Task.vue'
import Members from '../views/auth/users/Members.vue'
import EditMember from '../views/auth/users/Edit.vue'
import Details from '../views/auth/Projects/Details.vue'

//* Resource's content
import Overviews from '../views/Resources/Index.vue'
import AddResource from '../views/Resources/AddResource.vue'
import EditResource from '../views/Resources/Edit.vue'

import Resources from '../views/Resources/lectures/Resource_list.vue'
import Add_lecture from '../views/Resources/lectures/Add_lecture.vue'
import EditMaterial from '../views/Resources/lectures/Edit.vue'
import InnerResource from '../views/Resources/lectures/Inner.vue'

import Add_task from '../views/Resources/lectures/tasks/Add_task.vue'
import Edit_task from '../views/Resources/lectures/tasks/Edit.vue'

//* Challanges' content
import Challenges from '../views/Challenges/Index.vue'
import Add_Category from '../views/Challenges/Add-Category.vue'
import Edit_Category from '../views/Challenges/Edit.vue'

import Challenge_list from '../views/Challenges/Inner-challange/Challenge_list.vue'
import Add_challenge from '../views/Challenges/Inner-challange/Add-challenge.vue'
import Edit_challenge from '../views/Challenges/Inner-challange/Edit.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  // Main Content
  {
    path: '/Mytask',
    name: 'Mytask',
    component: Mytask
  },
  {
    path: '/MyCompletedTask',
    name: 'MyCompletedTask',
    component: MyCompletedTask
  },
  //* Resource's content
  {
    path: '/Overviews',
    name: 'Overviews',
    component: Overviews
  },
  {
    path: '/Resources/:id',
    name: 'Resources',
    component: Resources
  },
  {
    path: '/AddResource',
    name: 'AddResource',
    component: AddResource
  },
  {
    path: '/EditResource/:id',
    name: 'EditResource',
    component: EditResource
  },
  {
    path: '/Add_lecture/:id?',
    name: 'Add_lecture',
    component: Add_lecture
  },
  {
    path: '/Add_task/:id?',
    name: 'Add_task',
    component: Add_task
  },
  {
    path: '/Edit_task/:id?',
    name: 'Edit_task',
    component: Edit_task
  },
  {
    path: '/EditMaterial/:id',
    name: 'EditMaterial',
    component: EditMaterial
  },
  {
    path: '/InnerResource/:id',
    name: 'InnerResource',
    component: InnerResource
  },
  //* Challanges' content
  {
    path: '/Challenges',
    name: 'Challenges',
    component: Challenges
  },
  {
    path: '/Add_Category',
    name: 'Add_Category',
    component: Add_Category
  },
  {
    path: '/Edit_Category/:id',
    name: 'Edit_Category',
    component: Edit_Category
  },
  {
    path: '/Challenge_list/:id',
    name: 'Challenge_list',
    component: Challenge_list
  },
  {
    path: '/Add_challenge/:id?',
    name: 'Add_challenge',
    component: Add_challenge
  },
  {
    path: '/Edit_challenge/:id?',
    name: 'Edit_challenge',
    component: Edit_challenge
  },
  //* main content
  {
    path: '/Project/:id',
    name: 'Project',
    component: Project
  },
  {
    path: '/Details/:id',
    name: 'Details',
    component: Details
  },
  {
    path: '/Completed_Task/:id',
    name: 'Completed_Task ',
    component: Completed_Task 
  },
  {
    path: '/Project_Dashboard/:id',
    name: 'Project_Dashboard',
    component: Project_Dashboard
  },

  // Authorization
  {
    path: '/Signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/Recover-password',
    name: 'Recovery',
    component: Recovery
  },
  {
    path: '/PasswordReset',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path:'/Signup',
    name:"Signup",
    component: Signup
  },
  // Settings
  {
    path:'/Settings',
    name:"Change_password",
    component: Settings
  },
  {
    path:'/Members',
    name:"Members",
    component: Members
  },
  {
    path:'/Members/:id/edit',
    name:"EditMember",
    component: EditMember

  }

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  document.title = `${ process.env.VUE_APP_TITLE } - ${ to.name }`
  next()



  if (to.name === 'Signin' || to.name==='Signup' || to.name==='Recovery' || to.name==='PasswordReset') {
    if(localStorage.getItem('user')){
      router.push('/')
    }else{
      next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
    }
  } else if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (localStorage.getItem('user')) {

    next() // i'm logged in. carry on
  } else {
    if(to.name==='Signup'){
      next({ name: 'Signup' }) // always put your redirect as the default case
    }else{
      next({ name: 'Signin' }) // always put your redirect as the default case
    }

  }
})

export default router
