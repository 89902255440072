import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

//* vue select
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

//* css imports
import '@/assets/css/style.css';
import '@/assets/css/tio-icon.css';
import '@/assets/css/themify-icons.css';

//* vue 2 Editor
import Vue2Editor from 'vue2-editor';
Vue.use(Vue2Editor);

import CKEditor from 'ckeditor4-vue';
Vue.use(CKEditor);

//* Loader
import 'vue-loading-overlay/dist/vue-loading.css';

//* Form Validation
import {
  ValidationProvider,
  extend,
} from 'vee-validate/dist/vee-validate.full';
import { ValidationObserver } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Please repeat the same password',
});

// Register vue2Dropzone as a global component
import vue2Dropzone from 'vue2-dropzone';
Vue.component('vue-dropzone', vue2Dropzone);
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
