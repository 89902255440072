<template>
    <div>
     <loading :active="isLoading"
                  :can-cancel="true"
                  :on-cancel="onCancel"
                  :color="`var(--color-beta)`"
                  :z-index="10000"
                 ></loading>
         <Header />
          <!-- Resources -->
         <section class="home resources">
             <!-- Container -->
             <div class="container">
                 <div class="row">
                    <!-- Col -->
                    <div class="col-12 mb-3">
                        <div class="d-flex align-items-start align-items-md-center border-bottom">
                            <div class="projectInnerTopBar">
                                <div class="projectLogo text-white">
                                    <img src="@/assets/image/project-icon.png">
                                </div>
                            </div>
                        
                            <div class="projectInnerTopBar h-auto overflow-x-auto overflow-y-hidden">
                                <div class="projectStructure overflow-x-auto">
                                    <div class="d-flex align-items-center">
                                        <h1 class="project-title fw-bold">{{ resource_name.name }}</h1>
                                        <router-link v-if="permission === 'Administrator' || permission === 'Super Administrator'" to="/Add_lecture" class="btn-members ms-2 px-2 py-1 position-relative text-decoration-none d-block d-md-none">
                                            <i class="tio-add"></i>
                                        </router-link>
                                    </div>
                                    <ul class="project-navigation overflow-x-auto">
                                        <li><router-link class="text-nowrap" to="/Overviews">რესურსების სია</router-link></li>
                                        <li class="active"><router-link class="text-nowrap" :to="'/Resources/'+this.$route.params.id">ლექციები</router-link></li>
                                        <li v-if="permission === 'Administrator' || permission === 'Super Administrator'"><router-link class="text-nowrap" :to="'/Add_lecture/'+this.$route.params.id">დაამატე ლექცია</router-link></li>
                                    </ul>
                                </div>
                            </div>
                            <router-link v-if="permission === 'Administrator' || permission === 'Super Administrator'" :to="'/Add_lecture/'+this.$route.params.id" class="btn-members ms-auto position-relative text-decoration-none d-none d-md-block">
                                <i class="tio-add"></i>
                                რესურსი
                            </router-link>
                        </div>
                    </div>
                    <!-- End Col -->
                
 
                     <!-- Col -->
                     <div class="col-12 mb-3">
                        <div class="filter border-bottom pb-3 d-flex flex-wrap justify-content-between align-items-center  gap-3">
                         <div class="d-flex gap-1">
                             <input v-model="filter_name" class="form-control" type="search" placeholder="მოძებნე რესურსი">
                             <button @click="filter_material()" class="btn-members">ძებნა</button>
                         </div>
                         <div>
                             <small class="fw-bold">ლექციების რაოდენობა: {{ material.length }}</small>
                         </div>
                        </div>
                     </div>
                     <!-- End Col -->
 
                     <!-- Col -->
                     <div class="col-12" v-for="(item, index) in material" :key="index">
                         <!-- Card -->
                         <div class="card shadow-sm mb-3">
                             <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <!-- <span class="d-block" style="width: 35px;">{{ item.sort }}</span> -->
                                    <span class="me-2" v-if="permission === 'Administrator' || permission === 'Super Administrator'" 
                                        data-bs-toggle="tooltip" 
                                        data-bs-placement="top"
                                        data-bs-custom-class="custom-tooltip"
                                        :data-bs-title="item.tasks_user_tag === 'checked' ? 'ლექცია ჩართულია' : 'ლექცია გამორთულია'">
                                        <i v-if="item.tasks_user_tag === 'checked'" class="tio-circle text-success"></i>
                                        <i v-else class="tio-circle text-danger"></i>
                                    </span>
                                    <div class="position-relative me-3" style="width: 50px;">
                                        <img class="me-2 bg-warning rounded w-100" :src="item.resources.Thumb" alt="">
                                        <span v-if="permission === 'Administrator' || permission === 'Super Administrator'" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger d-flex align-items-center justify-content-center" 
                                            data-bs-toggle="tooltip" 
                                            data-bs-placement="top"
                                            data-bs-custom-class="custom-tooltip"
                                            data-bs-title="დავალებების რაოდენობა" style="width: 25px;height: 25px;">
                                            {{ item.tasks_count}}
                                        </span>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between w-100 flex-wrap row-gap-2">
                                    <div class="d-flex flex-column">
                                        <router-link class="text-decoration-none me-auto" :to="'/InnerResource/'+item.id">
                                            <h4 class="card-title mb-0 text-dark"> {{ item.name }}</h4>
                                        </router-link>
                                    </div>
                                    <div class="d-flex">
                                        <a :href="'/InnerResource/'+item.id" class="btn btn-outline-dark me-1"><i class="tio-play"></i></a>
                                        <router-link :to="'/EditMaterial/'+item.id" v-if="permission === 'Administrator' || permission === 'Super Administrator'" class="btn btn-outline-primary me-1">რედაქტირება</router-link>
                                        <button v-if="permission === 'Administrator' || permission === 'Super Administrator'" class="btn btn-outline-danger" data-bs-toggle="modal" :data-bs-target="'#deleteResource_'+item.id">წაშლა</button>
                                    </div>
                                    </div>
                                 </div>
                             </div>
                         </div>
                         <!-- End Card -->
                     </div>
                     <!-- End Col -->
 
                 </div>
             </div>
              <!-- End Container -->
         </section>
         <!-- End Resources -->
 
 
         <!-- Resource Delete -->
         <div v-for="item in material" class="modal fade" :id="'deleteResource_'+item.id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
             <div class="modal-dialog">
                 <div class="modal-content">
                     <div class="modal-header">
                         <h1 class="modal-title fs-5" id="staticBackdropLabel">ლექციის წაშლა</h1>
                         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     </div>
                     <div class="modal-body">
                         ნამდვილად გსურს წაშლა?
                     </div>
                     <div class="modal-footer">
                         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
                         <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" @click="delete_lecture(item.id)">წაშლა</button>
                     </div>
                 </div>
             </div>
         </div>
         <!-- End Resource Delete -->
 
         <!-- Custom DropDown -->
         <div @click="filterMenu = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent" :class="filterMenu == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
         <div @click="filterAssigne = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent" :class="filterAssigne == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
    </div>
 </template>
 
 <script>
 import Loading from 'vue-loading-overlay';
 import Header from '@/components/Header.vue'
 import axios from 'axios';
 import { mapGetters } from 'vuex'

 export default {
     name: 'Resource_list',
     data() {
         return {
             // loader
             isLoading: true,
             // Custom DropDown
             filterMenu: false,
             filterAssigne: false,
             material: [],
             filter_name: '',
             resource_name: ''
         }
     },
     components: {
         Header,
         Loading
     },
     methods: {
         onCancel() {},
         getMaterial(){
           const token = JSON.parse(localStorage.getItem('user')).access_token;
           axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/material/GetList/'+this.$route.params.id,
               {
                 headers: {
                   'Authorization': `Bearer ${token}`
                 }
               })
               .then(response => {
                 // handle success
                this.material = response.data;
               })
               .catch(error => {
                     // handle error
                     console.log(error);
                 });
         },
         getResourceName(){
           const token = JSON.parse(localStorage.getItem('user')).access_token;
           axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/material/getName/'+this.$route.params.id,
               {
                 headers: {
                   'Authorization': `Bearer ${token}`
                 }
               })
               .then(response => {
                 // handle success
                this.resource_name = response.data;
               })
               .catch(error => {
                     // handle error
                     console.log(error);
                 });
         },
         filter_material() {
             this.isLoading = true;
             const token = JSON.parse(localStorage.getItem('user')).access_token;
             axios.get(process.env.VUE_APP_BACKEND_URL + `/auth/material/Filter/${this.$route.params.id}?name=` + this.filter_name,
                 {
                     headers: {
                         'Authorization': `Bearer ${token}`
                     }
                 })
                 .then(response => {
                     // handle success
                     this.material = response.data;
                     this.isLoading = false;
                 })
                 .catch(error => {
                     // handle error
                     console.log(error);
                 });
         },
         delete_lecture(id) {
             this.isLoading = true;
             const token = JSON.parse(localStorage.getItem('user')).access_token;
             axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/material/delete/' + id,
                 {
                     headers: {
                         'Authorization': `Bearer ${token}`
                     }
                 })
                 .then(response => {
                     // handle success
                    //  this.material = response.data;
                     this.getMaterial();
                     this.isLoading = false;
                 })
                 .catch(error => {
                     // handle error
                     console.log(error);
                 });
         }
     },
     mounted() {
         window.scroll(0, 0);

         this.getMaterial();
         this.getResourceName();
         setTimeout(() => {
             this.isLoading = false
         }, 1000)
     },
     setup() {
         setTimeout(() => {
             const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
             const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
         }, 1000)
     },
     watch: {
        filter_name: {
            handler(value, newValue) {
                if(!value) {
                    this.getMaterial();
                }
            }
        }
     },
     computed: {
         ...mapGetters({ permission: "getCurrentPermission" })
     }
 }
 </script>
 
 <style scroped>
 
 </style>