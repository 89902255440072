<template>
    <tr :class="item.status.text === 'Done' ? 'bg-success-light' : ''">
        <td class="task-name">
            <div class="d-flex justify-content-between float-end">
                <span>
                    <i v-if="permission !== 'User'" @click="markTask(item.id, index, $event, '4')"
                        class="tio-checkmark-circle-outlined task-mark"></i>
                    <input style="width: 350px" placeholder="დაწერე დავალების სახელი" @input="inputGrow"
                        @keyup="debouncedUpdateTasks(item.id, index)" :disabled="permission === 'User'"
                        v-model="item.name" type="text" class="text" />
                    <span class="text-inputValue"></span>
                </span>
                <span class="badge-details" @click="showDetail(item.id)">
                    დეტალები
                    <i class="tio-arrow-forward"></i>
                </span>
            </div>
        </td>
        <td class="assigne">
            <v-select :disabled="permission === 'User'" label="text" v-model="item.assigne" placeholder="User"
                @input="debouncedUpdateTasks(item.id, index)" :options="membersOfProjectList"></v-select>
        </td>
        <td class="date">
            <input :class="item.date < moment ? 'text-danger' : 'text-success'" type="date" class="text-center"
                v-model="item.date" :disabled="permission === 'User'" placeholder="Date"
                @change="debouncedUpdateTasks(item.id, index)" />
        </td>
        <td class="priority">
            <v-select label="text" style="color: red" v-model="item.priority" :disabled="permission === 'User'"
                @input="debouncedUpdateTasks(item.id, index)" placeholder="პრიორიტეტი"
                :options="PriorityData"></v-select>
        </td>
        <td class="status">
            <v-select label="text" v-model="item.status" @input="debouncedUpdateTasks(item.id, index)"
                placeholder="სტატუსი" :options="StatusData"></v-select>
        </td>
    </tr>
</template>

<script>
import { debounce } from 'lodash';

export default {
    name: 'TaskRow',
    props: {
        item: Object,
        index: Number,
        permission: String,
        membersOfProjectList: Array,
        PriorityData: Array,
        StatusData: Array,
        moment: String
    },
    methods: {
        markTask(id, index, event, status) {
            this.$emit('mark-task', { id, index, event, status });
        },
        inputGrow() {
            // Add input grow logic here
        },
        showDetail(id) {
            this.$emit('show-detail', id);
        },
        updateTasks(id, index) {
            this.$emit('update-tasks', { id, index });
        },
        debouncedUpdateTasks: debounce(function (id, index) {
            this.updateTasks(id, index);
        }, 300)
    }
};
</script>
