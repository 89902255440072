<template>
  <div v-if="permission">
    <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :color="`var(--color-beta)`" :z-index="10000">
    </loading>
    <Header />
    <section class="home">
      <!-- Main Content -->
      <div class="container">
        <div class="row" v-if="permission == 'Super Administrator' || permission == 'Administrator'
    ">
          <!-- Col -->
          <div class="col-12 mb-3">
            <div class="d-flex align-items-center border-bottom">
              <div class="projectInnerTopBar">
                <div class="projectLogo text-white">
                  <img src="@/assets/image/project-icon.png" />
                </div>
              </div>

              <div class="projectInnerTopBar h-auto overflow-x-auto">
                <div class="projectStructure overflow-x-auto">
                  <h1 class="project-title fw-bold">
                    {{ projectData.project.name ? projectData.project.name : '...' }}
                  </h1>
                  <ul class="project-navigation overflow-x-auto">
                    <li class="d-none" v-if="permission != 'User'">
                      <a class="text-nowrap" :href="'/Project_Dashboard/' + projectData.project.id">დეშბორდი</a>
                    </li>
                    <li class="active">
                      <router-link class="text-nowrap" :to="'/Project/' + projectData.project.id">მიმდინარე
                        დავალებები</router-link>
                    </li>
                    <li>
                      <router-link class="text-nowrap" :to="'/Completed_Task/' + projectData.project.id">დასრულებული
                        დავალებები</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <button v-if="permission == 'Administrator' || permission == 'Super Administrator'"
                class="btn-members ms-auto position-relative d-none d-md-block" data-bs-toggle="modal"
                data-bs-target="#members">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-people-fill" viewBox="0 0 16 16">
                  <path
                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z">
                  </path>
                </svg>
                წევრები
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {{ projectData.members_cnt }}
                </span>
              </button>
            </div>
          </div>
          <!-- End Col -->

          <div class="col-12">
            <div class="row g-2">

              <div class="col-12">
                <label>გაფრთხილებები <span class="text-danger fw-bold">{{ warningTasks?.length }}</span></label>
              </div>

              <!-- Col -->
              <div v-for="(item, index) in warningTasks" class="col-md-2" :key="index">
                <div class="card h-100 rounded text-danger border-danger">
                  <div class="card-body">
                    <h5>{{ item.NAME }}</h5>
                    <div class="font-size-35">{{ item.cnt }}</div>
                  </div>
                </div>
              </div>
              <!-- /Col -->

              <div class="col-12 mb-3 mt-3">
                <div class="border-bottom"></div>
              </div>

            </div>
          </div>

          <div class="col-12 mb-3">
            <div class="filter border-bottom pb-3 d-flex align-content-center gap-3">
              <!-- <button type="button" class="btn btn-ligth border d-none" v-if="permission != 'User'"
                @click="addTaskPlus()">
                <i class="tio-add"></i> Add task
              </button>
              <div class="btn-group d-flex justify-content-between">
                <button type="button" @click="filter()" class="btn btn-light border dropdown-toggle">
                  <i class="tio-filter-list"></i> ფილტრი
                </button>
                <button v-if="assigne_id != ''" type="button" @click="clearFilter()"
                  class="btn btn-warning border dropdown-toggle">
                  <i class="tio-clear-circle"></i> გასუფთავება
                </button>
                <ul class="dropdown-menu filterMenu shadow" :class="filterMenu == true ? 'd-block' : ''">
                  <li @click="(filterAssigne = true), (filterMenu = false)">
                    <a class="dropdown-item" href="#">სორტირება</a>
                  </li>
                </ul>
                <ul class="dropdown-menu filterMenu shadow" :class="filterAssigne == true ? 'd-block' : ''">
                  <li class="pe-3 px-3">
                    <label class="mb-1 fw-bold">შემსრულებელი</label>
                    <div class="dropdown-custom-item d-flex align-items-center gap-2" style="min-width: 500px">
                      <v-select :disabled="permission == 'User'" class="assigne w-100 border" label="text"
                        v-model="filters.assigne" placeholder="აირჩიე წევრები"
                        :options="membersOfProjectList"></v-select>
                    </div>
                  </li>
                  <li class="pe-3 px-3 mt-2">
                    <label class="mb-1 fw-bold">სტატუსი</label>
                    <div class="dropdown-custom-item d-flex align-items-center gap-2" style="min-width: 500px">
                      <v-select :disabled="permission == 'User'" class="assigne w-100 border" label="text"
                        v-model="filters.status" placeholder="აირჩიე სტატუსი" :options="StatusData"></v-select>
                    </div>
                  </li>
                  <li class="pe-3 px-3 mt-3">
                    <div class="row">
                      <div class="col-md-12 pe-0">
                        <div class="float-end">
                          <button class="btn-members float-start" type="button" @click="search()">
                            ძებნა
                          </button>
                          <button class="btn btn-danger mx-2 float-start" type="button" @click="clearFilter()">
                            გასუფთავება
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <button v-if="permission == 'Administrator' ||
    permission == 'Super Administrator'
    " class="btn-members ms-auto position-relative d-block d-md-none" data-bs-toggle="modal" data-bs-target="#members">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-people-fill" viewBox="0 0 16 16">
                  <path
                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z">
                  </path>
                </svg>
                წევრები
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {{ projectData.members_cnt }}
                </span>
              </button> -->

              <div class="d-flex align-items-center flex-wrap w-100 w-md-75 gap-2">
                <div class="w-100 w-md-25">
                  <label>წევრები</label>
                  <v-select :disabled="permission == 'User'" class="assigne form-control w-100 border" label="text"
                    v-model="filters.assigne" placeholder="აირჩიე წევრები" :options="membersOfProjectList"></v-select>
                </div>
                <div class="w-100 w-md-25">
                  <label>სტატუსი</label>
                  <v-select :disabled="permission == 'User'" class="assigne form-control w-100 border" label="text"
                    v-model="filters.status" placeholder="აირჩიე სტატუსი" :options="StatusData"></v-select>
                </div>

                <div class="d-flex align-items-center">
                  <button class="btn-members mt-4 rounded-end-0 py-md-2" type="button" @click="search()">
                    ძებნა
                  </button>
                  <button type="button" @click="clearFilter()" class="btn btn-warning mt-4 rounded-start-0">
                    <i class="tio-clear-circle"></i> გასუფთავება
                  </button>
                </div>

              </div>
            </div>
          </div>
          <div class="col-12 position-relative mb-5 pb-5">
            <!-- Padding Task-->
            <h1 class="title">
              <div class="section-arrow">
                <svg class="Icon RightTriangleIcon" viewBox="0 0 32 32" aria-hidden="true" focusable="false">
                  <path
                    d="M13.707,6.707L23,16l-9.293,9.293C13.077,25.923,12,25.477,12,24.586V7.414C12,6.523,13.077,6.077,13.707,6.707z">
                  </path>
                </svg>
              </div>
              მიმდინარე დავალებები

              <div class="add-task-plus" @click="addTaskPlus()" v-if="permission != 'User'">
                <i class="tio-add"></i>
              </div>
            </h1>
            <div class="table-responsive pb-5">
              <table class="padding-table table table-bordered">
                <thead>
                  <tr>
                    <th class="task-name">დავალების დასახელება</th>
                    <th>შემსრულებელი</th>
                    <th class="text-center">დას. თარიღი</th>
                    <th>პრიორიტეტი</th>
                    <th>სტატუსი</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Tasks Data -->

                  <task-row
                    v-for="(item, index) in getDataTasks"
                    :key="index"
                    :item="item"
                    :index="index"
                    :permission="permission"
                    :membersOfProjectList="membersOfProjectList"
                    :PriorityData="PriorityData"
                    :StatusData="StatusData"
                    :moment="moment"
                    @mark-task="markTask"
                    @show-detail="showDetail"
                    @update-tasks="updateTasks"
                  ></task-row>
                   <!-- End Tasks Data -->

                  <tr class="add-task" v-if="permission !== 'User'" @click="addTaskPlus">
                    <td colspan="5">
                      <i class="tio-add"></i> დაამატე დავალება...
                    </td>
                  </tr>
                  
                  <!-- End Add New Task -->
                </tbody>
              </table>
            </div>
            <!-- End Padding Task -->
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12">ეს გვერდი არ არსებობს</div>
        </div>
      </div>
    </section>

    <!-- OffCanvas ------------------------>

    <!-- details canvas -->
    <div v-if="1 != 1" class="details offcanvas offcanvas-end" ref="offCanvas" @click="handleClose()" tabindex="-1"
      id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div v-if="permission != 'User'" class="offcanvas-header border-bottom">
        <h5 class="mb-0 position-relative" id="offcanvasRightLabel">
          <div data_id="1" class="position-absolute top-0 w-100 h-100" @click="markDetailTask(taskDetail.id, 4, $event)"
            style="left: 0"></div>
          <i :class="detailsTask == true
    ? 'tio-checkmark-circle-outlined'
    : 'tio-checkmark-circle'
    "></i>
          <span v-if="detailsTask == true"> Mark completed</span>
          <span v-else> Completed</span>
        </h5>

        <button type="button" class="btn-close text-reset" @click="showDescription = false" data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
      </div>
      <div v-else class="offcanvas-header">
        <h5 class="mb-0 position-relative" id="offcanvasRightLabel">
          <div data_id="1" class="position-absolute top-0 w-100 h-100" @click="markDetailTask(taskDetail.id, 3, $event)"
            style="left: 0"></div>
          <i :class="detailsTask == true
    ? 'tio-checkmark-circle-outlined'
    : 'tio-checkmark-circle'
    "></i>
          <span v-if="detailsTask == true"> Mark Done</span>
          <span v-else> Done</span>
        </h5>

        <button type="button" class="btn-close text-reset" @click="showDescription = false" data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
      </div>
      <div id="comments" class="offcanvas-body position-relative px-0" @click="textareaDetails = false"
        :class="textareaDetails ? 'active' : ''">
        <div class="container px-0 mb-4">
          <div class="row align-items-center mx-1">
            <!-- Details Col -->

            <div class="col-8">
              <h5 class="mb-0">{{ taskDetail.name }}</h5>
              <small class="text-muted">{{ taskDetail.project.name }}</small>
            </div>
            <div class="col-4 mt-0 text-end">
              <button type="button" @click="markDetailTask(taskDetail.id, 3)"
                class="btn btn-sm btn-outline-success me-1">
                Done
              </button>
              <button v-if="permission != 'User'" type="button" @click="deleteTask(taskDetail.id)"
                class="btn btn-sm btn-outline-danger">
                Delete Task
              </button>
            </div>

            <div class="col-6 mt-3">
              <span>Assigner</span>
              <p class="mb-0">
                <small class="text-muted">{{ taskDetail.assigner.name }}</small>
              </p>
            </div>

            <div class="col-6 mt-3">
              <span>Assigne</span>
              <p class="mb-0">
                <small class="text-muted">{{ taskDetail.assigned.name }}</small>
              </p>
            </div>

            <div class="col-6 mt-1">
              <span>Created at</span>
              <p class="mb-0">
                <small class="text-muted">{{ taskDetail.created_at }}</small>
              </p>
            </div>

            <div class="col-6 mt-1">
              <span>Due at</span>
              <p class="mb-0">
                <small class="text-muted">{{ taskDetail.due_at }}</small>
              </p>
            </div>

            <!-- <div class="col-12  mt-1">
                        <span>Description</span>
                        <div  v-if="permission!='User'" class="detailsDescription" :class="showDescription ? 'active' : ''">
                            <p @click="showDescription = true" class="mb-0">
                                <small v-html="description" class="text-muted" style="word-wrap: break-word;"></small>
                            </p>
                            <ckeditor class="detailEditor"  v-model="description" @input="updateDescription(taskDetail.id)" :config="editorConfig_Description"></ckeditor>


                            <i @click="showDescription = false" class="tio-clear-circle-outlined offDescription"></i>
                        </div>
                        <div  v-else class="detailsDescription">
                          <p v-html="taskDetail.description"></p>
                        </div>
                      </div> -->
          </div>
        </div>

        <!-- get Comment List -->
        <div class="getCommentList mt-auto" v-if="taskDetail.comment.length > 0">
          <h5 class="px-3">Comments</h5>
          <ul class="px-3 py-3 list-unstyled">
            <!-- comments -->
            <li :class="comments.s_color + ' pb-3 d-flex mb-2 w-100'" v-for="(comments, index) in taskDetail.comment"
              :key="index">
              <span :class="comments.color +
    ' ' +
    comments.f_color +
    ' rounded-circle  text-dark p-3 d-flex align-items-center justify-content-center me-2'
    " style="
                  font-size: 12px;
                  height: 32px;
                  line-height: 32px;
                  min-width: 32px;
                  width: 32px;
                ">
                {{ comments.short_name }}
              </span>
              <div class="w-100">
                <div class="d-flex align-items-center gap-2">
                  <span class="w-100">
                    <strong> {{ comments.name }}</strong>
                  </span>
                  <span v-if="permission != 'User'" class="float-end">
                    <button type="button" @click="deleteComment(comments.id, taskDetail.id)" class="btn-close"></button>
                  </span>
                </div>
                <p class="w-100 mb-0">
                  <small class="text-muted">
                    {{ comments.created_at }}
                  </small>
                </p>
                <p class="w-100 mb-0">
                  <span :class="'badge rounded-pill' + ' text-' + comments.badge">
                    {{ comments.role }}</span>
                </p>
                <p class="mb-0 mt-2 comments" v-html="comments.comment"></p>
              </div>
            </li>
          </ul>
        </div>
        <!-- End get Comment List -->
      </div>

      <div class="offcanvas-footer border-top">
        <!-- Comment -->
        <div class="addComment" :class="textareaDetails ? 'active' : ''">
          <div class="comment d-flex align-items-start">
            <div v-if="permission == 'User'"
              class="rounded-circle bg-danger text-primary text-white p-3 d-flex align-items-center justify-content-center me-2"
              style="
                font-size: 12px;
                height: 32px;
                line-height: 32px;
                min-width: 32px;
                width: 32px;
              ">
              {{ userName }}
            </div>
            <div v-if="permission == 'Super Administrator'"
              class="rounded-circle bg-danger text-white p-3 d-flex align-items-center justify-content-center me-2"
              style="
                font-size: 12px;
                height: 32px;
                line-height: 32px;
                min-width: 32px;
                width: 32px;
              ">
              {{ userName }}
            </div>
            <div v-if="permission == 'Administrator'"
              class="rounded-circle bg-warning text-dark p-3 d-flex align-items-center justify-content-center me-2"
              style="
                font-size: 12px;
                height: 32px;
                line-height: 32px;
                min-width: 32px;
                width: 32px;
              ">
              {{ userName }}
            </div>

            <div class="add-comment position-relative">
              <ckeditor class="detailEditor w-100" v-model="comment" @focus="textareaDetails = true"
                @input="textareaDetails = true" :config="editorConfig"></ckeditor>

              <!-- <vue-editor class="detailEditor" :editorToolbar="customToolbar" @click="textareaDetails = true" @input="textareaDetails = true"  v-model="comment"></vue-editor> -->
              <!-- <textarea v-model="comment" class="textarea" @click="textareaDetails = true" @input="textareaDetails = true" contenteditable="true"></textarea> -->
              <button class="btn-comment" @click="addComment(taskDetail.id)" :class="textareaDetails ? 'active' : ''">
                Comment
              </button>
            </div>
          </div>
        </div>
        <!-- End Comment -->
      </div>
    </div>

    <!-- End OffCanvas -------------------------->

    <!-- Modal ------------------------>

    <!-- Members -->
    <div v-if="permission == 'Super Administrator' || permission == 'Administrator'
    " class="modal modal-lg fade" id="members" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#member"
                  type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                  წევრები
                </button>
              </li>
              <li class="nav-item d-none" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#inviteMember"
                  type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                  მოიწვიე
                </button>
              </li>
            </ul>
            <!-- <h1 class="modal-title fs-5 fw-bold" id="staticBackdropLabel">Members</h1> -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="tab-content" id="myTabContent">
                  <!-- Member Tab Content-->
                  <div class="tab-pane fade show active" id="member" role="tabpanel" aria-labelledby="home-tab"
                    tabindex="0">
                    <div class="row">
                      <div class="col-12 mt-3">
                        <label class="fw-bold mb-2 px-3" for="membersCount">წევრები ({{ projectData.members_cnt }})
                        </label>
                        <ul class="memberList">
                          <li v-for="member in projectData.members" :key="member.id">
                            <span class="w-100 d-flex align-items-start">
                              <i class="tio-user me-2"></i>

                              <div class="d-flex flex-column testtool">
                                {{ member.name }}
                                <div class="d-flex align-items-baseline gap-2 pt-2">
                                  <span class="badge rounded-pill text-bg-warning custom_toolpit"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom"
                                    :data-bs-title="`მიმდინარე დავალება: ${member.in_progress_cnt}`">
                                    {{ member.in_progress_cnt }}
                                  </span>
                                  <span class="badge rounded-pill text-bg-primary custom_toolpit"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom"
                                    :data-bs-title="`შესრულებული: ${member.done_cnt}`">
                                    {{ member.done_cnt }}
                                  </span>
                                  <span class="badge rounded-pill text-bg-success custom_toolpit"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom"
                                    :data-bs-title="`დასრულებული: ${member.complited_cnt}`">
                                    {{ member.complited_cnt }}
                                  </span>
                                </div>
                              </div>
                            </span>
                            <span v-if="member.permission == 'Super Administrator'"
                              class="badge bg-danger text-white mb-0 text-right float-end">{{ member.permission
                              }}</span>
                            <span v-if="member.permission == 'Administrator'"
                              class="mb-0 text-right badge bg-warning text-dark float-end">{{ member.permission
                              }}</span>
                            <span v-if="member.permission == 'User'" class="mb-0 text-right badge bg-dark float-end">{{
    member.permission }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- End Member Tab Content-->

                  <!-- Invite Member Tab Content-->
                  <div class="tab-pane fade" id="inviteMember" role="tabpanel" aria-labelledby="profile-tab"
                    tabindex="0">
                    <div class="row">
                      <div class="col-12 border-bottom pb-3">
                        <label class="mb-2 px-3 fw-bold" for="addMember">მოიწვიე მეილით</label>
                        <div class="d-flex align-items-center gap-2 px-3">
                          <input id="addMember" class="form-control" placeholder="დაამატე წევრი მეილით" />
                          <button class="btn-members ms-auto">
                            <i class="tio-send"></i>
                            მოწვევა
                          </button>
                        </div>
                        <!-- Alert -->
                        <div v-if="memberError" class="alert bg-danger text-white fade show py-2 mt-2 mx-3"
                          role="alert">
                          <strong>Oops</strong> ,something went wrong.
                        </div>
                        <!-- End Alert -->
                      </div>
                      <div class="col-12 mt-3">
                        <label class="fw-bold mb-2 px-3" for="membersCount">წევრები ({{ projectData.members_cnt
                          }})</label>
                        <ul class="memberList">
                          <li v-for="member in projectData.members" :key="member.id">
                            <span><i class="tio-user"></i> {{ member.name }}</span>
                            <select v-model="member_status" :key="member.id"
                              v-on:change="member_status_change(member.id)">
                              <option selected disabled value="">
                                სტატუსი
                              </option>
                              <option value="Super Administrator">
                                სრული კონტროლი
                              </option>
                              <option value="Administrator">
                                შეუძლია რედაქტირება
                              </option>
                              <option value="User">მხოლოდ ნახვა</option>
                              <option value="remove">წაშლა</option>
                            </select>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- Invite Member Tab Content-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- End Modal ------------------------>

    <!-- Custom DropDown -->
    <div @click="filterMenu = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent"
      :class="filterMenu == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
    <div @click="filterAssigne = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent"
      :class="filterAssigne == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import Header from '@/components/Header.vue';
import vSelect from 'vue-select';
import { mapActions, mapGetters } from 'vuex';

import ListItem from '@/components/ListItem.vue';

import TaskRow from '@/components/TaskRow.vue';

export default {
  name: 'Index',
  data() {
    return {
      moment: '',
      // Detail Description
      description: 'add more description about task...',
      showDescription: false,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'code-block'],
      ],
      editorConfig: {
        toolbar: [
          {
            name: 'basicstyles',
            items: [
              'Bold',
              'Italic',
              'Link',
              'Underline',
              'Undo',
              'Redo',
              'NumberedList',
              'BulletedList',
              'Format',
              'Source',
            ],
          },
        ],
      },
      editorConfig_Description: {
        toolbar: [
          {
            name: 'basicstyles',
            items: [
              'Bold',
              'Italic',
              'Underline',
              'Undo',
              'Redo',
              'NumberedList',
              'BulletedList',
              'Format',
            ],
          },
        ],
      },
      filters: {
        assigne: '',
        status: ''
      },
      assigne_id: '',
      status_id: '',
      getDataTasks: [],
      completedTasks: [],
      detailsTask: true,
      filterMenu: false,
      filterAssigne: false,
      member: '',
      projectData: {
        members: [],
        project: [
          {
            id: '',
            name: '',
          },
        ],
      },
      membersList: [],
      membersOfProjectList: [],
      user: {
        id: '',
      },
      userName: '',
      memberError: false,
      memberSuccess: false,
      memberRemovedSuccess: false,
      member_status: '',
      PriorityData: [],
      StatusData: [],
      textareaDetails: false,
      comment: '',
      isLoading: true,
      taskDetail: {
        assigned: {},
        assigner: {},
        project: {},
        comment: {},
      },
      warningTasks: null
    };
  },
  components: {
    Header,
    'v-select': vSelect,
    Loading,
    ListItem,
    TaskRow
  },
  methods: {
    handleClose() {
      console.log('Off-canvas closed');
    },
    clearFilter() {
      this.assigne_id = '';
      this.filters.assigne = [];
      this.status_id = '';
      this.filters.status = [];
      this.getTaskList();
      this.getComplitedTasks();
    },
    onCancel() { },
    markTask(id, index, event, status) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios
        .put(
          process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/setStatus',
          {
            project_id: this.$route.params.id,
            id: id,
            status_id: status,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.setMark(event);
          this.getTaskList();
          this.getComplitedTasks();
        })
        .catch((error) => {
          this.memberError = true;
        });
    },
    markDetailTask(id, status, event) {
      if (event.target.getAttribute('data_id') == '1') {
        event.target.parentElement.classList.add('active');

        this.detailsTask = false;

        event.target.setAttribute('data_id', '2');
      } else {
        event.target.parentElement.classList.remove('active');

        this.detailsTask = true;

        event.target.setAttribute('data_id', '1');
      }
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios
        .put(
          process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/setStatus',
          {
            project_id: this.$route.params.id,
            id: id,
            status_id: status,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => { })
        .catch((error) => {
          this.memberError = true;
        });
    },
    setMark(event) {
      if (event.target.getAttribute('data_id') == '1') {
        event.target.classList.remove('tio-checkmark-circle-outlined');
        event.target.classList.add('tio-checkmark-circle');
        event.target.classList.remove('task-mark');
        event.target.classList.add('task-marked');
        event.target.setAttribute('data_id', '2');
      } else {
        event.target.classList.remove('tio-checkmark-circle');
        event.target.classList.add('tio-checkmark-circle-outlined');
        event.target.classList.remove('task-marked');
        event.target.classList.add('task-mark');
        event.target.setAttribute('data_id', '1');
      }
      console.log(event.target);
    },
    DetailsMarkTask(event) {
      if (event.target.getAttribute('data_id') == '1') {
        event.target.parentElement.classList.add('active');

        this.detailsTask = false;

        event.target.setAttribute('data_id', '2');
      } else {
        event.target.parentElement.classList.remove('active');

        this.detailsTask = true;

        event.target.setAttribute('data_id', '1');
      }
      console.log(event.target);
    },
    accordion() { },
    getTaskList() {
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/auth/Tasks/GetList/${this.$route.params.id}?assigne_id=${this.assigne_id}&status_id=${this.status_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((response) => {
          this.getDataTasks = response.data;
          this.isLoading = false;
        });
    },

    addTaskPlus() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/Add',
          {
            project_id: this.$route.params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.addNewTask(response.data.id);
        })
        .catch((error) => {
          this.memberError = true;
        });
    },
    addNewTask(id) {
      // Add New Task
      this.getDataTasks.push({
        id: id,
        name: '',
        assigne: [],
        date: '',
        priority: [],
        status: [],
      });
    },
    updateTasks({ id, index }) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios
        .put(
          `${process.env.VUE_APP_BACKEND_URL}/auth/Tasks/update`,
          {
            id,
            task_data: this.getDataTasks[index]
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .catch(() => {
          this.memberError = true;
        });
    },
    showDetail(itemId) {
      this.isLoading = true;

      this.$router.push({ path: '/Details/' + itemId });

      // const token = JSON.parse(localStorage.getItem('user')).access_token;
      // axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/GetById/'+itemId, {
      //   headers: {
      //     'Authorization': `Bearer ${token}`
      //   }
      // })
      //     .then(response => {
      //         this.taskDetail=response.data;
      //         if(response.data.description!=null){
      //           this.description=response.data.description;
      //         }
      //       this.isLoading=false;

      //     });
    },
    inputGrow() {
      /* const inputText = document.querySelectorAll('.text');
       const spanInputText = document.querySelector('.text-inputValue');

       inputText.forEach(el => {
           spanInputText.innerHTML = el.value.replace(/\s/g, '&nbsp;&nbsp;&nbsp;');
           el.style.width = spanInputText.offsetWidth + 'px';
       })*/
    },
    detailAddComment() {
      const comment = document.querySelector('.comment textarea');
      comment.parentElement.classList.toggle('active');
    },
    filter() {
      this.filterMenu = true;
      this.filterAssigne = false;
    },
    search() {
      if (this.filters?.assigne) {
        this.assigne_id = this.filters.assigne.id;
      }
      if (this.filters?.status) {
        this.status_id = this.filters.status.id;
      }
      this.getTaskList();
      this.getComplitedTasks();
    },
    member_list() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios
        .get(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/MembersList', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.membersList = response.data;
        });
    },
    members_of_project_List() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL +
          '/auth/Projects/membersOfProjectList/' +
          this.$route.params.id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.membersOfProjectList = response.data;
        });
    },
    addMember() {
      if (!this.user.id == '') {
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL +
            '/auth/Projects/add_member_to_project',
            { project_id: this.$route.params.id, user_id: this.user.id.id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.memberSuccess = true;
            setTimeout(() => {
              this.memberSuccess = false;
            }, 3000);
            this.getProjectData();
          })
          .catch((error) => {
            this.memberError = true;
            setTimeout(() => {
              this.memberError = false;
            }, 3000);
          });
      }
    },
    getProjectData() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL +
          '/auth/Projects/get_by_id/' +
          this.$route.params.id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.projectData = response.data;
        });
    },
    member_status_change(user_id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL +
          '/auth/Projects/member_status_change',
          {
            project_id: this.$route.params.id,
            user_id: user_id,
            status: this.member_status,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.memberRemovedSuccess = true;
          setTimeout(() => {
            this.memberRemovedSuccess = false;
          }, 3000);
          // location.reload();
          this.getProjectData();
        })
        .catch((error) => {
          this.memberError = true;
        });
    },
    getPriority() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios
        .get(process.env.VUE_APP_BACKEND_URL + '/auth/Priority/GetList', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.PriorityData = response.data;
        });
    },
    getStatus() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios
        .get(process.env.VUE_APP_BACKEND_URL + '/auth/Status/GetList', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.StatusData = response.data;
        });
    },
    getComplitedTasks() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(
        process.env.VUE_APP_BACKEND_URL +
        '/auth/Tasks/GetComplitedList/' +
        this.$route.params.id +
        '?assigne_id=' +
        this.assigne_id +
        '&status_id=' +
        this.status_id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          this.completedTasks = response.data;
        });
    },
    addComment(id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(
        process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/add-task-comment',
        {
          task_id: id,
          comment: this.comment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          this.showDetail(id);

          setTimeout(function () {
            var div = document.querySelector('#comments');
            div.scrollTop = div.scrollHeight - div.clientHeight;
          }, 500);
        })
        .catch((error) => { });

      this.comment = '';
      this.textareaDetails = false;
    },
    deleteTask(id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/' + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        // Handle successful response
        location.reload();
      }).catch((error) => {
        // Handle error
        console.log(error.response.data.error);
      });
    },
    updateDescription(id) {
      if (id) {
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios.put(
          process.env.VUE_APP_BACKEND_URL +
          '/auth/Tasks/UpdateDescription/' +
          id,
          {
            id: id,
            description: this.description,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((response) => { })
          .catch((error) => {
            this.memberError = true;
          });
      }
    },
    deleteComment(id, detail_id) {
      if (id) {
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios
          .delete(
            process.env.VUE_APP_BACKEND_URL +
            '/auth/Tasks/DeleteComment/' +
            id +
            '/' +
            detail_id,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.showDetail(detail_id);
          })
          .catch((error) => {
            this.memberError = true;
          });
      }
    },
    getDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const dateTime = `${year}-${month}-${day}`;
      this.moment = dateTime;
      return dateTime;
    },

    getWarningTasks() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/WarningList/' + this.$route.params.id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          this.warningTasks = response.data;
        });
    }
  },
  mounted() {
    this.getComplitedTasks();
    this.getStatus();
    this.getPriority();
    this.getTaskList();
    this.member_list();
    this.members_of_project_List();
    this.getDateTime();
    this.getWarningTasks();

    // Open Padding-table
    // const table = document.querySelector(".padding-table");
    // const iconUpDown = document.querySelector(".section-arrow");

    // iconUpDown.onclick = function () {
    //   table.classList.add('active');
    // }

    this.getProjectData();
    window.scroll(0, 0);
    const user_info = JSON.parse(localStorage.getItem('user'));
    this.userName = user_info.user.name;
    let username = this.userName.split(' ');
    let firstName = username[0];
    let lastName = username[1];
    this.userName = firstName[0] + lastName[0];

    setTimeout(() => {
      const table = document.querySelector(".padding-table");
      const iconUpDown = document.querySelector(".section-arrow");

      if (iconUpDown) {
        iconUpDown.addEventListener('click', function () {
          table.classList.toggle('d-none');
          iconUpDown.parentElement.classList.toggle('active');
        })
      }
    }, 1000)
  },
  updated() {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
    );

    // const iconUpDown = document.querySelectorAll(".section-arrow");

    // iconUpDown.forEach(icon => {

    //     icon.addEventListener("click", e => {
    //         icon.parentElement.classList.toggle("active")
    //         icon.parentElement.nextElementSibling.classList.toggle("collapsed")
    //     })
    // })
    // const comment = document.querySelector('.add-comment .ql-editor');
    // comment.addEventListener('click', () => {
    //   this.textareaDetails = true;
    // })

    // var myOffcanvas = document.getElementById('offcanvasRight')
    // myOffcanvas.addEventListener('hide.bs.offcanvas', function () {
    //   console.log('test')
    // })
  },
  computed: {
    ...mapGetters({ permission: 'getCurrentPermission' }),
  },
};
</script>

<style scroped>
.error {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.5em red;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}
</style>