<template>

  <div>
    <loading :active="isLoading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :color="`var(--color-beta)`"
                 :z-index="10000"
                ></loading>
    <section id="login_wrapper">
      <div class="login_position">
        <div class="login_box card border-0">
          <div class="card-body border-0">

            <ValidationObserver v-slot="{ handleSubmit }">
              <!-- Form -->
              <form @submit.prevent="handleSubmit(submitForm)" class="row">

                <!-- Col -->
                <div class="col-12">
                  <h1>პაროლის აღდგენა</h1>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12">
                  <p>პაროლის აღსადგენად მიმართეთ ადმინისტრატორს.</p>
                </div>
            
                <!-- Col -->
                <div v-if="1 != 1" class="col-12 custom-form-group">
                  <div class="alert alert-success m-0 mb-3" v-if="successAlert" role="alert">
                    გილოცავ! პაროლის აღდგენის ლინკი გამოგზავნილია მითითებულ ელ.ფოსტაზე.
                  </div>
                  <div class="alert alert-danger w-100 m-0 mb-3" v-if="failAlert">
                    მითითებული ელ.ფოსტა არ არის სწორი.
                  </div>
                  <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                    <label class="custom-input-label" for="email">ელ.ფოსტა</label>
                    <input id="email" type="email" name="email" v-model="form.email" class="lss-form-control" :class="errorPassword ? 'error' : ''"
                      placeholder="example@example.com">
                    <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12 d-block d-sm-flex justify-content-between align-items-center mt-40">
                  <div class="d-block d-md-flex text-center mt-4 mt-sm-0">
                    <div class="mr-0 mr-sm-3">გაქვს ექაუნთი?</div>
                    <a href="/" class="ms-1">ავტორიზაცია</a>
                  </div>
                  <!-- <button class="btn-sent-yellow-lg">აღდგენა</button> -->
            
                </div>
                <!-- End Col -->

              </form>
              <!-- End Form -->
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import axios from "axios";

export default {
  name: "RecoverPassword",
  data(){
    return{
      success:false,
      isLoading: true,
      fullPage: true,
      isActive: true,
      failAlert: false,
      successAlert: false,
      form:{
        email:''
      },
      errorPassword: false
    }
  },
  components: {
   Loading
  },
  methods: {
    onCancel() {},
    submitForm() {
      axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/password_reset', this.form)
          .then((res) => {
            this.form.email = ""
            this.successAlert = true
            if(this.successAlert) {
              this.failAlert = false
            }
          })
          .catch((error) => {
            this.isLoading = false
            this.errorPassword = true;
            setTimeout(() => {
              this.errorPassword = false
            }, 1500)
            this.failAlert = true
            if(this.failAlert) {
              this.successAlert = false
            }
          });
    },
  },
  mounted() {
    setTimeout(() => {
            this.isLoading = false
        }, 1000)
  },
}
</script>

<style scoped>
.lss-form-control.error {
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.5em red;
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}
</style>