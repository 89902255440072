<template>
  <div>
    <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :color="`var(--color-beta)`" :z-index="10000">
    </loading>
    <Header />
    <!-- container -->
    <div class="container mt-4" v-if="!isLoading">
      <!-- Row -->
      <div class="row gy-3">
        <div class="col-md-12">
          <h5>დეშბორდი</h5>
        </div>

        <!-- col -->
        <div class="col-md-3">
          <div class="card h-100 bg-primary-subtle rounded">
            <div class="card-body">
              <h5>ჩემი დავალებები</h5>
              <div class="font-size-35">{{ my_task_count.my_tasks }}</div>
              <a href="/Mytask">
                <button type="button" class="float-end btn btn-members text-white">ნახვა</button>
              </a>
            </div>
          </div>
        </div>
        <!-- End col -->

        <!-- col -->
        <div class="col-md-3" v-if="permission != 'User'">
          <div class="card h-100 bg-danger-subtle rounded">
            <div class="card-body">
              <h5>წევრები</h5>
              <div class="font-size-35">{{ my_task_count.all_members }}</div>
            </div>
          </div>
        </div>
        <!-- End col -->

        <!-- col -->
        <div class="col-md-3" v-if="permission != 'User'">
          <div class="card h-100 bg-success-subtle rounded">
            <div class="card-body">
              <h5>გაცემული დავალებები</h5>
              <div class="font-size-35">{{ my_task_count.total_tasks }}</div>
            </div>
          </div>
        </div>
        <!-- End col -->

        <!-- col -->
        <div class="col-md-3" v-if="permission != 'User'">
          <div class="card h-100 bg-warning-subtle rounded">
            <div class="card-body">
              <h5>შესრულებული დავალებები</h5>
              <div class="font-size-35">{{ my_task_count.done_tasks }}</div>
            </div>
          </div>
        </div>
        <!-- End col -->

      </div>
      <!-- End Row -->

      <!-- Row -->
      <div class="row g-3 mt-5" v-if="permission != 'User'">
        <!-- col -->
        <div v-if="checkTasksCount?.length" class="col-md-12">
          <h5>შესამოწმებელი</h5>
        </div>
        <!-- /col -->

        <!-- col -->
        <div v-for="(item, index) in checkTasksCount" :key="index" class="col-md-3">
          <div class="card h-100 bg-light-subtle border rounded">
            <div class="card-body">
              <h5>{{ item.NAME }}</h5>
              <div class="font-size-35 text-danger">{{ item.cnt }}</div>
              <router-link :to="'/Project/' + item.project_id">
                <button type="button" class="float-end btn btn-danger text-white">ნახვა</button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- End col -->
      </div>
      <!-- End Row -->
    </div>
    <!--End container -->
  </div>
</template>


<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import { mapGetters } from "vuex";
import axios from 'axios';

export default {
  name: "Dashboard",
  data() {
    return {
      isLoading: true,
      my_task_count: '',
      checkTasksCount: []
    }
  },
  methods: {
    onCancel() {

    },
    getDashboard() {
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Tasks/getDashboard', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.isLoading = false;
          this.my_task_count = response.data;
        });
    },
    checkCountsOfTasksInProjects() {
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Dashboard/GetList', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.isLoading = false;
          this.checkTasksCount = response.data;
        });
    }
  },
  components: {
    Header,
    Loading
  },
  mounted() {
    this.getDashboard();
    this.checkCountsOfTasksInProjects();
  },
  computed: {
    ...mapGetters({ permission: "getCurrentPermission" })
  }
}
</script>

<style></style>