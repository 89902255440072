<template>
    <div v-if="permission === 'Administrator' || permission === 'Super Administrator'">
     <loading :active="isLoading"
                  :can-cancel="true"
                  :on-cancel="onCancel"
                  :color="`var(--color-beta)`"
                  :z-index="10000"
                 ></loading>
         <Header />
          <!-- Resources -->
         <section class="home resources">
             <!-- Container -->
             <div class="container">
                 <div class="row">
                    <!-- Col -->
                    <div class="col-12 mb-3">
                        <div class="d-flex align-items-start align-items-md-center border-bottom">
                            <div class="projectInnerTopBar">
                                <div class="projectLogo text-white">
                                    <img src="@/assets/image/project-icon.png">
                                </div>
                            </div>
                        
                            <div class="projectInnerTopBar h-auto overflow-x-auto overflow-y-hidden">
                                <div class="projectStructure overflow-x-auto">
                                    <div class="d-flex align-items-center">
                                        <h1 class="project-title fw-bold">{{ challange_name.name }}</h1>
                                        <router-link v-if="permission === 'Administrator' || permission === 'Super Administrator'" :to="'/Add_challenge/'+this.$route.params.id" class="btn-members ms-2 px-2 py-1 position-relative text-decoration-none d-block d-md-none">
                                            <i class="tio-add"></i>
                                        </router-link>
                                    </div>
                                    <ul class="project-navigation overflow-x-auto">
                                        <li><router-link class="text-nowrap" to="/Challenges">კატეგორიების სია</router-link></li>
                                        <li class="active"><router-link class="text-nowrap" :to="'/Challenge_list/'+this.$route.params.id">დავალებები</router-link></li>
                                        <li v-if="permission === 'Administrator' || permission === 'Super Administrator'"><router-link class="text-nowrap" :to="'/Add_challenge/'+this.$route.params.id">დაამატე დავალება</router-link></li>
                                    </ul>
                                </div>
                            </div>
                            <router-link v-if="permission === 'Administrator' || permission === 'Super Administrator'" :to="'/Add_challenge/'+this.$route.params.id" class="btn-members ms-auto position-relative text-decoration-none d-none d-md-block">
                                <i class="tio-add"></i>
                                დავალება
                            </router-link>
                        </div>
                    </div>
                    <!-- End Col -->
                
 
                     <!-- Col -->
                     <div class="col-12 mb-3">
                        <div class="filter border-bottom pb-3 d-flex flex-wrap justify-content-between align-items-center  gap-3">
                         <div class="d-flex gap-1">
                             <input v-model="filter_name" class="form-control" type="search" placeholder="მოძებნე დავალება">
                             <button @click="filter_material()" class="btn-members">ძებნა</button>
                         </div>
                         <div>
                             <small class="fw-bold">დავალებების რაოდენობა: {{ material.length }}</small>
                         </div>
                        </div>
                     </div>
                     <!-- End Col -->
 
                     <!-- Col -->
                     <div class="col-12" v-for="(item, index) in material" :key="index">
                         <!-- Card -->
                         <div class="card shadow-sm mb-3">
                             <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="d-block" style="width: 35px;">{{ item.sort }}</span>
                                 
                            

                                    <div class="d-flex align-items-center justify-content-between w-100 flex-wrap row-gap-2">
                                        <div class="d-inline-flex align-items-center flex-column">
                                            <h4 class="card-title mb-0 text-dark">
                                                <span 
                                                    :class="item.level == 'high' ? 'text-bg-success' : '' || 
                                                    item.level == 'middle' ? 'text-bg-warning' : '' || 
                                                    item.level == 'low' ? 'text-bg-danger' : ''"
                                                    class="badge me-2">

                                                    {{ item.level == 'high' ? 'რთული' : '' }}
                                                    {{ item.level == 'middle' ? 'საშუალო' : '' }}
                                                    {{ item.level == 'low' ? 'ადვილი' : '' }}
                                                
                                                </span>  
                                                {{ item.name }}
                                            </h4>
                                        </div>
                                        <div class="d-flex">
                                            <button class="btn btn-dark me-1 position-relative" @click="linkToCopy(item.name, $event, 'dark')">
                                                <i class="tio-info"></i>
                                                <div class="position-absolute w-100 h-100 top-0" style="left: 0"></div>
                                            </button>
                                            <button class="btn btn-primary me-1 position-relative" @click="linkToCopy(item.link, $event, 'primary')">
                                                <i class="tio-link"></i>
                                                <div class="position-absolute w-100 h-100 top-0" style="left: 0"></div>
                                            </button>

                                            <router-link :to="'/Edit_challenge/'+item.id" v-if="permission === 'Administrator' || permission === 'Super Administrator'" class="btn btn-outline-primary me-1">რედაქტირება</router-link>
                                            <button v-if="permission === 'Administrator' || permission === 'Super Administrator'" class="btn btn-outline-danger" data-bs-toggle="modal" :data-bs-target="'#deleteResource_'+item.id">წაშლა</button>
                                        </div>
                                    </div>
                                 </div>
                             </div>
                         </div>
                         <!-- End Card -->
                     </div>
                     <!-- End Col -->
 
                 </div>
             </div>
              <!-- End Container -->
         </section>
         <!-- End Resources -->
 
 
         <!-- Resource Delete -->
         <div v-for="item in material" class="modal fade" :id="'deleteResource_'+item.id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
             <div class="modal-dialog">
                 <div class="modal-content">
                     <div class="modal-header">
                         <h1 class="modal-title fs-5" id="staticBackdropLabel">ლექციის წაშლა</h1>
                         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     </div>
                     <div class="modal-body">
                         ნამდვილად გსურს წაშლა?
                     </div>
                     <div class="modal-footer">
                         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
                         <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" @click="delete_lecture(item.id)">წაშლა</button>
                     </div>
                 </div>
             </div>
         </div>
         <!-- End Resource Delete -->
 
         <!-- Custom DropDown -->
         <div @click="filterMenu = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent" :class="filterMenu == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
         <div @click="filterAssigne = false" class="position-fixed top-0 left-0 h-100 w-100 bg-transparent" :class="filterAssigne == true ? 'd-block' : 'd-none'" style="z-index: 1"></div>
    </div>
 </template>
 
 <script>
 import Loading from 'vue-loading-overlay';
 import Header from '@/components/Header.vue'
 import axios from 'axios';
 import { mapGetters } from 'vuex'

 export default {
     name: 'Challenge_list',
     data() {
         return {
             // loader
             isLoading: true,
             // Custom DropDown
             filterMenu: false,
             filterAssigne: false,
             material: [],
             filter_name: '',
             challange_name: ''
         }
     },
     components: {
         Header,
         Loading
     },
     methods: {
         onCancel() {},
         getMaterial(){
           const token = JSON.parse(localStorage.getItem('user')).access_token;
           axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/ChallengeMaterial/GetList/'+this.$route.params.id,
               {
                 headers: {
                   'Authorization': `Bearer ${token}`
                 }
               })
               .then(response => {
                 // handle success
                this.material = response.data;
               })
               .catch(error => {
                     // handle error
                     console.log(error);
                 });
         },
         getChallengeName(){
           const token = JSON.parse(localStorage.getItem('user')).access_token;
           axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/ChallengeMaterial/getName/'+this.$route.params.id,
               {
                 headers: {
                   'Authorization': `Bearer ${token}`
                 }
               })
               .then(response => {
                 // handle success
                this.challange_name = response.data;
               })
               .catch(error => {
                     // handle error
                     console.log(error);
                 });
         },
         getMaterial(){
           const token = JSON.parse(localStorage.getItem('user')).access_token;
           axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/ChallengeMaterial/GetList/'+this.$route.params.id,
               {
                 headers: {
                   'Authorization': `Bearer ${token}`
                 }
               })
               .then(response => {
                 // handle success
                this.material = response.data;
               })
               .catch(error => {
                     // handle error
                     console.log(error);
                 });
         },
         filter_material() {
             this.isLoading = true;
             const token = JSON.parse(localStorage.getItem('user')).access_token;
             axios.get(process.env.VUE_APP_BACKEND_URL + `/auth/ChallengeMaterial/search/${this.$route.params.id}?name=` + this.filter_name,
                 {
                     headers: {
                         'Authorization': `Bearer ${token}`
                     }
                 })
                 .then(response => {
                     // handle success
                     this.material = response.data;
                     this.isLoading = false;
                 })
                 .catch(error => {
                     // handle error
                     console.log(error);
                 });
         },
         delete_lecture(id) {
             this.isLoading = true;
             const token = JSON.parse(localStorage.getItem('user')).access_token;
             axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/ChallengeMaterial/delete/' + id,
                 {
                     headers: {
                         'Authorization': `Bearer ${token}`
                     }
                 })
                 .then(response => {
                     // handle success
                    //  this.material = response.data;
                     this.getMaterial();
                     this.isLoading = false;
                 })
                 .catch(error => {
                     // handle error
                     console.log(error);
                 });
         },
         linkToCopy(textToCopy, event, color) {
             const input = document.createElement('input');
             input.setAttribute('type', 'text');
             input.value = textToCopy;

             // Append the input element to the DOM
             document.body.appendChild(input);

             // Select the text inside the input field
             input.select();

             try {
                 document.execCommand('copy'); // Try to copy the selected text to the clipboard
                 event.target.parentElement.classList.remove(color == 'primary' ? 'btn-primary' : '' || color == 'dark' ? 'btn-dark' : '');
                 event.target.parentElement.classList.add('btn-success');
                 setTimeout(() => {
                    event.target.parentElement.classList.remove('btn-success');
                    event.target.parentElement.classList.add(color == 'primary' ? 'btn-primary' : '' || color == 'dark' ? 'btn-dark' : '');
                 }, 1000);
             } catch (err) {
                 alert('ვერ დაკოპირდა');
             }

             // Clean up by removing the input element
             document.body.removeChild(input);
         },
     },
     mounted() {
         window.scroll(0, 0);

         this.getMaterial();
         this.getChallengeName();
         setTimeout(() => {
             this.isLoading = false
         }, 1000)
     },
     setup() {
         setTimeout(() => {
             const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
             const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
         }, 1000)
     },
     watch: {
        filter_name: {
            handler(value, newValue) {
                if(!value) {
                    this.getMaterial();
                }
            }
        }
     },
     computed: {
         ...mapGetters({ permission: "getCurrentPermission" })
     }
 }
 </script>
 
 <style scroped>
 
 </style>