<template>
    <div v-if="permission === 'Administrator' || permission === 'Super Administrator'">
     <loading :active="isLoading"
                  :can-cancel="true"
                  :on-cancel="onCancel"
                  :color="`var(--color-beta)`"
                  :z-index="10000"
                 ></loading>
         <Header />
          <!-- Resources -->
         <section class="home resources">
             <!-- Container -->
             <div class="container">
                 <div class="row">
                    <!-- Col -->
                    <div class="col-12 mb-3">
                        <div class="d-flex align-items-start align-items-md-center border-bottom">
                          <div class="projectInnerTopBar">
                            <div class="projectLogo text-white">
                                <img src="@/assets/image/project-icon.png">
                            </div>
                          </div>
                        
                          <div class="projectInnerTopBar h-auto overflow-x-auto overflow-y-hidden">
                            <div class="projectStructure overflow-x-auto">
                                <h1 class="project-title fw-bold">ლექციის რედაქტირება</h1>
                                <ul class="project-navigation overflow-x-auto">
                                  <li><router-link class="text-nowrap" to="/Overviews">რესურსების სია</router-link></li>
                                  <li><router-link class="text-nowrap" :to="'/Resources/'+form.resources_id">ლექციები</router-link></li>
                                  <li v-if="permission === 'Administrator' || permission === 'Super Administrator'"><router-link class="text-nowrap" :to="'/Add_lecture/'+form.resources_id">დაამატე ლექცია</router-link></li>
                                </ul>
                            </div>
                          </div>
                        </div>
                    </div>
                    <!-- End Col -->
 
 
                     <!-- Col -->
                     <div class="col-12">
                         <!-- Card -->
                         <div class="card shadow-sm mb-3">
                             <div class="card-body">
                              <ValidationObserver v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(submitForm)" class="row g-4">
                                  <div class="col-md-6">
                                    <label for="name" class="form-label">აირჩიე რესურსი</label>
                                    <ValidationProvider name="resource" rules="required" v-slot="{ errors }">
                                      <v-select class="form-control" label="name" v-model="form.resource" placeholder="აირჩიე რესრუსი" :options="resources"></v-select>
                                      <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                  <div class="col-md-6">
                                    <label for="name" class="form-label">ლექციის დასახელება</label>
                                    <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                      <input type="text" class="form-control"  v-model="form.name" id="name">
                                      <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                  <div class="col-12">
                                    <label for="videoUrl" class="form-label">ვიდეო</label>
                                    <!-- <input type="text" class="form-control" v-model="form.link" id="videoUrl"> -->
                                    <!-- <vue-dropzone ref="myVueDropzone" id="dropzone" :headers="dropzoneOptions.headers" :options="dropzoneOptions" v-on:vdropzone-success="videoRes" v-bind:useCustomSlot="true">
                                        <div class="dropzone-custom-content">
                                            <h3 class="dropzone-custom-title"><i class="tio-video-add"></i></h3>
                                            <p>ატვირთე ვიდეო</p>
                                        </div>
                                    </vue-dropzone> -->
                                    <vue-dropzone ref="myVueDropzone" id="dropzone" :headers="dropzoneOptions.headers" :options="dropzoneOptions" @vdropzone-success="videoRes" :use-custom-slot="true">
                                      <div class="dropzone-custom-content">
                                        <h3 class="dropzone-custom-title"><i class="tio-video-add"></i></h3>
                                        <p>ატვირთე MKV ვიდეო</p>
                                      </div>
                                    </vue-dropzone>
                                  </div>
                                  <div class="col-12">
                                    <label for="inputAddress" class="form-label">აღწერა</label>
                                    <!-- <vue-editor class="detailEditor" :editorToolbar="customToolbar" v-model="form.description"></vue-editor> -->
                                    <ValidationProvider name="description" rules="required" v-slot="{ errors }">
                                      <ckeditor class="detailEditor"  v-model="form.description"></ckeditor>
                                      <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                    </ValidationProvider>
 
                                  </div>
                                  <div v-if="1 != 1" class="col-12">
                                    <label class="form-label">დავალების დამატება</label>
                                    <div class="row g-3">
                                      <div class="col-md-5">
                                        <input type="text" name="task_name" v-model="task_name" class="form-control" placeholder="დავალების დასახელება">
                                      </div>
                                      <div class="col-md-5">
                                        <input type="text" v-model="task_url" class="form-control" placeholder="დავალების ლინკი">
                                      </div>
                                      <div class="col-md-2">
                                        <button class="btn btn-dark w-100" type="button" @click="addTask()"><i class="tio-file-add-outlined"></i></button>
                                      </div>
  
                                    </div>
                                    <div class="table-responsive mt-2 border-bottom">
                                      <table class="table">
                                        <thead>
                                          <tr>
                                            <th>დავალებების სია:</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-if="form.tasks.length == 0" class="placeholder-glow">
                                            <td><h4 class="placeholder rounded m-0 col-4"></h4></td>
                                            <td class="text-end">
                                              <button type="button" class="btn btn-outline-primary me-1 placeholder col-2"></button>
                                              <button type="button" class="btn btn-outline-danger placeholder col-1"></button>
                                            </td>
                                          </tr>
                                          <tr v-for="(item, index) in form.tasks" :key="index">
                                            <td><h4>{{ item.name }}</h4></td>
                                            <td class="text-end">
                                              <a class="btn btn-outline-primary me-1" :href="item.url" target="_blank"><i class="tio-link"></i> ლინკი</a>
                                              <button class="btn btn-outline-danger" type="button" @click="remove_tasks(index)"><i class="tio-clear"></i></button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                     <label for="inputAddress2" class="form-label">სტატუსი</label>
 
                                     <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                                       <v-select  class="form-control" label="name" v-model="form.status" placeholder="აირჩიე სტატუსი" :options="[
                                           { name: 'საჯარო', id: 'public' },
                                           { name: 'დაფარული', id: 'private' }
                                       ]"></v-select>
                                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                                    </ValidationProvider>
 
                                  </div>
                                  <div class="col-12">
                                      <button :disabled="!form.video" type="submit"  class="btn-members"><i class="tio-edit"></i> რედაქტირება</button>
                                  </div>
                                </form>
                              </ValidationObserver>
 
                             </div>
                         </div>
                         <!-- End Card -->
                     </div>
                     <!-- End Col -->
                 </div>
             </div>
              <!-- End Container -->
         </section>
         <!-- End Resources -->
    
        </div>
 </template>
 
<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import axios from 'axios';
import { mapGetters } from 'vuex'

export default {
  name: 'Edit',
  data() {
    return {
      form: {
        resource: '',
        name: '',
        video: '',
        description: '',
        // tasks: [],
        status: ''
      },
      // For Task Upload
      task_name: '',
      task_url: '',
      resources: [],
      // loader
      isLoading: true,
      // Vue Editor
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"]
      ],
      // for uploading
      dropzoneOptions: {
        url: `${process.env.VUE_APP_BACKEND_URL}/auth/material/UploadVideo`,
        thumbnailWidth: 150,
        maxFilesize: 300,
        timeout: 360000,
        // Specify accepted file extensions for MKV video files
        acceptedFiles: '.mp4',
        maxFiles: 1,
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`,
        },
      },
    }
  },
  components: {
    Header,
    Loading
  },
  methods: {
    onCancel() { },
    submitForm() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      // make POST request with Axios
      axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/material/update/'+this.$route.params.id, this.form, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          // handle success
          this.$router.push({
            path: '/Resources/'+this.form.resources_id
          })

        })
        .catch(error => {
          // handle error
          console.log(error);
        });
    },
    videoRes(file, response) {
      this.form.video = response.success;
    },
    // addTask() {
    //   if(this.task_name === '' || this.task_url === '') {
    //     alert('გთხოვთ შეავსოთ ორივე ველი (დასახელება და ლინკი)')
    //     return
    //   }
    //   const task = {
    //     name: this.task_name,
    //     url: this.task_url
    //   }
    //   this.form.tasks.push(task);

    //   this.task_name = '';
    //   this.task_url ='';
    // },
    remove_tasks(index) {
      this.form.tasks.splice(index, 1);
    },

    goBack() {
      this.$router.back()
    },

    onFailure(message) {
      this.error = true;
    },
    setErrors(errors) {
      this.errors=errors;
    },
    hasError(fieldName){
      return (fieldName in this.errors);
    },
    // clearError(event){
    //   delete this.errors[event.target.name]
    // },
    openPasswordReset() {
      this.openResetPasswords = true;
    },


    getResources() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/resource/SelectList',
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          // handle success
          this.resources = response.data;

        })
        .catch(error => {
          // handle error
          console.log(error);
        });
    },
    getSubmitedForm() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/material/getById/'+this.$route.params.id,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          // handle success
          this.form = response.data;

          this.form.status = this.form.status_material

        })
        .catch(error => {
          // handle error
          console.log(error);
        });
    },
  },
  mounted() {
    window.scroll(0, 0);

    setTimeout(() => {
      this.isLoading = false
    }, 1000)

    // const permission = JSON.parse(localStorage.getItem('user')).permission
    // if (permission !== 'Administrator' || permission !== 'Super Administrator') {
    //   this.$router.push({
    //     path: '/Overviews',
    //   })
    // }

    this.getResources()
    this.getSubmitedForm()
  },
  computed: {
    ...mapGetters({ permission: "getCurrentPermission" })
  }
}
</script>
 
<style scroped>
label {
  font-family: var(--FiraGoBold);
}

button:disabled {
  background-color: #a5a6e3!important;
}
</style>