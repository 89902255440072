<template>

    <div>
      <loading :active="isLoading"
                   :can-cancel="true"
                   :on-cancel="onCancel"
                   :color="`var(--color-beta)`"
                   :z-index="10000"
                  ></loading>
      <section id="login_wrapper">
        <div class="login_position">
          <div class="login_box card border-0">
            <div class="card-body border-0">
  
              <ValidationObserver v-slot="{ handleSubmit }">
                <!-- Form -->
                <form @submit.prevent="handleSubmit(submitForm)" class="row">
  
                  <!-- Col -->
                  <div class="col-12">
                    <h1>პაროლის აღდგენა</h1>
                  </div>
                  <!-- End Col -->
              
                  <!-- Col -->
                  <div class="col-12 custom-form-group">
                    <div class="alert alert-success m-0 mb-3" v-if="successAlert" role="alert">
                      გილოცავ! პაროლი შეცვლილია
                    </div>
                    <div class="alert alert-danger w-100 m-0 mb-3" v-if="failAlert">
                      პაროლის ცვლილება ვერ მოხერხდა.
                    </div>
                    <ValidationProvider name="Email" rules="required|password:@confirm" v-slot="{ errors }">
                      <label class="custom-input-label" for="password">პაროლი</label>
                      <input id="password" type="password" name="password" v-model="form.password" class="lss-form-control" :class="errorPassword ? 'error' : ''"
                        placeholder="პაროლი">
                      <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>

                    <ValidationProvider name="confirm" rules="required" v-slot="{ errors }">
                      <label class="custom-input-label mt-4" for="repassword">გაიმეორე პაროლო</label>
                      <input id="repassword" type="password" name="repassword" v-model="form.repassword" class="lss-form-control" :class="errorPassword ? 'error' : ''"
                        placeholder="გაიმეორე პაროლი">
                      <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <!-- End Col -->
  
                  <!-- Col -->
                  <div class="col-12 d-block d-sm-flex justify-content-between align-items-center mt-40">
                    
                    <button class="btn-sent-yellow-lg">განახლება</button>
              
                  </div>
                  <!-- End Col -->
  
                </form>
                <!-- End Form -->
              </ValidationObserver>
            </div>
          </div>
        </div>
      </section>
    </div>
  
  </template>
  
  <script>
  import Loading from 'vue-loading-overlay';
  import axios from "axios";
  
  export default {
    name: "PasswordReset",
    data(){
      return{
        success:false,
        isLoading: true,
        fullPage: true,
        isActive: true,
        failAlert: false,
        successAlert: false,
        update_status: '',
        form: {
            password: '',
            repassword:'',
            reset_token:this.$route.query.token
        },
        errorPassword: false
      }
    },
    components: {
     Loading
    },
    methods: {
      onCancel() {},
      submitForm() {
      this.isLoading = true;
      axios.post(process.env.VUE_APP_BACKEND_URL+'/public/update_password', this.form)
          .then((res) => {
            this.isLoading = false
            this.form.password = ""
            this.form.repassword = ""
            this.email = ""
            this.successAlert = true
            this.update_status=res.data.status;
            setTimeout(() => {
              this.$router.push('/Signin');
            }, 3000)
          })
          .catch((error) => {
            this.isLoading = false;
            this.failAlert = true;
            this.update_status=error.response.data.status;
         
          });
    },
    CheckToken(){
      this.isLoading = true;
      axios.post(process.env.VUE_APP_BACKEND_URL+'/public/check_token', {"token":this.$route.query.token})
          .then((res) => {
            this.isLoading = false
          })
          .catch((error) => {
            this.isLoading = false;
            this.$router.push('/Signin');
          });
    }
    },
    mounted() {
        this.CheckToken();
      setTimeout(() => {
              this.isLoading = false
          }, 1000)
    },
  }
  </script>
  
  <style scoped>
  .lss-form-control.error {
      animation: shake 0.2s ease-in-out 0s 2;
      box-shadow: 0 0 0.5em red;
  }
  
  @keyframes shake {
    0% { margin-left: 0rem; }
    25% { margin-left: 0.5rem; }
    75% { margin-left: -0.5rem; }
    100% { margin-left: 0rem; }
  }
  </style>