<template>
    <div>
        <header class="header position-fixed top-0 z-2 w-100">
            <div class="container d-flex align-items-center">
                <i class="tio-menu-hamburger" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions"
                    aria-controls="offcanvasWithBothOptions"></i>
                <router-link class="logo ms-4" to="/">
                    <img class="w-100" src="@/assets/image/logo.svg" alt="logo">
                </router-link>


                <!-- <ul class="header_left-content">
                <li><a href="#"> Dashboard</a></li>
                <li><a href="#"> My Task</a></li>
            </ul> -->
                <div class="d-flex gap-1 ms-auto p-0">
                    <div v-if="1 != 1" class="d-none d-md-block">
                        <!-- Notification -->
                        <div class="dropdown">
                            <div style="cursor: pointer;" id="navbarNotificationsDropdown" data-bs-toggle="dropdown"
                                aria-expanded="true" data-bs-auto-close="outside" data-bs-dropdown-animation="">
                                <svg id="Component_3_1" data-name="Component 3 – 1" xmlns="http://www.w3.org/2000/svg"
                                    width="20" height="20" viewBox="0 0 16.5 17"
                                    style="margin-top: -5px; margin-right: 10px;">
                                    <path data-v-c5ef9d60="" id="ic_notifications_none_24px"
                                        d="M9.744,16.5a1.44,1.44,0,0,0,1.436-1.436H8.308A1.44,1.44,0,0,0,9.744,16.5Zm4.308-4.308V8.6a4.367,4.367,0,0,0-3.231-4.537V3.577a1.077,1.077,0,1,0-2.154,0v.488A4.369,4.369,0,0,0,5.436,8.6v3.59L4,13.628v.718H15.487v-.718Zm-1.436.718H6.872V8.6A2.944,2.944,0,0,1,9.744,5.372,2.944,2.944,0,0,1,12.615,8.6Z"
                                        transform="translate(-4 0.5)"></path>
                                    <circle data-v-c5ef9d60="" id="Ellipse_1" data-name="Ellipse 1" cx="5.5" cy="5.5"
                                        r="5.5" transform="translate(5.5)" fill="#ff7b7b"></circle>
                                </svg>
                            </div>
                            <div class="dropdown-menu d-none dropdown-menu-end dropdown-card navbar-dropdown-menu navbar-dropdown-menu-borderless p-0"
                                aria-labelledby="navbarNotificationsDropdown"
                                style="width: 25rem; opacity: 1; transform: translateY(10px) translateY(-10px); transition: transform 300ms ease 0s, opacity 300ms ease 0s;"
                                data-bs-popper="static">
                                <div class="card">
                                    <!-- Header -->
                                    <div class="card-header">
                                        <h4 class="card-title mb-0">შეტყობინებები</h4>
                                    </div>
                                    <!-- End Header -->

                                    <!-- Card Body-->
                                    <div class="card-body p-0">
                                        <!-- Nav -->
                                        <ul class="nav nav-tabs nav-justified" id="notificationTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link active" href="#notificationNavOne"
                                                    id="notificationNavOne-tab" data-bs-toggle="tab"
                                                    data-bs-target="#notificationNavOne" role="tab"
                                                    aria-controls="notificationNavOne" aria-selected="true">მესიჯები
                                                    (3)</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" href="#notificationNavTwo"
                                                    id="notificationNavTwo-tab" data-bs-toggle="tab"
                                                    data-bs-target="#notificationNavTwo" role="tab"
                                                    aria-controls="notificationNavTwo" aria-selected="false"
                                                    tabindex="-1">არქივი</a>
                                            </li>
                                        </ul>
                                        <!-- End Nav -->

                                        <!-- Body -->
                                        <div class="card-body-height">
                                            <!-- Tab Content -->
                                            <div class="tab-content" id="notificationTabContent">
                                                <div class="tab-pane fade active show" id="notificationNavOne"
                                                    role="tabpanel" aria-labelledby="notificationNavOne-tab">
                                                    <!-- List Group -->
                                                    <ul class="list-group list-group-flush navbar-card-list-group">
                                                        <!-- Item -->
                                                        <li class="list-group-item form-check-select">
                                                            <div class="row">
                                                                <div class="col-auto">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input"
                                                                                type="checkbox" value=""
                                                                                id="notificationCheck1" checked>
                                                                            <label class="form-check-label"
                                                                                for="notificationCheck1"></label>
                                                                            <span
                                                                                class="form-check-stretched-bg"></span>
                                                                        </div>
                                                                        <div
                                                                            class="avatar avatar-sm avatar-soft-dark avatar-circle">
                                                                            <span class="avatar-initials">R</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- End Col -->

                                                                <div class="col ms-n2">
                                                                    <h5 class="mb-1">Rati Tsiklauri</h5>
                                                                    <p class="text-muted fs-5">changed an issue from "In
                                                                        Progress" to</p>
                                                                </div>
                                                                <!-- End Col -->

                                                                <small class="col-auto text-muted text-cap">2hr</small>
                                                                <!-- End Col -->
                                                            </div>
                                                            <!-- End Row -->

                                                            <a class="stretched-link" href="#"></a>
                                                        </li>
                                                        <!-- End Item -->
                                                    </ul>
                                                    <!-- End List Group -->
                                                </div>

                                                <div class="tab-pane fade" id="notificationNavTwo" role="tabpanel"
                                                    aria-labelledby="notificationNavTwo-tab">
                                                    <!-- List Group -->
                                                    <ul class="list-group list-group-flush navbar-card-list-group">
                                                        <!-- Item -->
                                                        <li class="list-group-item form-check-select">
                                                            <div class="row">
                                                                <div class="col-auto">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input"
                                                                                type="checkbox" value=""
                                                                                id="notificationCheck6">
                                                                            <label class="form-check-label"
                                                                                for="notificationCheck6"></label>
                                                                            <span
                                                                                class="form-check-stretched-bg"></span>
                                                                        </div>
                                                                        <div
                                                                            class="avatar avatar-sm avatar-soft-dark avatar-circle">
                                                                            <span class="avatar-initials">A</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- End Col -->

                                                                <div class="col ms-n2">
                                                                    <h5 class="mb-1">Anne Richard</h5>
                                                                    <p class="text-body fs-5">accepted your invitation
                                                                        to join Notion</p>
                                                                </div>
                                                                <!-- End Col -->

                                                                <small class="col-auto text-muted text-cap">1dy</small>
                                                                <!-- End Col -->
                                                            </div>
                                                            <!-- End Row -->

                                                            <a class="stretched-link" href="#"></a>
                                                        </li>
                                                        <!-- End Item -->
                                                    </ul>
                                                    <!-- End List Group -->
                                                </div>
                                            </div>
                                            <!-- End Tab Content -->
                                        </div>
                                        <!-- End Body -->
                                    </div>
                                    <!-- End Card Body-->


                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Profile -->
                    <div class="dropdown">
                        <span style="cursor: pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <!-- style="font-size: 12px; height: 32px; line-height: 32px; min-width: 32px; width: 32px;" -->
                            <span
                                class="text-white rounded-circle  text-dark p-1 d-flex align-items-center justify-content-center me-2"
                                style="background: var(--color-beta);"><i class="tio-user"></i> </span>
                        </span>
                        <ul class="dropdown-menu shadow-lg" aria-labelledby="dropdownMenuButton1">
                            <li><span class="dropdown-item fw-bold border-bottom">{{ name }}</span></li>
                            <li><a class="dropdown-item" href="/Settings">პარამეტრები</a></li>
                            <li><a class="dropdown-item" style="cursor: pointer" @click="logout">გასვლა</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>


        <!-- sidebar menu canvas -->
        <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions"
            aria-labelledby="offcanvasWithBothOptionsLabel">
            <div class="offcanvas-header gap-5">
                <router-link class="logo ms-4" to="/">
                    <img class="w-100" src="@/assets/image/logo.svg" alt="logo">
                </router-link>

                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <!-- Main Menu Action -->
                <button class="create d-none">
                    <div>
                        <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                            <path
                                d="M10,10V4c0-1.1,0.9-2,2-2s2,0.9,2,2v6h6c1.1,0,2,0.9,2,2s-0.9,2-2,2h-6v6c0,1.1-0.9,2-2,2s-2-0.9-2-2v-6H4c-1.1,0-2-0.9-2-2s0.9-2,2-2H10z">
                            </path>
                        </svg>
                    </div>
                    <span>შექმნა</span>
                </button>
                <ul class="sidebarMenu">
                    <li><a href="/"> დეშბორდი</a></li>
                    <li v-if="permission != 'User'"><a href="/Members"> წევრები</a></li>
                    <li><a href="/Mytask"> ჩემი დავალებები</a></li>
                    <li><a href="/Overviews"> რესურსები</a></li>
                    <!-- <li v-if="permission!='User'"><a href="/Challenges"> ჩელენჯები</a></li> -->
                </ul>
                <!-- End Main Menu Action -->

                <!-- Other Menu Action -->

                <div class="sidebarMenu-action" v-if="permission != 'User'">
                    <div class="action-titles">
                        <h5 class="action-title">
                            <svg class="Icon RightTriangleIcon" viewBox="0 0 32 32" aria-hidden="true"
                                focusable="false">
                                <path
                                    d="M13.707,6.707L23,16l-9.293,9.293C13.077,25.923,12,25.477,12,24.586V7.414C12,6.523,13.077,6.077,13.707,6.707z">
                                </path>
                            </svg>
                            <span>პროექტები </span>
                        </h5>
                        <div v-if="permission != 'User'" class="add-project-plus" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><i class="tio-add"></i></div>
                    </div>
                    <ul class="sidebarMenu">
                        <li class="custom-tooltip mb-0" v-for="item in projectData" :key="item.id">

                            <a :href="'/Project/' + item.id">
                                {{ item.name }} <strong class="text-danger">({{ item.cnt }})</strong>
                            </a>
                            <!--                        <span class="tooltiptext">{{ item.name }}</span>-->
                        </li>
                    </ul>
                </div>
                <!-- End Other Menu Action -->

            </div>
        </div>

        <div class="offcanvas offcanvas-top project-canvas" tabindex="-1" id="offcanvasTop"
            aria-labelledby="offcanvasTopLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasTopLabel">დაამატე პროექტი</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body d-flex justify-content-center">
                <input type="text" v-model="projectName" :class="addProjectError == true ? 'error' : ''"
                    class="form-control w-50 me-2" placeholder="პროექტის სახელი">
                <button v-if="projectName == ''" type="button" class="btn-members" @click="addProject()"><i
                        class="tio-add"></i> დამატება</button>
                <button v-else type="button" class="btn-members" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"
                    @click="addProject()"><i class="tio-add"></i> დამატება</button>
            </div>
        </div>

    </div>

</template>


<script>
import axios from 'axios';
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Header",
    data() {
        return {
            name: '',
            email: '',
            projectData: [],
            projectName: '',
            addProjectError: false
        }
    },
    methods: {
        logout() {
            this.$store.permission = '';
            localStorage.removeItem('user');
            this.$router.push('/Signin');
        },
        getList() {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/GetList', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    this.projectData = response.data;
                });
        },
        addProject() {
            if (!this.projectName) {
                this.addProjectError = true;
                setTimeout(() => {
                    this.addProjectError = false;
                }, 2000);
            }
            if (this.projectName) {
                const token = JSON.parse(localStorage.getItem('user')).access_token;
                axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/store', { 'projectName': this.projectName }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => {
                        console.log(response.data);
                        this.getList();
                        // window.location.reload();
                        // this.$router.push({
                        //     path: '/Project/'+response.data.id
                        // });
                    });
            }
        }
    },
    mounted() {

        this.getList();


        try {
            JSON.parse(localStorage.getItem('user'))

        } catch (e) {
            localStorage.removeItem('user');
            this.$router.push({ path: '/Signin' })
        }

        const user_info = JSON.parse(localStorage.getItem('user'));
        this.name = user_info.user.name;
        this.email = user_info.user.email;
        const token = JSON.parse(localStorage.getItem('user')).access_token;



        axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/check-user', { 'data': localStorage.getItem('user') }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {



                if (res.data.status === 'disabled') {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
                /*
                          this.$store.state.permission=res.data.permission;
                */
                /*
                
                
                          this.$store.state.commit("setCurrentPermission", res.data.permission);*/





            })
            .catch((error) => {

                localStorage.removeItem('user');
                this.$router.push({ path: '/Signin' })

            });

        this.$store.state.sideBarMenuAccordion();



    },
    computed: {
        ...mapGetters({ permission: "getCurrentPermission" })
    }
}
</script>

<style>
.logo {
    max-width: 200px
}

.card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.card-title {
    font-weight: bold;
}

.card-body-height {
    height: 21.25rem;
    overflow: hidden;
    overflow-y: auto;
}

.h4,
h4 {
    font-size: .984375rem;
}

.h5,
h5 {
    font-size: .875rem;
    font-weight: bold;
}

.fs-5 {
    font-size: .875rem !important;
}

.nav-link.active,
.navbar-nav .nav-link.active {
    color: #377dff !important;
}

.nav-tabs .nav-link {
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom: 0.1875rem solid transparent !important;
    margin-bottom: -0.125rem !important;
}

.nav-link {
    color: #000000;
    padding: 1rem 0.75rem !important;
}

.nav-link.active {
    border-bottom: 4px solid #377dff !important;
}

.navbar-card-list-group .list-group-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.avatar {
    position: relative;
    display: inline-block;
    width: 2.625rem;
    height: 2.625rem;
}

.avatar-sm,
.avatar-sm .avatar-initials {
    width: 2.40625rem;
    height: 2.40625rem;
}

.avatar-circle {
    border-radius: 50%;
}

.avatar-sm .avatar-initials {
    font-size: .875rem;
}

.avatar-sm,
.avatar-sm .avatar-initials {
    width: 2.40625rem;
    height: 2.40625rem;
    color: #132144;
    background-color: rgba(19, 33, 68, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

@media screen and (max-width: 576px) {
    .logosvg {
        width: 250px;
    }
}
</style>