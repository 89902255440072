<template>
    <div>
        <input v-if="isActive" style="width:350px;" placeholder="დაწერე დავალების სახელი"
            @keyup="updateTasks(id, index)" :disabled="disabled" v-model="name" type="text" class="text">
    </div>
</template>

<script>
export default {
    name: "ListItem",
    props: {
        id: Number,
        index: Number,
        name: String,
        isActive: Boolean,
        disabled: Boolean
    },
    updated() {
        console.log('updating ' + this.id)
    },
    methods: {
        updateTasks(id, index) {
            this.$emit('updateTasks', id, index);
        }
    }
}
</script>

<style scoped></style>