<template>
  <div>
    <loading :active="isLoading" :can-cancel="true" :color="`var(--color-beta)`" :z-index="10000"></loading>
    <Header />
    <section class="home">

      <div class="container">
        <div class="row">

          <div class="col-md-12 ">
            <div class="row">
              <div class="col-md-12 mb-2">
                <a href="/Members">უკან</a>
              </div>
              <div class="col-md-10">
                <p class="mb-0">
                  <b>{{ member.name }}</b>
                </p>
                <span class="badge bg-warning text-dark">{{ member.permission.name }}</span>
              </div>
              <div class="col-md-2 text-end">
                <p class="mb-0">
                  <b>რეგისტრაციის თარიღი:</b>
                </p>
                <small> {{ member.created_at }}</small>

              </div>
            </div>


            <hr>
          </div>

          <div class="col-md-5">
            <div class="row">
              <div class="col-md-12">
                <div class="card p-4">
                  <div class="col-md-12">
                    <h5 class="card-title">პროფილი</h5>
                  </div>
                  <div class="col-md-12 mt-3">
                    <label>სახელი</label>
                    <input type="text" class="form-control" v-model="member.name">
                  </div>
                  <div class="col-md-12 mt-3">
                    <label>ელ.ფოსტა</label>
                    <input type="text" class="form-control" v-model="member.email">
                  </div>
                  <div class="col-md-12 mt-3">
                    <label>ტელეფონი</label>
                    <input type="text" class="form-control" v-model="member.mobile">
                  </div>
                  <div v-if="permission == 'Super Administrator'" class="col-md-12 mt-3">
                    <label>ნებართვა</label>
                    <v-select v-model="member.permission" class="assigne w-100 border" label="name" :options="[
      { name: 'Super Administrator', id: 'Super Administrator' },
      { name: 'Administrator', id: 'Administrator' },
      { name: 'User', id: 'User' }
    ]"></v-select>
                  </div>

                  <div class="col-md-12 mt-3 mb-3">
                    <label>Status</label>
                    <v-select v-model="member.status" class="assigne w-100 border" label="name" :options="[
      { name: 'active', id: 'active' },
      { name: 'disabled', id: 'disabled' }
    ]"></v-select>
                  </div>
                  <div class="col-12 mb-3">
                    <label>პაროლი</label>
                    <input type="password" class="form-control" v-model="member.password">
                  </div>
                  <!-- Col -->
                  <div v-if="permission == 'Super Administrator'" class="col-12 mb-3">
                    <label>კურსის ტიპი</label>
                    <v-select v-model="member.learn_type" class="assigne w-100 border" placeholder="აირჩიე ტიპი"
                      label="title" :options="[
      { title: 'Front-End', id: 'frontend' },
      { title: 'Back-End', id: 'backend' }
    ]"></v-select>
                  </div>
                  <!-- /End Col -->
                  <!-- Col -->
                  <div v-if="permission == 'Super Administrator'" class="col-12">
                    <label>ბოლო დავალება</label>
                    <v-select v-model="member.task_id" class="assigne w-100 border" placeholder="აირჩიე დავალება"
                      label="title" :options="allTasks"></v-select>
                  </div>
                  <!-- /End Col -->

                  <div class="col-md-12 mt-4">
                    <div v-if="success_profile" class="alert alert-success alert-dismissible fade show" role="alert">
                      წარმატებით განახლდა იუზერის პროფილი.
                      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <button class="btn btn-members float-end" @click="UpdateProfile();">განახლება</button>
                  </div>


                </div>
              </div>
            </div>
          </div>

          <div class="col-md-7">
            <div class="row g-3">
              <div class="col-md-12">
                <div class="card p-4">
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="card-title">დაამატე მოსწავლე პროექტში</h5>
                    </div>
                    <div class="col-md-10">
                      <v-select class="border" style="height:40px;padding-top:5px;" label="name" v-model="projectsForm"
                        placeholder="აირჩიე პროექტი" :options="projects"></v-select>

                    </div>
                    <div class="col-md-2">
                      <button class="btn btn-members" @click="AddUserToProject();">დამატება</button>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div v-if="success" class="alert alert-success alert-dismissible fade show" role="alert">
                        <strong>Ho Ho Ho!</strong> You successful added user to project.
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4" v-if="member.projects.length > 0">

                    <div class="col-md-12">
                      <h5 class="card-title">პროექტების ჩამონათვალი</h5>
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <td>დასახელება</td>
                            <td class="text-center">წაშლა</td>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="item in member.projects" :key="item.id">
                            <td>{{ item.name }}</td>
                            <td class="text-center">
                              <button type="button" class="btn-close" @click="removeUserFromProject(item.id);"></button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Col -->
              <div class="col-12 mb-3">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">კურსი</h5>
                    <!-- Course -->
                    <ul class="list-group list-group-flush">
                      <!-- Main Items -->
                      <div class="accordion" id="accordionExample">
                        <!-- Sub Items -->
                        <div class="accordion-item rounded-0">
                          <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                              HTML <span class="badge bg-success ms-2">10 / 30 - {{ ((10 / 30) * 100).toFixed() + '%'
                                }}</span>
                            </button>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                  სათაურები
                                </li>
                                <li class="list-group-item">
                                  p ტეგი
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!-- /Sub Items -->

                        <!-- Sub Items -->
                        <div class="accordion-item rounded-0">
                          <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              CSS <span class="badge bg-success ms-2">15 / 28 - {{ ((15 / 28) * 100).toFixed() + '%'
                                }}</span>
                            </button>
                          </h2>
                          <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                  ფონტები
                                </li>
                                <li class="list-group-item">
                                  ფერები
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!-- /Sub Items -->
                      </div>
                      <!-- /Main Items -->

                    </ul>
                    <!-- /Course -->
                  </div>
                </div>
              </div>
              <!-- /End Col -->
            </div>
          </div>



        </div>
      </div>

    </section>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import Header from '@/components/Header.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditMemeber",
  data() {
    return {
      isLoading: true,
      member: {
        projects: {},
        permission: {
          id: '',
          name: '',
        },
        password: '',
        status: '',
        task_id: '',
        learn_type: ''
      },
      projects: [],
      filterMenu: false,
      filterAssigne: false,
      projectsForm: [],
      success: false,
      success_profile: false,


      // user Tasks
      allTasks: []
    }
  },
  components: {
    Header,
    'v-select': vSelect,
    Loading
  },
  methods: {
    filter() {
      this.filterMenu = true;
      this.filterAssigne = false;
    },
    getList() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/users/get_by_id/' + this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.isLoading = false;
          this.member = response.data;
        });
    },
    getProjectList() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/Projects/getProjectList', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.isLoading = false;
          this.projects = response.data;
        });
    },
    AddUserToProject() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/user/AddUserToProject', {
        user_id: this.$route.params.id,
        project_id: this.projectsForm.id
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.isLoading = false;
          this.getList();
          this.success = true;
        });
    },
    removeUserFromProject(id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/user/removeUserFromProject/' + id + '/' + this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.isLoading = false;
          this.getList();
        });
    },
    UpdateProfile() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/user/UpdateProfile/' + this.$route.params.id, this.member, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          this.isLoading = false;
          this.getList();
          this.success_profile = true;
        });
    },
    getAllTasks() {

      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/resource/getTasks/')
        .then(response => {

          // let filterData;
          // response.data.forEach(element => {

          //   if (element.TaskScope && element.TaskScope.task_id) {
          //     filterData = {
          //       task_id: element.TaskScope.task_id,
          //       title: element.TaskScope.title
          //     }
          //     this.allTasks.push(filterData);
          //   }

          // });

          this.allTasks = response.data
            .filter(element => element.TaskScope && element.TaskScope.task_id)
            .map(element => ({
              id: element.TaskScope.task_id,
              title: element.TaskScope.title
            }));

        });
    }


  },
  mounted() {
    this.getList();
    this.getProjectList();

    this.getAllTasks();

    const user = JSON.parse(localStorage.getItem('user')).permission;
    if (user == 'User') {
      this.$router.push('/');
    }
  },
  computed: {
    ...mapGetters({ permission: "getCurrentPermission" })
  }
}
</script>

<style>
input.date {
  position: relative;
}

input.date[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>